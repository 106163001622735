import { mls } from 'lib/multilanguagesupport';
import React, { useEffect, useMemo, useState } from 'react';
import './pageTableSelection.scss';
import reduxConstants from 'lib/multilanguagesupport/reduxConstants';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import TableDataCard from './TableDataCard';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import DisplayTable from 'lib/reusable-components/DisplayTable/DisplayTable';
import {
  getConvertedTableData,
  getSheetID,
} from 'lib/reusable-components/DisplayTable/tableFunction';
import DataTimeColumnField from './DataTimeColumnField';
import { defaultReportTimeFrame } from 'lib/reports-analytics/data/defaultSchema';
import { defaultDataByTableType } from '../tableCustomColumn/data/sqlCustomCommandsData';
interface pageTableSelectionProps {
  stateProps: any;
  selectedTable: any;
  pagesList: any[];
  selectedPageNumber: number;
  setPagesList: Function;
  allTableHeadData: { [key: string]: any };
  setAllTableHeadData: Function;
  tableHeadLoading: boolean;
  setTableHeadLoading: Function;
}
const tableType = {
  appJointable: 'Join Table',
  appCustomtable: 'Custom Table',
  appDatatable: 'Data Table',
};

const PageTableSelection = ({
  stateProps,
  selectedTable,
  selectedPageNumber,
  pagesList,
  setPagesList,
  allTableHeadData,
  setAllTableHeadData,
  tableHeadLoading,
  setTableHeadLoading,
}: pageTableSelectionProps) => {
  const { appID, projectSheets } = stateProps;

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const tablesObject = useMemo(() => {
    const getGroupTableData = ({
      analyticsSchema,
      tableType,
    }: {
      analyticsSchema: any;
      tableType: { [key: string]: any };
    }) => {
      let tableInGroup = {};
      Object.keys(tableType).forEach((tableTypeID) => {
        if (analyticsSchema?.[tableTypeID]) {
          tableInGroup = {
            ...tableInGroup,
            [tableTypeID]: {
              groupKey: tableTypeID,
              title: tableType[tableTypeID],
              options: analyticsSchema?.[tableTypeID],
            },
          };
        }
      });
      return tableInGroup;
    };

    const finalGroupTable = getGroupTableData({ analyticsSchema: analyticsSchema, tableType });
    return finalGroupTable;
  }, [analyticsSchema]);

  const getTableData = async ({ tableID }: { tableID: string }) => {
    if (allTableHeadData[tableID as keyof typeof allTableHeadData]) {
      return { skip: true };
    }
    const sheetData: {
      sheetID: string;
      skip: boolean;
      newCreatedSheetID?: boolean;
    } = await getSheetID({
      appSheets: projectSheets,
      selectedTable: tableID,
      appID,
    });
    if (sheetData?.skip) {
      return { skip: true };
    }
    const tableDataProps = {
      appID,
      tableID: tableID,
      sheetID: sheetData.sheetID,
      disableCustomColumns: false,
    };

    const tableDataRes = await getConvertedTableData({
      appID: tableDataProps.appID,
      selectedTable: tableDataProps.tableID,
      sheetID: tableDataProps.sheetID,
      disableCustomColumns: tableDataProps.disableCustomColumns,
    });
    if (tableDataRes.skip) {
      return { skip: true };
    }

    return {
      tableData: tableDataRes.groupedTableData,
      skip: false,
      tableSchema: tableDataRes.data.tableSchema,
    };
  };
  const getAllTableData = async ({ tables = [] }: { tables: any[] }) => {
    setTableHeadLoading(true);

    let newAllTableData: { [key: string]: any } = {};
    for (let index = 0; index < tables.length; index++) {
      const tableID = tables[index];
      const tableDataRes = await getTableData({ tableID: tableID });

      if (!tableDataRes.skip) {
        if (index === tables.length - 1) {
          setAllTableHeadData((currentTableData: any) => ({
            ...currentTableData,
            ...newAllTableData,
            [tableID]: { tableData: tableDataRes.tableData, tableSchema: tableDataRes.tableSchema },
          }));
        } else {
          newAllTableData = {
            ...newAllTableData,
            [tableID]: { tableData: tableDataRes.tableData, tableSchema: tableDataRes.tableSchema },
          };
          newAllTableData = { ...newAllTableData, [tableID]: tableDataRes.tableData };
        }
      }
    }
    setTableHeadLoading(false);
  };
  const updateSelectedTable = async ({ key, value }: { key: string; value: any }) => {
    const pagesListCopy = Array.from(pagesList);
    const [selectedItem] = pagesListCopy.splice(selectedPageNumber, 1);

    if (key === 'tableID') {
      const tableID = value;
      let datetimeColumn = '';
      setTableHeadLoading(true);
      const tableDataRes = await getTableData({ tableID: tableID });
      if (!tableDataRes.skip) {
        setAllTableHeadData((currentTableData: any) => ({
          ...currentTableData,
          [tableID]: { tableData: tableDataRes.tableData, tableSchema: tableDataRes.tableSchema },
        }));
        datetimeColumn = tableDataRes?.tableSchema?.defaultTable?.defaultDatetimeCol ?? '';
      }
      const udpatedSelectedItem = {
        ...selectedItem,
        [key]: value,
        datetimeColumn,
        sqlQuery: defaultDataByTableType.customColumn.defaultQuery,
      };
      pagesListCopy.splice(selectedPageNumber, 0, udpatedSelectedItem);
      setPagesList(pagesListCopy);
      setTableHeadLoading(false);
      return;
    }
    const udpatedSelectedItem = {
      ...selectedItem,
      [key]: value,
    };
    pagesListCopy.splice(selectedPageNumber, 0, udpatedSelectedItem);
    setPagesList(pagesListCopy);
  };

  const updateReportLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSelectedTable({ key: 'name', value: e.target.value });
  };
  const updateReportTableId = (tableID: string) => {
    updateSelectedTable({ key: 'tableID', value: tableID });
  };
  const [tableHeadDisplayTableData, setTableHeadDisplayTableData] = useState({
    status: false,
    tableID: '',
  });
  const handleTableHead = (action: boolean) => {
    setTableHeadDisplayTableData({ status: action, tableID: '' });
  };

  useEffect(() => {
    if (tableHeadDisplayTableData.status) {
      getAllTableData({ tables: [tableHeadDisplayTableData.tableID] });
    }
  }, [tableHeadDisplayTableData.status]);

  const selectedTableData =
    allTableHeadData?.[selectedTable?.tableID ?? ('' as keyof typeof allTableHeadData)];

  const handleTimeFrame = (action: any) => {
    updateSelectedTable({ key: 'timeFrame', value: action });
  };

  return (
    <div className='pageTableSelection'>
      <PopUpDivChild
        setPopUp={handleTableHead}
        initialValue={tableHeadDisplayTableData.status}
        className='tableDisplayPopup'
        popupTitle='Table Head'
      >
        <DisplayTable
          tableData={
            allTableHeadData?.[tableHeadDisplayTableData.tableID as keyof typeof allTableHeadData]
              ?.tableData ?? []
          }
          tableLoading={tableHeadLoading}
        />
      </PopUpDivChild>
      <div className='titleDiv'> {mls('Report Name')}: </div>
      <input
        type='text'
        className='primaryInput'
        placeholder='Name of the Report'
        value={selectedTable?.name ?? ''}
        maxLength={40}
        onChange={updateReportLabel}
      />
      <div className='titleDiv'> {mls('Select Table')}: </div>
      <div className='tableDropDownDiv'>
        <ObjectDropDown
          object={tablesObject}
          dropDownValue={selectedTable?.tableID ?? ''}
          setdropDownValue={updateReportTableId}
          className='tableDropDown introjs-supistaAISelectTable'
          inputPlaceHolder={mls('Search Table')}
          alternateName={'tableID'}
          mainKey={'tableID'}
          ObjectInObject
          search
          groupDropDown
        />
      </div>
      {/* {tableHeadLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : null} */}
      {selectedTable?.tableID && !tableHeadLoading ? (
        <>
          <DataTimeColumnField
            tableSchema={selectedTableData?.tableSchema}
            dateTimeCol={selectedTable?.datetimeColumn ?? ''}
            updateSelectedTable={updateSelectedTable}
          />
          <div className='titleDiv'> {mls('TimeFrame')}: </div>
          <ObjectDropDown
            object={defaultReportTimeFrame}
            dropDownValue={selectedTable?.timeFrame ?? ''}
            setdropDownValue={handleTimeFrame}
            // ObjectInObject={true}
          />
          {React.Children.toArray(
            (
              allTableHeadData?.[selectedTable?.tableID ?? ('' as keyof typeof allTableHeadData)]
                ?.tableData ?? []
            ).map((tableData: any, tableIndex: number) => {
              return (
                <>
                  <TableDataCard
                    columnSchema={tableData.items}
                    tableID={selectedTable?.tableID ?? ''}
                    mainTitle={tableData.nameOfTable}
                    tableData={tableData}
                    setTableHeadDisplayTableData={setTableHeadDisplayTableData}
                    tableIndex={tableIndex}
                  />
                </>
              );
            })
          )}
        </>
      ) : null}
    </div>
  );
};

export default PageTableSelection;
