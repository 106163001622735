import React from 'react';
import resetArrow from '../../assets/resetArrow.svg';
import { allDropableID, defaultChartSetting } from '../../data/defaultChartSchema';
import DragableAxis from '../../components/BuildComponents/DragableAxis';
import SlideSwitch from '../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import FilterIndex from '../../components/BuildComponents/FilterDiv/FilterIndex';
import GeoGroups from '../../components/SpacialCasesComponents/GeoCharts/GeoGroups';
import DragableToolTip from '../../components/BuildComponents/DragableToolTip';
import EditColors from '../../components/StyleComponents/ChartDisplay/EditColors';
import ChartInsight from 'lib/chart-settings-lib/components/BuildComponents/ChartInsight';
import { mls } from 'lib/multilanguagesupport';

const GeoChart = ({ stateProps }) => {
  const {
    isInsight,
    setIsInsight,
    xAxisData,
    setXAxisData,
    yAxisData,
    setYAxisData,
    isCrossReport,
    setIsCrossReport,
    iskeepAllFilters,
    setIskeepAllFilters,
    setChartYAxisStyle,
    setChartXAxisStyle,
    handleResetToolTip,
    chartType
  } = stateProps;
  const { yAxisId, toolTip, xAxisId } = allDropableID;

  return (
    <>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls("X-Axis")} *</div>

        <DragableAxis
          dragableDivId={xAxisId}
          axisData={xAxisData}
          setAxisData={setXAxisData}
          stateProps={stateProps}
          setStyle={setChartXAxisStyle}
          defaultLable={defaultChartSetting.xAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls("Y-Axis")} *
        </div>
        <DragableAxis
          dragableDivId={yAxisId}
          axisData={yAxisData}
          setAxisData={setYAxisData}
          stateProps={stateProps}
          setStyle={setChartYAxisStyle}
          defaultLable={defaultChartSetting.yAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>

      {/* <div className='outerDiv open'>
        <div className='MainTitle inFlex'>Group</div>
        <GeoGroups stateProps={stateProps} />
      </div> */}

      <div className='outerDiv open'>
        <div className='MainTitle inFlex'>
          {mls("Tooltips")}
        </div>
        <DragableToolTip
          dragableDivId={toolTip}
          stateProps={stateProps}
          allDropableID={allDropableID}
          handleResetToolTip={handleResetToolTip}
        />
      </div>
      <EditColors stateProps={stateProps} />

      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls("Default Filters")}</div>

        <FilterIndex stateProps={stateProps} />
        {/* <div className='textAndToggle'>
          <div className='text'>Cross-report</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={isCrossReport} setIsChecked={setIsCrossReport} />{' '}
          </div>
        </div>
        <div className='textAndToggle'>
          <div className='text'>Keep all filters</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={iskeepAllFilters} setIsChecked={setIskeepAllFilters} />
          </div>
        </div> */}
      </div>

      <ChartInsight
        isInsight={isInsight}
        setIsInsight={setIsInsight}
        chartKey={chartType.chartKey}
      />

    </>
  );
};

export default GeoChart;
