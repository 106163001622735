import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { pieDataFormatter } from './helpers/dataFormatter';
import styles from '../../constants/styles.module.css';
import useDivDimensions from '../../helpers/divDimensionHelper';
import {
  compareBrightness,
  getDefaultColorByIndex,
  hexWithOpacity,
} from '../../helpers/colorHelper';
import { defaultToolTipStyling } from '../../constants/constants';
import { mls } from 'lib/multilanguagesupport';

import useOrdinalLegend from '../../helpers/hooks/userOrdinalLegend';
import { PieToolTip } from '../ChartToolTip/ChartToolTip';
import { getOther } from 'lib/reusable-components/reusableFunction/chartHealperReusableFunction';
import { allChartTypesObj } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';

const capitalizeOnlyFirst = (s) => {
  s = s.toLowerCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
};
/* Assumption: Data numerical data is in y and labels are in x in data obj */
const getY = (d) => d.y;

const PieType = ({
  // eslint-disable-next-line no-unused-vars
  data,
  title,
  type,
  xAxisLabel,
  yAxisLabel,
  cardRef,
  isProd,
  useChartSettingsButton,
  chartID,
  chartColor,
  colorType,
  chartHeight,
  cardWidth,
  handleClick,
  theme,
  optionData,
  chartData,
  isReport,
  // height, width,
}) => {
  const margin = 20;
  const automaticOpacity = (chartData?.tooltip?.automaticOpacity ?? 100) / 100;
  // Calculating maximum height and width by deducting margins
  const { width } = useDivDimensions(cardRef);
  // eslint-disable-next-line no-unused-vars
  const height = Math.min(chartHeight, width) || 300;
  // eslint-disable-next-line no-unused-vars
  const { formattedData, others, otherSum } = useMemo(() => pieDataFormatter(data), [data]);
  const maxHeight = height - margin * 2;
  const maxWidth = width - margin * 2;
  // Determining radius and center for chart
  const maxRadius = Math.min(maxHeight, chartHeight) / 2;
  const centerX = maxWidth / 2;
  const centerY = maxHeight / 2;
  // If Donut chart, provide inner Radius

  let innerRadius = 0;
  if (type === 'DONUT') {
    innerRadius = Math.max(
      maxRadius - 80,
      maxRadius >= 100 ? maxRadius - 20 - maxRadius / 5 : maxRadius - 10 - maxRadius / 10
    );
  }

  const { x, y } = formattedData;
  let dataDict = {};
  x.forEach((ele, index) => {
    dataDict[ele] = formattedData.y[index];
  });
  const dataObj = x.map((e, i) => ({ x: e, y: y[i] }));
  // Accessors for data
  // Method for sorting values
  const pieSortValues = (a, b) => b - a;
  const valueStyle = useMemo(() => {
    const filterdValueStyle = (theme?.tooltip?.valueStyle || []).filter((styleData) => {
      if (styleData.axis === 'others') {
        return true;
      }
      return styleData.axis === allChartTypesObj?.[type]?.toolTip?.target;
    });
    return filterdValueStyle;
  }, [theme?.tooltip?.valueStyle]);
  const legendColorScale = {};
  (valueStyle || []).forEach((vs) => {
    if (formattedData.x.includes(vs.label)) {
      const color = vs?.color;
      const opacity = (vs?.opacity ?? 100) / 100;
      legendColorScale[vs.label || 'Null'] = hexWithOpacity(color, opacity);
    }
  });
  if (others.length > 0) {
    const othersData = getOther({ array: valueStyle });
    if (theme?.tooltip?.type === 'automatic') {
      legendColorScale[othersData.label] = hexWithOpacity(othersData.color, automaticOpacity);
    } else {
      legendColorScale[othersData.label] = hexWithOpacity(
        othersData.color,
        (othersData?.opacity ?? 100) / 100
      );
    }
  }

  // const { colorScale, Legend } = useOrdinalLegend({
  //   colorScale: theme.colors,
  // });
  const { colorScale, Legend } = useOrdinalLegend({
    colorScale: legendColorScale,
    isReport,
  });

  // Creating a tooltip for the chart
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // Function to handle mouse over change
  const handleMouseOver = ({ x, y, arcFill }, datum) => {
    showTooltip({
      tooltipLeft: x,
      tooltipTop: y,
      tooltipData: { ...datum, arcFill },
    });
  };

  // Function to handle change of color
  // const handleColorChange = (index, newColor) => {
  //   const newColors = colors.slice();
  //   newColors[index] = newColor;
  //   updateColors(newColors);
  // };

  // Simple ToolTip component
  // const tooltipStyling = theme.tooltip.style;
  const tooltipColorsDict = useMemo(() => {
    const tooltipColors = {};
    theme.tooltip.style.forEach((t) => {
      const operations = capitalizeOnlyFirst(t.operations?.type || '');
      const label = operations === '' ? t.label : `${operations}[ ${t.label} ]`;
      tooltipColors[label] = {
        color: t.color,
        show: t.show,
        textStyle: theme.tooltip.textStyle,
        font: theme.tooltip.font,
        fontHeight: theme.tooltip.fontHeight,
      };
    });
    valueStyle.forEach((t) => {
      const operations = capitalizeOnlyFirst(t.operations?.type || '');
      const label = operations === '' ? t.label : `${operations}[ ${t.label} ]`;
      tooltipColors[label] = {
        color: t.color,
        show: t.show,
        textStyle: theme.tooltip.textStyle,
        font: theme.tooltip.font,
        fontHeight: theme.tooltip.fontHeight,
      };
    });
    return tooltipColors;
  }, [theme.tooltip]);

  const tooltipTimer = useRef(null);
  const handleMouseLeave = () => {
    tooltipTimer.current = setTimeout(() => {
      hideTooltip();
    }, 1000);
  };

  const tooltipHoverHandler = () => {
    clearTimeout(tooltipTimer.current);
  };

  /* 
  Selecting flex direction, based on the chartWidth
  For ONETHIRD charts, we need to display legend on next line to avoid overlap
  But first, we need to check width, if width is less than 600px display on next line
  */
  const getMaxValue = () => {
    const yData = data?.y ?? [0, 0];
    const sumValue = (yData.length > 0 ? yData : [0, 0]).reduce((previousValue, currentValue) => {
      return (previousValue ?? 0) + (currentValue ?? 0);
    });
    return sumValue;
  };
  const maxValue = getMaxValue();

  return (
    <>
      {data.x && data.x.length !== 0 && data.y && data.y.length !== 0 ? (
        <>
          {optionData?.anyPositive ? (
            <div
              ref={containerRef}
              className={styles.pieDiv}
              style={{ flexDirection: 'column', position: 'relative' }}
            >
              {tooltipOpen && (
                <PieToolTip
                  TooltipInPortal={TooltipInPortal}
                  theme={theme}
                  // tooltipHoverHandler={tooltipHoverHandler}
                  // handleMouseLeave={handleMouseLeave}
                  tooltipLeft={tooltipLeft}
                  tooltipTop={tooltipTop}
                  tooltipData={tooltipData}
                  tooltipColorsDict={tooltipColorsDict}
                  xAxisLabel={xAxisLabel}
                  yAxisLabel={yAxisLabel}
                  defaultToolTipStyling={defaultToolTipStyling}
                  others={others}
                  otherSum={otherSum}
                  backgroundColor={defaultToolTipStyling.backgroundColor}
                  maxValue={maxValue}
                />
              )}

              {/* {tooltipOpen && (
              <Box
                onMouseEnter={tooltipHoverHandler}
                onMouseLeave={handleMouseLeave}
                // sx={{
                //   maxHeight: 'px',
                //   minWidth: '12px',
                //   maxWidth: '200px',
                //   overflowY: 'auto',
                //   position: 'absolute',
                //   left: tooltipLeft,
                //   top: tooltipTop,
                //   zIndex: 100,
                //   backgroundColor: '#ddd',
                //   color: '#333',
                //   padding: '12px',
                // }}
                style={{ ...defaultToolTipStyling, left: tooltipLeft, top: tooltipTop }}
              >
                {tooltipData?.x === 'Others' ? OthersToolTip : SimpleTooltip}
              </Box>
            )} */}

              <svg
                style={{
                  position: 'relative',
                }}
                width={width}
                height={height}
              >
                <Group top={centerY + margin} left={centerX + margin}>
                  <Pie
                    data={dataObj}
                    pieValue={getY}
                    pieSortValues={pieSortValues}
                    outerRadius={maxRadius}
                    getKey={(arc) => 'arc.data.label'}
                    innerRadius={innerRadius}
                  >
                    {(pie) =>
                      pie.arcs.map((arc, index) => {
                        return (
                          <PieArcs
                            arc={arc}
                            type={type}
                            index={index}
                            pie={pie}
                            colorScale={colorScale}
                            centerX={centerX}
                            maxRadius={maxRadius}
                            centerY={centerY}
                            tooltipHoverHandler={tooltipHoverHandler}
                            handleMouseOver={handleMouseOver}
                            handleMouseLeave={handleMouseLeave}
                            handleClick={handleClick}
                            tooltipColorsDict={tooltipColorsDict}
                            automaticOpacity={automaticOpacity}
                          />
                        );
                      })
                    }
                  </Pie>
                </Group>
              </svg>
              {/* <div className={styles.pieLegend}>
                {Object.keys(sortedData).map((value, index) => (
                  <div className={styles.glyphBox} key={`legend-item-${index}`}>
                    <div style={{ backgroundColor: colors[index] }} className={styles.glyph} />
                    <span>{value === '' ? 'NULL' : value}</span>
                  </div>
                ))}
              </div> */}
              {/* <Legend
                style={{
                  display: 'flex',
                  margin: '0 auto',
                  gap: '10px',
                }}
                direction='row'
                scale={colorScale}
                labelFormat={(label) => (label === '' ? 'NULL' : label)}
              /> */}
              <Legend />
            </div>
          ) : (
            <div
              className='fw-bolder my-1 fs-4 d-flex justify-content-center'
              style={{ color: '#7e8299' }}
            >
              {mls(
                "Consider using a different chart type, as Pie or Donut charts aren't suitable for negative or zero values!"
              )}
            </div>
          )}
        </>
      ) : (
        <div
          className='fw-bolder my-1 fs-4 d-flex justify-content-center'
          style={{ color: '#7e8299' }}
        >
          {mls('No data to show with the current settings. Please use another settings!')}
        </div>
      )}
    </>
  );
};

PieType.propTypes = {
  data: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  xAxisLabel: PropTypes.string.isRequired,
  yAxisLabel: PropTypes.string.isRequired,
  cardRef: PropTypes.shape().isRequired,
  cardWidth: PropTypes.string.isRequired,
};

export default PieType;

const PieArcs = ({
  index,
  arc,
  pie,
  colorScale,
  centerX,
  maxRadius,
  centerY,
  tooltipHoverHandler,
  handleMouseOver,
  handleMouseLeave,
  handleClick,
  tooltipColorsDict,
  automaticOpacity,
  type,
}) => {
  const value = arc.data.y;
  const arcPath = pie.path(arc);
  let arcFill;

  if (colorScale.domain().includes(arc.data.x)) arcFill = colorScale(arc.data.x);
  else arcFill = hexWithOpacity(getDefaultColorByIndex(index), automaticOpacity);

  // center x and y coordinates of the arc
  const centerAngle = (arc.endAngle + arc.startAngle) / 2 - Math.PI / 2;
  const arcCenterX = centerX + (Math.cos(centerAngle) * maxRadius) / 2;
  const arcCenterY = centerY + (Math.sin(centerAngle) * maxRadius) / 2;

  const datum = { x: arc.data.x, y: arc.data.y };
  const [centroidX, centroidY] = pie.path.centroid(arc);
  const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
  const isDarker = compareBrightness(arcFill);
  const darker = '#333';
  const ligher = '#ddd';
  const textData =
    type === 'DONUT'
      ? {
          centroidX,
          centroidY,
        }
      : {
          centroidX: centroidX + centroidX / 2,
          centroidY: centroidY + centroidY / 2,
        };
  return (
    <>
      <g
        // eslint-disable-next-line react/no-array-index-key
        key={`arc-${value}-${index}`}
        onMouseEnter={(e) => {
          tooltipHoverHandler();
          handleMouseOver(
            {
              x: arcCenterX,
              y: arcCenterY,
              arcFill,
            },
            datum
          );
        }}
        onMouseMove={(e) =>
          handleMouseOver(
            {
              x: arcCenterX,
              y: arcCenterY,
              arcFill,
            },
            datum
          )
        }
        onMouseLeave={(e) => {
          handleMouseLeave();
        }}
        onClick={() => handleClick(datum.x)}
        className={styles.cursorPointer}
      >
        <path
          d={arcPath}
          fill={arcFill}
          strokeWidth={1}
          stroke='#000000'
          // opacity={getStyling(tooltipColorsDict[arc?.data?.x])?.opacity}
          strokeOpacity={0.2}
        />
      </g>
      <g
        // style={{ opacity: 1 }}
        style={{
          background: 'red',
          borderRadius: '5px',
          border: '2px solid blue',
          padding: '5px',
        }}
      >
        {hasSpaceForLabel && (
          <SvgTextWithBackground text={numberFormatter({ value: datum?.y })} textData={textData} />
        )}
      </g>
    </>
  );
};

const SvgTextWithBackground = ({ text, textData }) => {
  const textRef = useRef(null);
  const [bbox, setBbox] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (textRef.current) {
      const boundingBox = textRef.current.getBBox();
      setBbox({ width: boundingBox.width, height: boundingBox.height });
    }
  }, [textRef.current]);
  const darker = '#333';
  const ligher = '#ddd';

  const rectPadding = 10;
  return (
    <>
      <rect
        x={textData.centroidX - bbox.width / 2 - rectPadding / 2}
        y={textData.centroidY - bbox.height / 2 - rectPadding / 2}
        width={bbox.width + rectPadding}
        height={bbox.height + rectPadding}
        fill={darker}
        rx={6}
      />
      <text
        ref={textRef}
        fill={ligher}
        x={textData.centroidX}
        y={textData.centroidY}
        style={{
          background: 'red',
          borderRadius: '5px',
          border: '2px solid blue',
          padding: '5px',
        }}
        // dy='.33em'
        fontSize={9}
        fontWeight={500}
        textAnchor='middle'
        dominantBaseline='middle'
        pointerEvents='none'
      >
        {text}
      </text>
    </>
  );
};
