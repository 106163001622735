import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
// import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import MessageHandling from './messageHandling/MessageHandling';
// import AuthInit from './modules/auth/redux/AuthInit'
import Routes from './routing/Routes';

type Props = {
  basename?: string;
};

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <LayoutProvider>
          <Routes />
          <MessageHandling />
        </LayoutProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export { App };
