import React, { useState } from 'react';
import resetArrow from '../../assets/resetArrow.svg';
import { allDropableID, defaultChartSetting } from '../../data/defaultChartSchema';
import DragableAxis from '../../components/BuildComponents/DragableAxis';
import SlideSwitch from '../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import FilterIndex from '../../components/BuildComponents/FilterDiv/FilterIndex';
import GeoGroups from '../../components/SpacialCasesComponents/GeoCharts/GeoGroups';
import DragableToolTip from '../../components/BuildComponents/DragableToolTip';
import EditColors from '../../components/StyleComponents/ChartDisplay/EditColors';
import ChartInsight from 'lib/chart-settings-lib/components/BuildComponents/ChartInsight';
import { mls } from 'lib/multilanguagesupport';
import SolidColorSelector from 'lib/chart-settings-lib/components/StyleComponents/SolidColorSelector';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';

const GeoMapChart = ({ stateProps }) => {
  const {
    isInsight,
    setIsInsight,
    xAxisData,
    setXAxisData,
    yAxisData,
    setYAxisData,
    setChartYAxisStyle,
    setChartXAxisStyle,
    handleResetToolTip,
    chartType,
    colorAxis,
    setColorAxis,
    setColorAxisStyle,
    geoMapColor,
    setGeoMapColor,
  } = stateProps;
  const { yAxisId, toolTip, xAxisId, groupId } = allDropableID;
  const [resetColor, setResetColor] = useState(false);
  const resetColors = () => {
    setResetColor(!resetColor);
    setGeoMapColor(defaultChartSetting.geoMapColor);
  };
  return (
    <>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('Latitude & Longitude')} *</div>
        <DragableAxis
          dragableDivId={xAxisId}
          axisData={xAxisData}
          setAxisData={setXAxisData}
          stateProps={stateProps}
          setStyle={setChartXAxisStyle}
          defaultLable={defaultChartSetting.xAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      {/* <div className='outerDiv'>
        <div className='MainTitle'>{mls('Longitude')} *</div>
        <DragableAxis
          dragableDivId={xAxisId}
          axisData={xAxisData}
          setAxisData={setXAxisData}
          stateProps={stateProps}
          setStyle={setChartXAxisStyle}
          defaultLable={defaultChartSetting.xAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div> */}
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('Magnitude')} *</div>
        <DragableAxis
          dragableDivId={yAxisId}
          axisData={yAxisData}
          setAxisData={setYAxisData}
          stateProps={stateProps}
          setStyle={setChartYAxisStyle}
          defaultLable={defaultChartSetting.yAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('Color Axis')}</div>
        <DragableAxis
          dragableDivId={groupId}
          axisData={colorAxis}
          setAxisData={setColorAxis}
          stateProps={stateProps}
          setStyle={setColorAxisStyle}
          defaultLable={defaultChartSetting.colorAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>

      {/* <div className='outerDiv open'>
        <div className='MainTitle inFlex'>Group</div>
        <GeoGroups stateProps={stateProps} />
      </div> */}

      <div className='outerDiv open'>
        <div className='MainTitle inFlex'>{mls('Tooltips')}</div>
        <DragableToolTip
          dragableDivId={toolTip}
          stateProps={stateProps}
          allDropableID={allDropableID}
          handleResetToolTip={handleResetToolTip}
        />
      </div>
      {chartType.chartKey === allChartKeys.GEOMAP ? (
        <div className='outerDiv open'>
          <div className='MainTitle'>{mls('Chart Display')}</div>
          <div className='grayContainers CardHeaderImage'>
            <div className='containerItems'>
              <SolidColorSelector
                color={geoMapColor}
                setColor={setGeoMapColor}
                resetColor={resetColor}
                isPalette={false}
                selectColorTitle={mls('Edit Colors')}
              />
            </div>
            <div className='resetDefault' onClick={() => resetColors()}>
              {mls('Reset to defaults')}
            </div>
          </div>
        </div>
      ) : (
        <EditColors stateProps={stateProps} />
      )}

      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls('Default Filters')}</div>

        <FilterIndex stateProps={stateProps} />
        {/* <div className='textAndToggle'>
          <div className='text'>Cross-report</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={isCrossReport} setIsChecked={setIsCrossReport} />{' '}
          </div>
        </div>
        <div className='textAndToggle'>
          <div className='text'>Keep all filters</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={iskeepAllFilters} setIsChecked={setIskeepAllFilters} />
          </div>
        </div> */}
      </div>

      <ChartInsight
        isInsight={isInsight}
        setIsInsight={setIsInsight}
        chartKey={chartType.chartKey}
      />
    </>
  );
};

export default GeoMapChart;
