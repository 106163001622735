import { useEffect, useMemo, useRef, useState } from 'react';

import useDebounce from '../hooks/useDebounce';
import useUpdateEffect from '../hooks/useUpdateEffect';
import { updateChartSchemaInServer } from '../utils/connectionFunction';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Chart from 'lib/visx-lib';
import { getChartSchema } from '../utils/finalSchemaFunction';
import useDivDimensions from 'lib/visx-lib/helpers/divDimensionHelper';
import { BiExitFullscreen, BiFullscreen } from 'react-icons/bi';
import { defaultChartWidthTypes, defaultTimeframe } from '../data/defaultChartSchema';
import DropDown from '../features/DropDown/DropDown';
import { supistaApiPost } from '../utils/connections';
import { CircularProgress } from '@mui/material';
import ObjectDropDown from '../features/NewDropDown/ObjectDropDown';
import { getTimeFrame } from '../utils/chartHelperFunctions';

import { RiTableFill } from 'react-icons/ri';
import DisplayTable from 'lib/reusable-components/DisplayTable/DisplayTable';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { mls } from 'lib/multilanguagesupport';
const ChartArea = (props) => {
  const { chartSchemaMemo, stateProps, toggleProps, setMinimizedDiv } = props;
  const {
    chartType,
    chatName,
    setChatName,
    chartDescription,
    setChartDescription,
    setChartWidth,
    allTableData,
    setTimeFrame,
    setDefaultKpiTargets,
    setKpiTargetPoints,
    defaultDateTimeCol,
    tableDetails,
    tableID,
    //Need for udpate Chart Plot Data
    isForecast,
    isCrossReport,
    isDateHierarchy,
    isInsight,
    iskeepAllFilters,
    xAxisData,
    yAxisData,
    chartFilter,
    timeFrame,
    //Need for udpate Chart Schmea Data

    // Normal Chart
    chartXAxisStyle,
    chartYAxisStyle,
    chartToolTip,
    isZoomBrush,
    plotAreaBackground,
    gridLines,
    chartWidth,
    columnDropNumber,
    // Special Case Chart
    //CARD
    chartHeaderImage,
    chartCardType,
    //KPIMETER
    kpiTargetPoints,
    //GEO
    geoGroup,
    //GEOMAP
    geoMapColor,
    //BARSTACKLINE
    barStackLineTargetPoints,
    //PLOT
    plotGroup,
    plotGroupStyle,
    //PIVOTTABLE
    pivotTableTitleStyle,
    //HEATMAP
    heatMapGroup,
    heatMapColor,
    //TREEMAP
    treeMapColor,
    //COLOR
    colorAxis,
    colorAxisStyle,
    //DOUBLEAXIS
    doubleAxis,
  } = stateProps;

  const { toggleColumnDetails, minimizedDiv } = toggleProps;
  const [currentTimeFrame, setCurrentTimeFrame] = useState(() => {
    if (timeFrame?.[defaultDateTimeCol]) {
      return `${timeFrame?.[defaultDateTimeCol]?.value?.xDay}`;
    }
    return 'null';
  });
  const [tableDetailsToggle, setTableDetailsToggle] = useState(false);
  const chartUpdateByAPIDebounceArray = useMemo(() => {
    return [
      // Normal Chart
      chartType,
      isForecast,
      isCrossReport,
      isDateHierarchy,
      isInsight,
      iskeepAllFilters,
      xAxisData,
      yAxisData,
      chartFilter,
      timeFrame,
      //KPIMETER
      kpiTargetPoints,
      //HEATMAP
      heatMapGroup,
      //COLOR
      colorAxis,
    ];
  }, [
    // Normal Chart
    chartType,
    isForecast,
    isCrossReport,
    isDateHierarchy,
    isInsight,
    iskeepAllFilters,
    xAxisData,
    yAxisData,
    chartFilter,
    timeFrame,
    //KPIMETER
    kpiTargetPoints,
    //HEATMAP
    heatMapGroup,
    //COLOR
    colorAxis,
  ]);
  const chartSchemaUpdateDebounceArray = useMemo(() => {
    return [
      // Normal Chart
      chatName,
      chartDescription,
      chartXAxisStyle,
      chartYAxisStyle,
      chartToolTip,
      isZoomBrush,
      plotAreaBackground,
      gridLines,
      chartWidth,
      columnDropNumber,
      // Special Case Chart
      //CARD
      chartHeaderImage,
      chartCardType,

      //GEO
      geoGroup,
      //GEOMAP
      geoMapColor,
      //BARSTACKLINE
      barStackLineTargetPoints,
      //PLOT
      plotGroup,
      plotGroupStyle,
      //PIVOTTABLE
      pivotTableTitleStyle,
      //HEATMAP
      heatMapColor,
      //TREEMAP
      treeMapColor,
      //COLOR
      colorAxisStyle,
      //DOUBLEAXIS
      doubleAxis,
    ];
  }, [
    // Normal Chart
    chatName,
    chartDescription,
    chartXAxisStyle,
    chartYAxisStyle,
    chartToolTip,
    isZoomBrush,
    plotAreaBackground,
    gridLines,
    chartWidth,
    columnDropNumber,
    // Special Case Chart
    //CARD
    chartHeaderImage,
    chartCardType,

    //GEO
    geoGroup,
    //GEOMAP
    geoMapColor,
    //BARSTACKLINE
    barStackLineTargetPoints,
    //PLOT
    plotGroup,
    plotGroupStyle,
    //PIVOTTABLE
    pivotTableTitleStyle,
    //HEATMAP
    heatMapColor,
    //TREEMAP
    treeMapColor,
    //COLOR
    colorAxisStyle,
    //DOUBLEAXIS
    doubleAxis,
  ]);

  const chartUpdateDebounce = useDebounce(chartUpdateByAPIDebounceArray, 300);
  const chartSchemaUpdateDebounce = useDebounce(chartSchemaUpdateDebounceArray, 300);

  const presentActiveColumn = useMemo(() => {
    let filtered = minimizedDiv.filter(Boolean);
    if (filtered?.length === 0) {
      return 'twoColumnOpen';
    }
    if (filtered?.length === 1) {
      return 'oneColumnOpen';
    }
    return '';
  }, [minimizedDiv]);

  const handleChartDiscription = (evt) => {
    setChartDescription(evt.target.value);
  };

  const handleFullScreen = () => {
    if (minimizedDiv[0] === '' || minimizedDiv[1] === '') {
      setMinimizedDiv([
        toggleColumnDetails.firstColumn.toggleKeyName,
        toggleColumnDetails.secondColumn.toggleKeyName,
      ]);
      return;
    }
    setMinimizedDiv(['', '']);
  };

  const handleTimeFrame = (action) => {
    const finalTimeFrame = getTimeFrame({
      action: action,
      tableSchema: allTableData,
      defaultDateTimeCol,
    });
    setTimeFrame(finalTimeFrame);
    setCurrentTimeFrame(action);
  };
  useEffect(() => {
    setCurrentTimeFrame(() => {
      if (timeFrame?.[defaultDateTimeCol]) {
        return `${timeFrame?.[defaultDateTimeCol]?.value?.xDay}`;
      }
      return 'null';
    });
  }, [timeFrame]);
  const handleTable = () => {
    setTableDetailsToggle((currentToggle) => !currentToggle);
  };
  return (
    <div className={`thirdColumn ${presentActiveColumn}`}>
      <PopUpDivChild
        setPopUp={setTableDetailsToggle}
        initialValue={tableDetailsToggle}
        className='tableDisplay'
        popupTitle='Table Head'
      >
        <DisplayTable tableData={tableDetails} />
      </PopUpDivChild>
      <div className='chartNameDiv'>
        <div className='titleDiv'>{mls('Chart Name')}:</div>

        <input
          type='text'
          placeholder='Name of Chart'
          value={chatName}
          onChange={(e) => setChatName(e.target.value)}
        />
      </div>
      <div className='chartDescriptionDiv'>
        <div className='titleDiv'>{mls('Chart Description')}:</div>

        <TextareaAutosize
          maxRows={3}
          minRows={1}
          onChange={(e) => handleChartDiscription(e)}
          placeholder={mls('Description of Chart')}
          value={chartDescription}
        />
      </div>
      <div className='chartWidthOuterDiv'>
        {/* <div className='chartWidthDiv'>
          <div className='titleDiv'>TimeFrame:</div>
          <div className='chartWidthDropDown'>
            <DropDown
              array={defaultChartWidthTypes}
              dropDownValue={chartWidth}
              setdropDownValue={setChartWidth}
              isKey={true}
            />
          </div>
        </div> */}

        {defaultDateTimeCol ? (
          <div className='chartWidthDiv'>
            <div className='titleDiv'>
              {mls('TimeFrame')}
              <span>:</span>
            </div>
            <div className='chartWidthDropDown'>
              <ObjectDropDown
                object={defaultTimeframe}
                dropDownValue={currentTimeFrame}
                setdropDownValue={handleTimeFrame}
                ObjectInObject={true}
              />
            </div>
          </div>
        ) : null}
        <div className='chartWidthDiv'>
          <div className='titleDiv'>
            {mls('Chart Width')}
            <span>:</span>
          </div>
          <div className='chartWidthDropDown'>
            <DropDown
              array={defaultChartWidthTypes}
              dropDownValue={chartWidth}
              setdropDownValue={setChartWidth}
            />
          </div>
        </div>
        <div className='fullScreenIcon ' onClick={() => handleTable()}>
          <RiTableFill />
        </div>
        <div className='fullScreenIcon ' onClick={() => handleFullScreen()}>
          {minimizedDiv[0] === '' || minimizedDiv[1] === '' ? (
            <BiFullscreen />
          ) : (
            <BiExitFullscreen />
          )}
        </div>
      </div>

      <div className='chartPlotArea'>
        <ChartDiv
          chartUpdateDebounce={chartUpdateDebounce}
          chartSchemaUpdateDebounce={chartSchemaUpdateDebounce}
          chartSchemaMemo={chartSchemaMemo}
          chartType={chartType}
          setMinimizedDiv={setMinimizedDiv}
          toggleProps={toggleProps}
          chartWidth={chartWidth}
          setChartWidth={setChartWidth}
          allTableData={allTableData}
          yAxisData={yAxisData}
          setDefaultKpiTargets={setDefaultKpiTargets}
          setKpiTargetPoints={setKpiTargetPoints}
          chartFilter={chartFilter}
          timeFrame={timeFrame}
          tableID={tableID}
        />
      </div>
    </div>
  );
};

export default ChartArea;

const ChartDiv = (chartDivProps) => {
  const {
    chartSchemaMemo,
    chartUpdateDebounce,
    chartSchemaUpdateDebounce,
    chartType,
    allTableData,
    chartFilter,
    timeFrame,
    tableID,
  } = chartDivProps;
  const [isChartDisplayable, setisChartDisplayable] = useState(false);
  const [plotingInProgress, setPlotingInProgress] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);

  // const [plotData2, setPlotData] = useState({});
  // const [chartSchema2, setChartSchema] = useState({});
  // const plotData = allPlottingData.charts['a0aaf080-53b4-11ee-8f64-4bfa82b0440c']
  // const chartSchema = chartSchemaTest['a0aaf080-53b4-11ee-8f64-4bfa82b0440c']
  const [plotData, setPlotData] = useState({});
  const [chartSchema, setChartSchema] = useState({});
  const containerRef = useRef(null);
  const { width: mainContainerWidth } = useDivDimensions(containerRef);
  useUpdateEffect(async () => {
    setPlotingInProgress(true);
    setisChartDisplayable(false);
    setLoadingScreen(true);
    const schemaData = await updateChartSchemaInServer({
      chartSchemaMemo,
      chartType,
      endPoint: 'createCharts',
    });
    if (schemaData.skip) {
      // setPlotingInProgress(false);
      // setisChartDisplayable(false);
      setLoadingScreen(false);
      return;
    }
    setChartSchema(schemaData.chartSchema);
    setPlotData(schemaData.plotData);
    setisChartDisplayable(true);
    setPlotingInProgress(false);
    setLoadingScreen(false);
  }, chartUpdateDebounce);

  useUpdateEffect(async () => {
    if (plotingInProgress) {
      return;
    }
    setLoadingScreen(true);
    setisChartDisplayable(false);
    const dataByChart = getChartSchema({ chartSchemaMemo, chartType, normal: undefined });
    const chartID = Object.keys(dataByChart?.data?.charts)[0];
    const finalSchema = dataByChart?.data?.charts[chartID];
    setChartSchema(finalSchema);
    setisChartDisplayable(true);
    setLoadingScreen(false);
  }, chartSchemaUpdateDebounce);

  const chartHeight = useMemo(() => {
    // if (minimizedDiv[0] === '' || minimizedDiv[1] === '') {
    //   return mainContainerWidth;
    // }
    const finalHeight =
      mainContainerWidth <= 576
        ? mainContainerWidth // sm
        : mainContainerWidth <= 992
        ? // ? mainContainerWidth / 3 // md
          mainContainerWidth / 2 // md
        : mainContainerWidth / 3; // lg
    return finalHeight;
  }, [mainContainerWidth]);
  // allPlottingData
  // chartSchema
  const handleChartUpdates = async (updatedValue) => {
    const endPoint = 'createCharts';
    const dataByChart = getChartSchema({ chartSchemaMemo, chartType, normal: undefined });
    if (dataByChart.skip) {
      return;
    }
    const newData = { ...dataByChart.data, chartUpdates: updatedValue };
    const res = await supistaApiPost(endPoint, newData, chartSchemaMemo.appID);
    if (!res.__d3__error) {
      const chartID = Object.keys(dataByChart?.data?.charts)[0];
      const schemaData = {
        skip: false,
        chartSchema: dataByChart?.data?.charts[chartID],
        plotData: res?.response?.charts[chartID],
      };
      if (schemaData.skip) {
        return;
      }
      setChartSchema(schemaData.chartSchema);
      setPlotData(schemaData.plotData);
    }
  };
  if (loadingScreen) {
    return (
      <div className='chartPlotArea' ref={containerRef}>
        <div className='chartLoadingArea'>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className='chartPlotArea' ref={containerRef}>
      {isChartDisplayable ? (
        <Chart
          chartKey={chartSchema?.chartID}
          chartData={chartSchema ?? {}}
          dataToPlot={plotData ?? {}}
          cardWidth={chartSchema?.chartWidth || 'HALF'}
          // useChartSettingsButton={chartSettingButtonFunction}
          // useChartSettingsButton={() => {
          //   return () => chartSettingButtonFunction;
          // }}
          useChartSettingsButton={() => {
            return () => {};
          }}
          handleChartUpdates={handleChartUpdates}
          // handleChartUpdates={() => {
          //   return () => chartSettingButtonFunction;
          // }}
          isInsightChartSchema={true}
          updateFilter={() => {}}
          isProd={false}
          isUpdateLoading={false}
          filterSchema={allTableData}
          chartHeight={chartHeight}
          timeFrameObj={timeFrame}
          filterObj={chartFilter}
          tableID={tableID}
        />
      ) : null}
    </div>
  );
};
