import './slideSwitch.scss';
const SlideSwitch = ({ isChecked = false, setIsChecked, type = 'regular', disabled = false }) => {
  // type "small" | "large" |"regular"
  const toggleChecked = (e) => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <div className={`CustomSlideSwitch SlideSwitchToggle ${type} ${disabled ? 'disabled' : ''} `}>
        <span>
          <input
            type='checkbox'
            id='toggleInput'
            checked={isChecked}
            onChange={(e) => toggleChecked(e)}
            disabled={disabled}
          />
          <button
            className='SlideSwitchSlider'
            type='button'
            onClick={(e) => toggleChecked(e)}
          ></button>
        </span>
      </div>
    </>
  );
};

export default SlideSwitch;
