import React from 'react';
import { AsideDefault } from './components/aside/AsideDefault';
import { Footer } from './components/Footer';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { ScrollTop } from './components/ScrollTop';
import { Content } from './components/Content';
import { MasterInit } from './MasterInit';
import { PageDataProvider } from './core';
import { DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan } from '../partials';
import { useHistory, useParams } from 'react-router-dom';

interface paramsObject {
  [key: string]: string;
}

type Props = {
  appName: string;
};

const MasterLayout: React.FC<Props> = ({ children, appName }) => {
  let params: paramsObject = useParams();
  const history = useHistory();
  const { appID } = params;

  return (
    <PageDataProvider key={history.location.pathname}>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault appID={appID} appName={appName} />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper appID={appID} appName={appName} />
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <Content>{children}</Content>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };
