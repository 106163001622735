import React, { useEffect, useRef, useState } from 'react';
import './kpiDashboard.scss';
import ArrayDropDown from 'lib/reusable-components/Components/DropDowns/ArrayDropDown';
import { mls } from 'lib/multilanguagesupport';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { _supistaAnalyticsApi } from 'lib/server-connection/connections';
import KpiChartSettings from '../KpiChartSettings/KpiChartSettings';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import KpiDashBoardChartArea from './KpiDashBoardChartArea';
import { CircularProgress } from '@mui/material';
import { useKpiSettingsButton } from 'lib/reusable-components/reusableUtils/updateSchema/useChartSettingsButton';
import NoAssetScreen from 'lib/reusable-components/noAssetScreens/NoAssetScreen';
import { defaultKpiSettingsPopupData, defaultTargetID } from './kpiDashboardData';
import { UpdateCircleAndEmployeeArray } from './kpiDashboardUtils';
const KpiDashboard = ({ isDevlopment = true }: { isDevlopment?: boolean }) => {
  const location = useLocation();
  const appID = location.pathname.split('/')[1];
  const kpiAllSchema = useSelector(
    (state: { [key: string]: any }) => state?.config_store?.kpiAllSchema || {}
  );

  const accessType = useSelector(
    (state: { [key: string]: any }) =>
      state?.config_store?.analyticsSchema?.appAccess?.accessType || []
  );

  const kpiSettingsPopUpData = useSelector(
    (state: { [key: string]: any }) =>
      state.config_store?.appState?.kpiSettings?.kpiChartID || defaultKpiSettingsPopupData
  );
  const kpiSettingsButton = useKpiSettingsButton();
  const isAccessChartSettings =
    accessType.includes('ALLOWED_ALL') ||
    accessType.includes('ALL_CREATOR') ||
    accessType.includes('CHART_CREATOR');
  const KpiDepartments = Object.values(kpiAllSchema?.KpiDepartments ?? {});
  const KpiCirclesDefault = Object.values(kpiAllSchema?.KpiCircles ?? {});
  const KpiEmployeesDefault = Object.values(kpiAllSchema?.KpiEmployees ?? {});
  const [KpiCircles, setKpiCircles] = useState([]);
  const [KpiEmployees, setKpiEmployees] = useState([]);
  const KpiCharts = kpiAllSchema?.KpiCharts ?? {};
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedTargetID, setSelectedTargetID] = useState(defaultTargetID);
  const [selectedCircleID, setSelectedCircleID] = useState('');
  const [selectedEmployeesID, setSelectedEmployeesID] = useState('');
  const [dashBoardChartsPlotData, setDashBoardChartsPlotData] = useState([]);
  const [updateChartCount, setUpdateChartCount] = useState(0);
  const dashBoardUpdating = useRef(false);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const handleKPISettingsPopUp = () => {
    kpiSettingsButton({
      ...kpiSettingsPopUpData,
      isPopup: false,
    });
  };

  const getDashboardData = async ({
    selectedDepartment,
    selectedCircleID,
    selectedEmployeesID,
  }: {
    selectedDepartment: string;
    selectedCircleID: string;
    selectedEmployeesID: string;
    // selectedCircleID: { [key: string]: any };
  }) => {
    dashBoardUpdating.current = true;
    const payloadData = {
      departmentID: selectedDepartment,
      circleID: selectedCircleID,
      employeeID: selectedEmployeesID,
    };
    const res = await _supistaAnalyticsApi(`${appID}/kpiDashboard`, payloadData)
      .then(async (response) => {
        if (response.__d3__error) {
          dashBoardUpdating.current = false;
          return { skip: true, data: [], msg: response?.response?.msg };
        } else {
          dashBoardUpdating.current = false;
          return { skip: false, data: response?.response?.charts ?? [], msg: undefined };
        }
      })
      .catch((error) => {
        dashBoardUpdating.current = false;
        console.log(error);
        // toast.error(mls('Some issues encountered.'));
        return { skip: true, data: [], msg: undefined };
      });
    dashBoardUpdating.current = false;
    return res;
  };

  const handleDepartment = (departmentID: string) => {
    const {
      firstPositionTargetID,
      kpiCirclesArray,
      kpiEmployeesArray,
      isAnyCircle,
      isAnyEmployees,
      defaultCircleID,
      defaultEmployeesID,
    } = UpdateCircleAndEmployeeArray({
      departmentID,
      KpiCircles: kpiAllSchema?.KpiCircles,
      KpiEmployees: kpiAllSchema?.KpiEmployees,
      KpiCharts: kpiAllSchema?.KpiCharts,
    });
    if (isAnyCircle) {
      setSelectedCircleID(defaultCircleID);
      setSelectedEmployeesID('');
    } else {
      if (isAnyEmployees) {
        setSelectedEmployeesID(defaultEmployeesID);
      } else {
        setSelectedEmployeesID('');
      }
      setSelectedCircleID('');
    }

    const finalKpiCircle: any = !isAnyCircle ? [] : [noSelection, ...kpiCirclesArray];
    const finalKpiEmployees: any = !isAnyEmployees ? [] : [noSelection, ...kpiEmployeesArray];
    setKpiCircles(finalKpiCircle);
    setKpiEmployees(finalKpiEmployees);
    setSelectedTargetID(firstPositionTargetID);
    setSelectedDepartment(departmentID);
    setDashBoardChartsPlotData([]);
  };
  const handleCircle = (circleID: string) => {
    if (circleID === noSelection.circleID) {
      setSelectedCircleID('');
      if (selectedEmployeesID) {
        setSelectedTargetID({ id: selectedEmployeesID, type: 'circles' });
      } else {
        setSelectedTargetID({ id: '', type: '' });
      }
      setSelectedTargetID({ id: circleID, type: 'circles' });
    } else {
      setSelectedCircleID(circleID);
      setSelectedTargetID({ id: circleID, type: 'circles' });
      setSelectedEmployeesID('');
    }
  };
  const handleEmployees = (employeeID: string) => {
    if (employeeID === noSelection.circleID) {
      setSelectedEmployeesID('');
      if (selectedCircleID) {
        setSelectedTargetID({ id: selectedCircleID, type: 'circles' });
      } else {
        setSelectedTargetID({ id: '', type: '' });
      }
    } else {
      setSelectedEmployeesID(employeeID);
      setSelectedTargetID({ id: employeeID, type: 'employees' });
    }
  };
  useEffect(() => {
    if (dashBoardUpdating.current) {
      return;
    }
    if (selectedDepartment && (selectedCircleID || selectedEmployeesID)) {
      (async () => {
        setChartDataLoading(true);
        const dashboardData = await getDashboardData({
          selectedDepartment,
          selectedCircleID,
          selectedEmployeesID,
        });
        if (dashboardData.skip) {
          toast.error(dashboardData?.msg ?? 'No KPIs present for selected department and circle!');
          setDashBoardChartsPlotData([]);
        } else {
          if (!dashboardData.data[0]) {
            toast.error(
              dashboardData?.msg ?? 'No KPIs present for selected department and circle!'
            );
          }
          setDashBoardChartsPlotData(dashboardData.data);
        }
        setChartDataLoading(false);
      })();
    } else {
      setDashBoardChartsPlotData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment, selectedCircleID, selectedEmployeesID]);

  const handleChartUpdate = async ({
    selectedDepartment,
    isDelete,
    selectedTargetPointID,
  }: {
    selectedDepartment: string;
    isDelete: boolean;
    selectedTargetPointID: any;
  }) => {
    kpiSettingsButton({
      ...kpiSettingsPopUpData,
      isPopup: false,
      isNew: true,
      kpiSchema: { chartID: uuidv4() },
    });

    setSelectedDepartment(selectedDepartment);

    if (selectedTargetPointID?.id) {
      setSelectedTargetID(selectedTargetPointID);
      if (selectedTargetPointID.type === 'circles') {
        setSelectedEmployeesID('');
        setSelectedCircleID(selectedTargetPointID?.id);
      }
      if (selectedTargetPointID.type === 'employees') {
        setSelectedCircleID('');
        setSelectedEmployeesID(selectedTargetPointID?.id);
      }
    }
    setUpdateChartCount((currentCount) => currentCount + 1);
    // if (selectedDepartment && (selectedTargetPointID?.id || selectedTargetID?.id)) {
    //   const dashboardData = await getDashboardData({
    //     selectedDepartment,
    //     // selectedTargetID: selectedTargetPointID.id ? selectedTargetPointID : selectedTargetID,
    //     selectedCircleID,
    //     selectedEmployeesID,
    //   });
    //   if (dashboardData.skip) {
    //     toast.error(dashboardData?.msg ?? 'No KPIs present for selected department and circle!');
    //   } else {
    //     setDashBoardChartsPlotData(dashboardData.data);
    //   }
    // }
  };
  useEffect(() => {
    const {
      firstPositionTargetID,
      kpiCirclesArray,
      kpiEmployeesArray,
      isAnyCircle,
      isAnyEmployees,
    } = UpdateCircleAndEmployeeArray({
      departmentID: selectedDepartment,
      KpiCircles: kpiAllSchema?.KpiCircles,
      KpiEmployees: kpiAllSchema?.KpiEmployees,
      KpiCharts: kpiAllSchema?.KpiCharts,
    });
    const finalKpiCircle: any = !isAnyCircle ? [] : [noSelection, ...kpiCirclesArray];
    const finalKpiEmployees: any = !isAnyEmployees ? [] : [noSelection, ...kpiEmployeesArray];
    setKpiCircles(finalKpiCircle);
    setKpiEmployees(finalKpiEmployees);
  }, [
    kpiAllSchema?.KpiCircles,
    kpiAllSchema?.KpiEmployees,
    kpiAllSchema?.KpiCharts,
    updateChartCount,
  ]);

  const addKPIChart = () => {
    kpiSettingsButton({
      ...defaultKpiSettingsPopupData,
      kpiSchema: { chartID: uuidv4() },
      isPopup: true,
      isNew: true,
    });
  };

  return (
    <div className='KpiDashboard DefaultScroll'>
      <ToastContainer position='bottom-left' theme='colored' />
      <div className='kpiDashboardHeader'>
        <div className='selectionContainer'>
          <div className='selectInnerContainer'>
            <div className='headerTitle'>{mls('Department')}:</div>
            <div className='selectDepartment'>
              <ArrayDropDown
                array={KpiDepartments}
                dropDownValue={selectedDepartment}
                setdropDownValue={handleDepartment}
                mainKey={'departmentID'}
                inputPlaceHolder={mls('Select Department')}
                nameKey={'name'}
                ObjectInArray
              />
            </div>
          </div>
          {/* {selectedDepartment ? (
            <>
              <div className='headerTitle'>{mls('Circle/Employees')}:</div>
              <div className='selectCircle'>
                <ObjectDropDown
                  object={circleAndEmployeesGroupedData}
                  dropDownValue={selectedTargetID.id ?? ''}
                  setdropDownValue={(action: { [key: string]: any }) => {
                    setSelectedTargetID({ id: action.data, type: action.groupKey });
                  }}
                  inputPlaceHolder={'Select'}
                  groupDropDown
                  returnGroupKeyWithValue
                />
              </div>
            </>
          ) : null} */}
          {selectedDepartment ? (
            <>
              <div className='selectInnerContainer'>
                <div className='headerTitle'>{mls('Circle')}:</div>
                <div className='selectCircle'>
                  <ArrayDropDown
                    array={KpiCircles}
                    dropDownValue={selectedCircleID ?? ''}
                    setdropDownValue={handleCircle}
                    mainKey={'circleID'}
                    inputPlaceHolder={mls('Select Circle')}
                    nameKey={'name'}
                    ObjectInArray
                  />
                </div>
              </div>
            </>
          ) : null}
          {selectedDepartment ? (
            <>
              <div className='selectInnerContainer'>
                <div className='headerTitle'>{mls('Employees')}:</div>
                <div className='selectCircle'>
                  <ArrayDropDown
                    array={KpiEmployees}
                    dropDownValue={selectedEmployeesID ?? ''}
                    setdropDownValue={handleEmployees}
                    mainKey={'employeeID'}
                    inputPlaceHolder={mls('Select Employees')}
                    nameKey={'email'}
                    ObjectInArray
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
        {isDevlopment ? (
          <button
            className='primaryButton'
            onClick={() => {
              addKPIChart();
            }}
          >
            {mls('Add KPI Chart')}
          </button>
        ) : null}
      </div>
      {kpiSettingsPopUpData.isPopup ? (
        <KpiChartSettings
          chartID={kpiSettingsPopUpData?.kpiSchema?.chartID ?? uuidv4()}
          kpiSchema={kpiSettingsPopUpData?.kpiSchema}
          isNewKPIChart={kpiSettingsPopUpData.isNew}
          handleKPISettingsPopUp={handleKPISettingsPopUp}
          handleChartUpdate={handleChartUpdate}
          departmentsData={KpiDepartments}
          circlesData={KpiCirclesDefault}
          employeesData={KpiEmployeesDefault}
          selectedTargetID={selectedTargetID}
        />
      ) : null}
      {chartDataLoading ? (
        <div
          className='loadingScreen'
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {(dashBoardChartsPlotData || []).length === 0 && (
            <div className='mt-15'>
              <NoAssetScreen assetType='KpiDashboard' />
            </div>
          )}
          <KpiDashBoardChartArea
            dashBoardChartsPlotData={dashBoardChartsPlotData}
            KpiCharts={KpiCharts}
            kpiSettingsPopUpData={kpiSettingsPopUpData}
            isAccessChartSettings={isAccessChartSettings}
          />
        </>
      )}
    </div>
  );
};

export default KpiDashboard;
const noSelection = {
  name: 'None',
  email: 'None',
  circleID: '__d3__NO__SELECT',
  employeeID: '__d3__NO__SELECT',
};
