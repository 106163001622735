import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
// import _ from 'lodash';

import { mls } from 'lib/multilanguagesupport';

import TableToJoin from '../TableToJoin';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';
import { Form } from 'react-bootstrap-v5';
import { _supistaAnalyticsApi, _supistaApiDelete } from 'lib/server-connection/connections';
import {
  deleteSheetandCharts,
  getReportDashIDsTBD,
  getSheetChartsIDsTBD,
} from 'lib/reusable-components/reusableFunction/deleteDataDependencies';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import charactersNotAllowedInTableAndColumnName from 'lib/data-connectors/dependencies/charactersNotAllowedInTableAndColumnName';
import useAddNewSheets from 'lib/data-connectors/screens/data-source/components/useAddNewSheets';
import useUpdateJoinTableSchema from 'lib/data-connectors/dependencies/useUpdateJoinTablesSchema';
import CopyColumnToClipBoard from 'lib/data-connectors/dependencies/CopyColumnToClipBoard/CopyColumnToClipBoard';
import { TableCard } from './TableListAssests';
import { toast } from 'react-toastify';

interface editTableListProps {
  appID: string;
  handleClose: Function;
  isAlreadyTable: boolean;
  setIsAlreadyTable: Function;
  selectedTable: any;
  setSelectedTable: Function;
  tableOriginalName: any;
  setTableOriginalName: Function;
  appJointable: any;
  joinedTables: any;
  setJoinedTables: Function;
  useUpdateSchema: Function;
  setUpdateSqlCustomCommands: Function;
  appDatatable: { [key: string]: any };
  formik: any;
  setIsAnyChanges: Function;
}
const EditTableList = ({
  appID,
  handleClose,
  isAlreadyTable,
  setIsAlreadyTable,
  selectedTable,
  setSelectedTable,
  tableOriginalName,
  setTableOriginalName,
  appJointable,
  appDatatable,
  useUpdateSchema,
  setUpdateSqlCustomCommands,
  formik,
  setIsAnyChanges,
}: editTableListProps) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [tableNameError, setTableNameError] = useState<boolean | string>(false);
  const [submitting, setSubmitting] = useState(false);

  const datetimeCols = useRef<{ [key: string]: any }>([]);
  const [defaultDatetimeColumnName, setDefaultDatetimeColumnName] = useState('');

  const addNewSheets: Function = useAddNewSheets();

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );

  const sheetsSchema = analyticsSchema?.appSheets || {};
  const reportSchema = analyticsSchema.appReports || {};
  const dashSchema = analyticsSchema.appDash || {};
  const updateJoinTableSchema = useUpdateJoinTableSchema();

  const getAllTableName = () => {
    let allTableName = {};
    Object.keys(appDatatable).forEach((tableID) => {
      allTableName = {
        ...allTableName,
        [tableID]:
          appDatatable?.[tableID]?.lable ??
          appDatatable?.[tableID]?.name ??
          appDatatable?.[tableID]?.tableID,
      };
    });
    return allTableName;
  };
  // map of tableID and its name to display in the frontend.
  const allTableIDAndNamesMap = useRef<{ [key: string]: any }>(getAllTableName() ?? {});

  const applyTableName = (columnName: string) => {
    const columnNameArray = columnName.split('.');
    const last = columnNameArray.pop();
    const tableName = columnNameArray.join('.');
    setIsAnyChanges(true);
    return (allTableIDAndNamesMap.current[tableName] || tableName) + '.' + last;
  };

  const selectColumn = (col: any) => {
    const currentlySelectedColumns = [...(formik.values.columnSchema || [])];
    currentlySelectedColumns.push(col);
    formik.setFieldValue('columnSchema', currentlySelectedColumns);
    setIsAnyChanges(true);
  };
  const deselectColumn = (colToBeRemoved: any) => {
    const currentlySelectedColumns = [...(formik.values.columnSchema || [])];
    const columnSchemaAfterRemovingColumn = currentlySelectedColumns.filter(
      (col) => col.columnName !== colToBeRemoved.columnName
    );
    formik.setFieldValue('columnSchema', columnSchemaAfterRemovingColumn);
    setIsAnyChanges(true);
  };

  const saveTableData = async ({ finalTable }: { finalTable: any }) => {
    const saveRes = await _supistaAnalyticsApi(
      `${appID}/opType/saveJoinTable/tableOperations`,
      finalTable
    )
      .then(async (response) => {
        return { skip: false, msg: response };
      })
      .catch((error) => {
        toast.error(
          mls("Some issues encountered. Couldn't able to Save Join Table. Please try again later.")
        );
        return { skip: true, msg: error };
      });

    return saveRes;
  };

  const handleSave = async ({ values }: { values: any }) => {
    const isSchemaValidated = await formik.submitForm();
    if (!values.name || submitting || !isSchemaValidated) return;
    setSubmitting(true);
    const currentTimestamp = Date.now();
    // Array of all tables being joined
    const tables = [values.primaryTable].concat(
      values.joinTableSchema.map((singleJoinTableSchema: any) => {
        return singleJoinTableSchema.joinTableName;
      })
    );
    const isNew = !values.tableID;
    const tableID = values.tableID || uuid();
    const getSheetIDList = (tableID: string) => {
      const { sheetIDsTBD } = getSheetChartsIDsTBD([], [tableID], sheetsSchema);
      return sheetIDsTBD ?? [];
    };
    const sendToBackend = {
      appID,
      tableID,
      isNew,
      tableSchema: {
        ...values,
        appID,
        _id: appID,
        name: values.name,
        tableID,
        tableType: 'JOINED_TABLE',
        tables,
        columnSchema: values.columnSchema,
        primaryTable: values.primaryTable,
        datetimeCols: [...(datetimeCols.current?.colNames || [])],
        defaultDatetimeCol: defaultDatetimeColumnName,
        joinTableSchema: values.joinTableSchema,
        updatedAt: currentTimestamp,
        createdAt:
          isAlreadyTable && selectedTable?.createdAt ? selectedTable.createdAt : currentTimestamp,
      },
      sheetIDLists: isNew ? [] : getSheetIDList(tableID),
      sqlPresent: values?.sqlQuery ? true : false,
    };
    const saveData = await saveTableData({ finalTable: sendToBackend });
    if (saveData?.skip || saveData.msg.__d3__error || !saveData?.msg?.response?.isSuccess) {
      toast.error(
        mls(
          saveData?.msg?.response?.errorMsg ??
            "Some issues encountered. Couldn't able to Save Join Table. Please try again later."
        )
      );
      setSubmitting(false);
      setIsAnyChanges(false);
      return;
    }
    publishToProduction(appID);
    if (isNew) {
      // adding sheet for the joined Table
      const defaultTable = {
        appID,
        name: values.name,
        tableID,
        tableType: 'JOINED_TABLE',
        tables,
        datetimeCols: [...(datetimeCols.current?.colNames || [])],
        defaultDatetimeCol: defaultDatetimeColumnName,
        _id: appID,
      };
      await addNewSheets({
        appID,
        tableName: values.name,
        defaultDatetimeColumnName,
        successMesage: mls(
          'Charts Auto generated by Supista AI and added to the Sheet successfully!'
        ),
        defaultTable,
      });
      updateJoinTableSchema(
        false,
        null,
        sendToBackend?.tableSchema,
        sendToBackend?.tableSchema.tableID,
        tableOriginalName
      );
      setIsAlreadyTable(true);
    }
    toast.success(mls('Join Tables Saved successfully'));
    setSubmitting(false);
    setSelectedTable(sendToBackend.tableSchema);
    formik.setValues(sendToBackend?.tableSchema);
    setUpdateSqlCustomCommands((currentValue: number) => currentValue + 1);
  };

  // Checks the validity of table name on each change
  useEffect(() => {
    const tableName = formik.values.name;
    // Skip the check if editing joined table and user reverts to the table name already used for this table
    if (isAlreadyTable && tableName === tableOriginalName) {
      return;
    }
    const containsCharacterWhichIsNotAllowed = charactersNotAllowedInTableAndColumnName.some(
      (char) => {
        return tableName?.includes(char);
      }
    );
    const tableWithThisNameAlreadyExists = appJointable?.[tableName] ? true : false;
    tableWithThisNameAlreadyExists && setTableNameError('Table name must be unique');
    containsCharacterWhichIsNotAllowed &&
      setTableNameError(
        "Table name can only contain these special characters: space (' '), hyphen (-) and underscore (_)"
      );
    !containsCharacterWhichIsNotAllowed &&
      !tableWithThisNameAlreadyExists &&
      setTableNameError(false);
  }, [formik.values.name, appJointable, isAlreadyTable, tableOriginalName]);

  // updating dateTimeCols on change in the values of formic.
  useEffect(() => {
    if (
      formik.values?.primaryTable &&
      (appDatatable?.[formik.values.primaryTable]?.columnSchema || []).length > 0
    ) {
      datetimeCols.current = getDateTimeCols(formik.values, null, null, true);
      if ((datetimeCols.current?.colNames || []).length > 0)
        setDefaultDatetimeColumnName(datetimeCols.current?.colNames[0]);
    }
  }, [formik.values, appDatatable]);

  const deleteTableToJoin = (index: number) => {
    const newTablesToJoinArray = [...formik.values.joinTableSchema];
    newTablesToJoinArray.splice(index, 1);
    formik.setFieldValue('joinTableSchema', newTablesToJoinArray);
  };
  const deleteTableData = async ({ finalTable }: { finalTable: any }) => {
    const deleteRes = await _supistaAnalyticsApi(
      `${appID}/opType/deleteJoinTable/tableOperations`,
      finalTable
    )
      .then(async (response) => {
        return { skip: false, msg: response };
      })
      .catch((error) => {
        toast.error(
          mls(
            "Some issues encountered. Couldn't able to Delete Join Table. Please try again later."
          )
        );
        return { skip: true, msg: error };
      });

    return deleteRes;
  };
  const deleteTable = async (tableToBeDeleted: any) => {
    if (submitting) return;
    setSubmitting(true);
    const { sheetIDsTBD, chartIDSTBD } = getSheetChartsIDsTBD(
      [],
      [tableToBeDeleted.tableID],
      sheetsSchema
    );
    const { reportIDsTBD, dashIDsTBD } = getReportDashIDsTBD({
      chartIDs: chartIDSTBD ?? [],
      dashSchema: dashSchema,
      reportSchema: reportSchema,
    });
    const sendToBackend = {
      appID,
      name: tableToBeDeleted.name,
      tableID: tableToBeDeleted.tableID,
      joinTableIDList: undefined,
      customTableIDList: undefined,
      sheetIDList: sheetIDsTBD,
      dashIDObj: dashIDsTBD,
      reportIDObj: reportIDsTBD,
    };
    const deleteDataRes = await deleteTableData({ finalTable: sendToBackend });
    if (deleteDataRes?.skip || deleteDataRes.msg.__d3__error) {
      toast.error(
        mls(
          deleteDataRes?.msg?.response?.errorMsg ??
            "Some issues encountered. Couldn't able to Delete Join Table. Please try again later."
        )
      );
      setSubmitting(false);
      return;
    }
    updateJoinTableSchema(true, tableToBeDeleted.tableID);
    publishToProduction(appID);
    setIsDeleteOpen(false);
    setSubmitting(false);
    handleClose();
  };

  // Overwrite Formik field props onChange method
  // Selected the table + select all the columns of it
  const handlePrimaryTableChange = (e: any) => {
    formik.setFieldValue('primaryTable', e.target.value);
    const allColumnsSelectedColumnSchema = [
      ...(appDatatable?.[e.target.value]?.columnSchema || []),
    ];
    formik.setFieldValue('columnSchema', allColumnsSelectedColumnSchema);
    setIsAnyChanges(true);
  };
  const clearCustomColumn = () => {
    const currentTimestamp = Date.now();
    const finalTable = {
      ...selectedTable,
      sqlQuery: '',
      customColumnSchema: [],
      updatedAt: currentTimestamp,
    };
    setUpdateSqlCustomCommands((currentValue: number) => currentValue + 1);
    setSelectedTable(finalTable);
    setIsAnyChanges(true);
  };
  return (
    <>
      <div className='editTableList'>
        <div className='modal-body pt-0 pb-0 px-xl-20 editTableTitle'>
          <div className='text-center'>
            <h1 className='mb-3'>{mls('Join-Table Settings')}</h1>
            <div className='text-muted fw-bold fs-5'>{mls('Edit Join-Table settings here')}.</div>
          </div>
        </div>
        <div className='edittableName'>
          <label className='form-label mt-5'>{mls('Join-Table Name')}:</label>
          <input
            placeholder={mls('Enter Join table name')}
            {...formik.getFieldProps('name')}
            className={clsx('form-control form-control-solid', {
              'is-invalid': formik.touched.name && formik.errors.name,
            })}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' style={{ background: 'white' }} role='alert'>
                  {mls(formik.errors.name)}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='editTableOptions'>
          {tableNameError && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {mls(tableNameError)}
                </span>
              </div>
            </div>
          )}
          <label className='form-label '>{mls('Description')}:</label>
          <textarea
            className='form-control form-control-solid'
            style={{ resize: 'none', height: 80 }}
            maxLength={100}
            placeholder={mls('Table description')}
            {...formik.getFieldProps('description')}
          />
          {selectedTable?.customColumnSchema?.length > 0 ? (
            <ShowTable
              columnSchema={selectedTable?.customColumnSchema}
              tableIndex={0}
              mainTitle={mls('Custom Columns')}
              className={'CustomColumnDiv'}
              tableType={allTableType.customColumnType}
              onClear={clearCustomColumn}
            />
          ) : null}

          {/* 'Primary Table' field */}
          {formik?.values?.columnSchema ? (
            <TableCard
              columnSchema={appDatatable?.[formik.values.primaryTable]?.columnSchema || []}
              tableIndex={0}
              mainTitle={mls('Primary Table')}
              formik={formik}
              handleTableChange={handlePrimaryTableChange}
              appDatatable={appDatatable}
              selectColumn={selectColumn}
              deselectColumn={deselectColumn}
              formikKey={`primaryTable`}
              selectionSchema={formik.values.columnSchema ?? []}
            />
          ) : null}

          {/* {formik.values?.primaryTable && <hr />} */}
          {formik.values.primaryTable !== '' &&
            (formik.values.joinTableSchema || []).map((joinTable: any, index: number) => {
              return (
                <TableToJoin
                  key={index}
                  index={index}
                  masterFormik={formik}
                  appDatatable={appDatatable}
                  deleteTableToJoin={deleteTableToJoin}
                  isAlreadyTable={isAlreadyTable}
                  setIsAnyChanges={setIsAnyChanges}
                  allTableIDAndNamesMap={allTableIDAndNamesMap.current}
                />
              );
            })}

          {formik.values.primaryTable !== '' && (
            <div className='text-center w-100 mt-5'>
              <i
                className='fas fa-plus fa-lg'
                style={{
                  cursor: 'pointer',
                  border: '1px solid grey',
                  padding: 8,
                  borderRadius: '50%',
                }}
                onClick={() => {
                  // Adding a new blank table to join data
                  formik.setFieldValue('joinTableSchema', [
                    ...(formik.values.joinTableSchema || []),
                    initialTableToJoinValues,
                  ]);
                }}
              />
            </div>
          )}

          {/* Date time column listing and setting */}
          {formik.values.primaryTable !== '' && (datetimeCols.current?.colNames || []).length > 0 && (
            <>
              <label className='form-label mt-5'>{mls('Default Datetime Column')}</label>
              <Form.Select
                aria-label='Default Datetime Column'
                className='form-control form-control-solid'
                value={defaultDatetimeColumnName}
                defaultValue={datetimeCols.current[0]}
                onChange={(evt: any) => setDefaultDatetimeColumnName(evt.target.value)}
              >
                <option key=''>None</option>
                {(datetimeCols.current?.colNames || []).map((ele: any) => (
                  <option key={ele} value={ele}>
                    {applyTableName(removeSysVariable(ele))}
                  </option>
                ))}
              </Form.Select>
            </>
          )}
        </div>

        <div className='w-100 d-flex justify-content-end bg-white pb-5 pe-5 pt-5 editTableButtons'>
          {isAlreadyTable && (
            <button
              name='Delete Join-Table'
              className={'btn btn-sm btn-hover-danger'}
              // style={{ width: 120 }}
              onClick={() => setIsDeleteOpen(true)}
            >
              {mls('Delete Join-Table')}
            </button>
          )}

          <button
            name='Save Join-Table'
            className={'btn btn-sm ms-5 btn-primary'}
            // style={{ width: 120 }}
            onClick={() => handleSave({ values: formik.values })}
          >
            <span className='loader-container'>
              {mls('Save Join-Table')}
              {submitting && <span className='loader' />}
            </span>
          </button>
        </div>
      </div>
      {isDeleteOpen && (
        <ConfirmDeleteModal
          isDeleteOpen={isDeleteOpen}
          setIsDeleteOpen={setIsDeleteOpen}
          joinTableData={selectedTable}
          handleDeleteTable={deleteTable}
          analyticsSchema={analyticsSchema}
        />
      )}
    </>
  );
};

export default EditTableList;
interface showTableProps {
  columnSchema: any[];
  tableIndex?: number;
  mainTitle?: string;
  subTitle?: string;
  className?: string;
  tableType?: string;
  onClear?: Function;
}
const allTableType = {
  normalType: 'normalType',
  customColumnType: 'customColumnType',
};
const ShowTable = ({
  columnSchema,
  tableIndex = 0,
  mainTitle = '',
  subTitle = '',
  className = '',
  tableType = allTableType.normalType,
  onClear = () => {},
}: showTableProps) => {
  return (
    <div className={`p-5 mt-5 border rounded ${className}`} style={customColumnBoxStyle}>
      {tableType === allTableType.customColumnType ? (
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ width: 'calc( 100% - 4rem)' }}>
            <label className='form-label  fw-bolder fs-3 '>{mls(mainTitle)}</label>
          </div>
          {/* <label className='btn btn-sm btn-light-primary btn-primary  btn-hover-danger'> */}
          <label className='btn btn-sm btn-hover-danger' onClick={() => onClear()}>
            Clear
          </label>
        </div>
      ) : mainTitle ? (
        <label className='form-label  fw-bolder fs-3 '>{mls(mainTitle)}</label>
      ) : null}
      {subTitle ? (
        <input
          placeholder={mls('Enter Join table name')}
          value={subTitle}
          className={clsx('form-control form-control-solid')}
          disabled
        />
      ) : null}
      <div className='w-90 mx-auto'>
        <div className='row mt-5'>
          <label className='col-5 fw-bold'>{mls('Column Name')}</label>
          <label className='col-7 fw-bold'>{mls('Column Type')}</label>
        </div>
        <hr />
        {(columnSchema || []).map((tableCol: any, ix: number) => {
          return (
            <div className='row my-5' key={ix}>
              <div className='col-5 d-flex align-items-center justify-content-left'>
                <CopyColumnToClipBoard columnName={tableCol.columnName} tableIndex={tableIndex}>
                  {tableCol?.name ?? tableCol?.columnName}
                </CopyColumnToClipBoard>
              </div>
              <div className='col-7 d-flex align-items-center justify-content-left'>
                <input
                  placeholder={mls('Enter Join table name')}
                  value={tableCol.dataType}
                  className={clsx('form-control form-control-solid')}
                  disabled
                />
              </div>
            </div>
          );
        })}
      </div>

      {/* Display warning if no column is selected from this table */}
      {columnSchema?.length === 0 && (
        <div className='alert alert-warning mt-2 text-center' role='alert'>
          {mls('No column is selected from this table!')}
        </div>
      )}
    </div>
  );
};

const initialTableToJoinValues = {
  joinTableName: '',
  primaryKey: '',
  secondaryKey: '',
  columnSchema: [],
  primaryTable: '',
};

const dateTimeTypes = [
  '__d3__CreatedAtDate',
  '__d3__CreatedAtTime',
  '__d3__UpdatedAtDate',
  '__d3__UpdatedAtTime',
  'date',
  'datePicker',
  'dateRangePicker',
  'dateTimePicker',
  'timePicker',
];

const customColumnBoxStyle = {
  maxHeight: '60vh',
  overflow: 'auto',
};

const removeSysVariable = (str: string) => {
  const spl = str.split('.');
  const last = spl.pop();
  if (last === '__d3__updatedAt') return spl.join('.') + '.Last Update Date';
  else if (last === '__d3__createdAt') return spl.join('.') + '.Create Date';
  else return spl.join('.') + '.' + last;
};

const getDateTimeCols = (
  tableSchema: any,
  dbType: string | null,
  tableName: string | null,
  isJoined: boolean
) => {
  let columns = tableSchema?.columnSchema || [];
  if (isJoined) {
    columns = columns.map((col: any) => ({ ...col, tableName: tableSchema.primaryTable }));
    (tableSchema.joinTableSchema || []).forEach((sch: any) => {
      const joinCols = (sch.columnSchema || []).map((schCol: any) => ({
        ...schCol,
        tableName: sch.joinTableName,
      }));
      columns = [...columns, ...joinCols];
    });
  } else {
    columns = columns.map((col: any) => ({ ...col, tableName: tableName }));
  }

  if (columns.length === 0) return [];
  const DTCols = columns.filter((col: any) => dateTimeTypes.includes(col.dataType));
  if (dbType === '__d3__supista' && isJoined !== true) {
    const createData = {
      name: 'Create Date',
      dataType: 'DATETIME',
      tableName: tableName,
      columnName: '__d3__createdAt',
    };
    const updateData = {
      name: 'Last Update Date',
      dataType: 'DATETIME',
      columnName: '__d3__updatedAt',
      tableName: tableName,
    };
    DTCols.push(createData, updateData);
  }

  const colNames = DTCols.map((obj: any) => obj.tableName + '.' + obj.columnName);

  return { DTCols, colNames };
};
