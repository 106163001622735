import { RxText } from 'react-icons/rx';
import { IoRemoveOutline } from 'react-icons/io5';
import { ImPageBreak } from 'react-icons/im';
import { defaultChartWidthTypesObj } from 'lib/reusable-components/reusableData/defaultChartSchema';
import { v4 as uuidv4 } from 'uuid';
import {
  defalutTimeInterval,
  getDefaultValueByKey,
} from '../components/NewReportComponents/SaveReportContainer/TimeIntervalField/timeIntervalData';
import { defaultDataByTableType } from '../components/NewReportComponents/PageWrapper/tableCustomColumn/data/sqlCustomCommandsData';
export const allDropableID = {
  chartId: 'chartId',
  reportId: 'reportId',
  reportToolsId: 'reportToolsId',
  dashboardSheetsId: 'dashboardSheetsId',
};
export const defaultWidthTypes = {
  FULL: {
    key: 'FULL',
    name: 'Full',
    spaces: 4,
  },
  HALF: {
    key: 'HALF',
    name: 'Half',
    spaces: 2,
  },
  TWOTHIRD: {
    key: 'TWOTHIRD',
    name: 'Two-Third',
    spaces: 3,
  },
  ONEFOURTH: {
    key: 'ONEFOURTH',
    name: 'One-Fourth',
    spaces: 1,
  },
};
export const defaultSectionData = {
  title: 'Section',
};
export const defaultReportType = {
  tools: 'tools',
  charts: 'charts',
  insights: 'insights',
};
export const chartTypeReport = [defaultReportType.charts, defaultReportType.insights];

export const defaultReportChartData = {
  // chartID:"",
  // sheetID:"",
  type: defaultReportType.charts,
  style: {
    color: '#cccccc',
    opacity: 100,
    justifyContent: 'center',
    chartWidth: defaultChartWidthTypesObj.HALF.key,
  },
  chartFilter: {},
  filterMsg: '',
  plotData: {},
};
export const defaultReportInsightData = {
  // chartID:"",
  // sheetID:"",
  type: defaultReportType.insights,
  style: {},
  chartFilter: {},
  filterMsg: '',
};
export const defaultReportTimeFrame = {
  THISWEEK: 'Weekly',
  THISMONTH: 'Monthly',
  THISQUARTER: 'Quarterly',
  THISYEAR: 'Yearly',
  // THISWEEK: 'This Week',
  // THISMONTH: 'This Month',
  // THISQUARTER: 'This Quarter',
  // THISYEAR: 'This Year',
};
export const defaultReportTypeObject = {
  DATA_REPORT: {
    key: 'DATA_REPORT',
    name: 'Data Report',
  },
  NOTIFICATION_REPORT: {
    key: 'NOTIFICATION_REPORT',
    name: 'Notification Report',
  },
  INSIGHT_REPORT: {
    key: 'INSIGHT_REPORT',
    name: 'Insight Report',
  },
};
export const reportPageStageObject = {
  selectType: { key: 'selectType', name: 'Select Type' },
  selectReport: { key: 'selectReport', name: 'Select Report' },
  selectTime: { key: 'selectTime', name: 'Select Time' },
};
export const defaultReportTools = {
  inputText: {
    reportToolType: 'inputText',
    title: 'Text',
    input: '',
    type: defaultReportType.tools,
    icon: RxText,
    style: {
      color: '#3F4254',
      font: 'Poppins',
      fontHeight: '12',
      opacity: '100',
      textAlign: 'left',
      textStyle: [],
    },
  },
  line: {
    reportToolType: 'line',
    title: 'Line',
    type: defaultReportType.tools,
    icon: IoRemoveOutline,
    style: {
      color: '#cccccc',
      opacity: '70',
      lineHeight: '12',
      lineSpace: '12',
    },
  },
  pageBreak: {
    reportToolType: 'pageBreak',
    title: 'Page Break',
    type: defaultReportType.tools,
    icon: ImPageBreak,
    style: {
      color: '#cccccc',
      opacity: '70',
      lineHeight: '12',
      lineSpace: '12',
    },
  },
};
export const defaultChartName = {
  ...defaultReportTools.inputText,
  style: {
    ...defaultReportTools.inputText.style,
    textStyle: ['bold'],
    fontHeight: '14',
  },
};
export type inputTextInterface = {
  reportToolType: 'inputText';
  title: typeof defaultReportTools.inputText.title;
  input: typeof defaultReportTools.inputText.input;
  type: 'tools';
  icon: typeof defaultReportTools.inputText.icon;
  style: typeof defaultReportTools.inputText.style;
};
export type lineInterface = {
  reportToolType: 'line';
  title: typeof defaultReportTools.line.title;
  type: 'tools';
  icon: typeof defaultReportTools.line.icon;
  style: typeof defaultReportTools.line.style;
};
export type reportItemInterface = lineInterface | inputTextInterface;
export const defaultReportTimeframe = {
  '30': {
    name: '1 Months',
    xDay: 30,
  },
  '90': {
    name: '3 Months',
    xDay: 90,
  },
  '180': {
    name: '6 Months',
    xDay: 180,
  },
  '365': {
    name: '1 Year',
    xDay: 365,
  },
  '1095': {
    name: '3 Year',
    xDay: 1095,
  },
};

export const defaultDashboardSchema = {
  reportName: '',
  reportDesc: '',
  reportTimeFrame: {
    period: 'THISMONTH',
  },
  scheduledTime: [],

  reportFilter: {},
  reportTools: Object.values(defaultReportTools),
  reports: [],
  recipients: {
    mail_list: [],
    user: [],
    teams: [],
  },
  tableDataInSheetID: {},
  allTableHeadData: {},
  pages: [
    {
      name: 'Report 1',
      id: uuidv4(),
      tableID: '',
      sqlQuery: defaultDataByTableType.customColumn.defaultQuery,
      timeFrame: 'THISMONTH',
      datetimeColumn: '',
    },
  ],
  reportType: defaultReportTypeObject.DATA_REPORT.key,
};

export const defaultWidthTypesArray = Object.values(defaultWidthTypes);
export const toggleColumnDetails = {
  firstColumn: {
    toggleKeyName: 'SELECT CHARTS',
    toggleKeyId: 0,
  },
  secondColumn: {
    toggleKeyName: 'TOOLS',
    toggleKeyId: 1,
  },
};
export const defaultDataType = {
  ALL: 'ALL',
  NUM: 'NUM',
  CAT: 'CAT',
  DATETIME: 'DATETIME',
  GEO: 'GEO',
  ID: 'ID',
};
const mainColors = {
  primaryBlue: '#00A3FF',
};
export const defaultLegendColorPalettes = [
  mainColors.primaryBlue,
  '#00C1FF',
  '#00D9E6',
  '#46f0f0',
  '#FFA800',
  '#50CD89',
  '#00A3FF',
  '#8950FC',
  '#1BC5BD',
  '#FFE456',
  '#0A572D',
  '#394157',
  '#FCB6BD',
  '#5F5CF1',
  '#D0B9FF',
];

export const defaultSolidColorPalettes = [
  '#181823',
  '#3F4254',
  '#336D81',
  '#3C84AB',
  '#473C33',
  '#282A3A',
  '#443C68',
  '#635985',

  // '#00EBB8',
  // '#9CF68A',
  // '#F9F871',
  // '#fdb827',

  '#404258',
  '#474E68',
  '#50577A',
  '#6B728E',
  mainColors.primaryBlue,
  '#00C1FF',
  '#00D9E6',
  '#46f0f0',
  // '#f58231',
  // '#911eb4',
  // '#46f0f0',
  // '#f032e6',
  // '#bcf60c',
  // '#fabebe',
  // '#008080',
  // '#e6beff',
  // '#fffac8',
  // '#800000',
  // '#aaffc3',
  // '#808000',
  // '#ffd8b1',
  // '#000075',
  // '#808080',
];
export const defaultOperation = {
  num: {
    sum: {
      name: 'Sum',
      key: 'sum',
    },
    average: {
      name: 'Average',
      key: 'mean',
    },
    minimum: {
      name: 'Minimum',
      key: 'min',
    },
    maximum: {
      name: 'Maximum',
      key: 'max',
    },
    standardDeviation: {
      name: 'Standard Deviation',
      key: 'std',
    },

    median: {
      name: 'Median',
      key: 'median',
    },
    mode: {
      name: 'Mode',
      key: 'mode',
    },
    var: {
      name: 'Variance',
      key: 'var',
    },
    uncalculated: {
      name: 'Value(Uncalculated)',
      key: 'UNCALCULATED',
    },
    percentOfGrandTotal: {
      name: 'Value(Percentage of grand total)',
      key: 'POGT',
    },
    PCT_BY_GROUP: {
      name: 'Percentage by group',
      key: 'PBG',
    },
  },
  cat: {
    count: {
      name: 'Count',
      key: 'count',
    },
    distinct: {
      name: 'Count(Distinct)',
      key: 'DISTINCT',
    },
  },
};
export const defaultFontDecoration = ['bold', 'italic', 'underlined'];
export const defaultFontFamily = ['Poppins', 'Times new roman'];
export const defaultFontAlign = {
  left: {
    key: 'left',
  },
  center: {
    key: 'center',
  },

  right: {
    key: 'right',
  },
  justify: {
    key: 'justify',
  },
};
export const defaultFontSize = [
  {
    name: 'S',
    key: '10',
  },
  {
    name: 'M',
    key: '12',
  },
  {
    name: 'L',
    key: '14',
  },
  {
    name: 'XL',
    key: '16',
  },
];
export const defaultSelectedFontSize = defaultFontSize[1].key;
export const defaultNestedArrayDraggableID = {
  first: 'firstNestedDraggableArray',
  second: 'secondNestedDraggableArray',
  third: 'thirdNestedDraggableArray',
};
