import React, { useEffect, useMemo, useState } from 'react';
import './global.scss';
import './scroll.scss';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import DataSection from './containers/DataSection';
import SecondColumn from './containers/SecondColumn';
import ChartArea from './containers/ChartArea';
import DragableWrapper from './features/DragableThings/DragableWrapper';
import ToggleColumn from './features/ToggleColumn/ToggleColumn';

import {
  allDropableID,
  toggleColumnDetails,
  defaultChartSetting,
  defaultDataType,
} from './data/defaultChartSchema';
import { filterByTableName, getTimeFrame, tableFilterFuntion } from './utils/chartHelperFunctions';
import { chartPopulate } from './utils/chartPopulateFunction';
import NonSupportedScreen from './components/NonSupportedScreen/NonSupportedScreen';
import { getConvertedTableData } from 'lib/reusable-components/DisplayTable/tableFunction';
import { deleteDateTimeToggle } from 'lib/reusable-components/reusableFunction/formatter';

import { useDispatch, useSelector } from 'react-redux';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { ChartTitleDescriptionInitiator } from 'lib/reusable-components/Components/ToolTip/ChartOptionToolTip';
import { InfoToolTipDivInitiator } from 'lib/reusable-components/Components/ToolTip/InfoToolTip';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
const ChartSettingWrapper = ({
  handleChartPopUp,
  chartID,
  appID,
  sheetID,
  projectID = '',
  tableSchema,
  chartSchema,
  isNewChart = true,
  updateProjectSchema,
  isJoinedTable = false,
  tableID = '',
  isChatBot = false,
  onSave = () => {},
  timeFrameObj = {},
  defaultDateTimeCol = '',
}) => {
  const timeFrameData = useSelector(
    (state) => state[reduxConstants.STORE_NAME][reduxConstants.config.DATE_TIME_TOGGLE]
  );
  const dispatch = useDispatch();

  // Handle ChartPopulate
  const [populateState, setPopulateState] = useState(!isNewChart);
  const [populateCount, setPopulateCount] = useState(0);

  // Handle Column Blocks
  const [minimizedDiv, setMinimizedDiv] = useState(['', '']);
  const toggleMinimizeDiv = (toggleKeyName, toggleKeyId) => {
    const newArray = new Array(...minimizedDiv);
    newArray[toggleKeyId] = toggleKeyName;
    setMinimizedDiv(newArray);
  };
  const toggleProps = { toggleColumnDetails, minimizedDiv, toggleMinimizeDiv };
  const [allTableData, setAllTableData] = useState(tableSchema);
  const [tableDetails, settableDetails] = useState([]);
  const [filterdTableSchema, setFilterdTableSchema] = useState(tableSchema);
  const [tableFilter, settableFilter] = useState({ search: '', type: defaultDataType.ALL });
  const handleSearch = (value) => {
    settableFilter((currentValue) => {
      tableFilterFuntion({
        allTableData,
        setFilterdTableSchema,
        ...currentValue,
        search: value,
      });

      return { ...currentValue, search: value };
    });
  };
  const handleTypeFilter = (e) => {
    settableFilter((currentValue) => {
      tableFilterFuntion({
        allTableData,
        setFilterdTableSchema,
        ...currentValue,
        type: e,
      });
      return { ...currentValue, type: e };
    });
  };

  // State for Schema

  const [chartType, setchartType] = useState(defaultChartSetting.chartType);
  const tablesData = useMemo(() => {
    return filterByTableName({
      data: filterdTableSchema,
      axisDataSupport: chartType?.axis?.axisDataSupport,
    });
  }, [filterdTableSchema, chartType]);
  // Normal Chart
  const [chatName, setChatName] = useState(defaultChartSetting.name);
  const [chartDescription, setChartDescription] = useState(defaultChartSetting.desc);
  const [columnDropNumber, setColumnDropNumber] = useState(
    defaultChartSetting.chartBuilderHelper.columnDropNumber
  );

  const [xAxisData, setXAxisData] = useState(defaultChartSetting.xAxis);
  const [yAxisData, setYAxisData] = useState(defaultChartSetting.yAxis);
  const [chartToolTip, setChartToolTip] = useState(defaultChartSetting.tooltip);
  const [chartXAxisStyle, setChartXAxisStyle] = useState(defaultChartSetting.xAxisStyle);
  const [chartYAxisStyle, setChartYAxisStyle] = useState(defaultChartSetting.yAxisStyle);
  const [chartFilter, setChartFilter] = useState(defaultChartSetting.filters);
  const [chartWidth, setChartWidth] = useState(defaultChartSetting.chartWidth);
  const [isInsight, setIsInsight] = useState(defaultChartSetting.isInsight);
  const [isForecast, setIsForecast] = useState(defaultChartSetting.isForecast);
  const [isCrossReport, setIsCrossReport] = useState(defaultChartSetting.isCrossReport);
  const [iskeepAllFilters, setIskeepAllFilters] = useState(defaultChartSetting.iskeepAllFilters);
  const [isZoomBrush, setIsZoomBrush] = useState(defaultChartSetting.isZoomBrush);
  const [isDateHierarchy, setIsDateHierarchy] = useState(defaultChartSetting.isDateHierarchy);
  const [plotAreaBackground, setPlotAreaBackground] = useState(
    defaultChartSetting.plotAreaBackgroundSolid
  );
  const [gridLines, setGridLines] = useState(defaultChartSetting.gridLines);

  const [timeFrame, setTimeFrame] = useState(
    getTimeFrame({ action: defaultChartSetting.timeFrame, tableSchema, defaultDateTimeCol })
  );
  // Special Case Chart

  //CARD
  const [chartHeaderImage, setChartHeaderImage] = useState(defaultChartSetting.chartHeaderImage); //CARD
  const [chartCardType, setChartCardType] = useState(defaultChartSetting.chartCardType); //CARD
  //KPIMETER
  const [kpiTargetPoints, setKpiTargetPoints] = useState(defaultChartSetting.targetPoints); //KPIMETER
  const [defaultKpiTargets, setDefaultKpiTargets] = useState(defaultChartSetting.defaultKpiTargets); //KPIMETER
  //GEO
  const [geoGroup, setGeoGroup] = useState(defaultChartSetting.geoGroup); //GEO
  //GEOMAP
  const [geoMapColor, setGeoMapColor] = useState(defaultChartSetting.geoMapColor); //GEOMAP

  //BARSTACKLINE
  const [barStackLineTargetPoints, setBarStackLineTargetPoints] = useState(
    defaultChartSetting.barStackLineTargetPoints
  ); //BARSTACKLINE
  //PLOT
  const [plotGroup, setPlotGroup] = useState(defaultChartSetting.plotGroup); //PLOT
  const [plotGroupStyle, setPlotGroupStyle] = useState(defaultChartSetting.plotGroupStyle); //PLOT
  //PIVOTTABLE
  const [pivotTableTitleStyle, setPivotTableTitleStyle] = useState(
    defaultChartSetting.pivotTableTitleStyle
  );
  //TREEMAP
  const [treeMapColor, setTreeMapColor] = useState(defaultChartSetting.treeMapColor); //HEATMAP
  //HEATMAP
  const [heatMapGroup, setHeatMapGroup] = useState(defaultChartSetting.heatMapGroup); //HEATMAP
  const [heatMapColor, setHeatMapColor] = useState(defaultChartSetting.heatMapColor); //HEATMAP

  //COLOR
  const [colorAxis, setColorAxis] = useState(defaultChartSetting.colorAxis); //COLOR
  const [colorAxisStyle, setColorAxisStyle] = useState(defaultChartSetting.colorAxisStyle); //COLOR

  //DOUBLEAXIS
  const [doubleAxis, setDoubleAxis] = useState(defaultChartSetting.doubleAxis); //DOUBLEAXIS
  const doubleAxisMemo = useMemo(() => {
    if (
      chartType?.chartKey === allChartKeys.BARGROUP ||
      chartType?.chartKey === allChartKeys.MULTILINE ||
      chartType?.chartKey === allChartKeys.LINE
    ) {
      if (yAxisData.length === 2) {
        return { disabled: false, doubleAxisData: doubleAxis, show: true };
      }
      return { disabled: true, doubleAxisData: { isDoubleAxis: false }, show: true };
    }
    return { disabled: true, doubleAxisData: { isDoubleAxis: false }, show: false };
  }, [yAxisData, chartType, doubleAxis]);

  //RADIALLINE
  const [radialLineBackGroundColor, setRadialLineBackGroundColor] = useState(
    defaultChartSetting.radialLineBackGroundColor
  ); //RaidalLine

  const handleResetToolTip = () => {
    setChartToolTip((currentData) => {
      const newStyle = currentData.style.map((elem) => {
        let isOpration = undefined;
        const checkForxAxis = xAxisData.find(
          (xAsixElem) => xAsixElem.uniqueColumnName === elem?.uniqueColumnName
        );
        isOpration = checkForxAxis?.operations?.type;
        if (!checkForxAxis) {
          const checkForYAxis = yAxisData.find(
            (xAsixElem) => xAsixElem.uniqueColumnName === elem?.uniqueColumnName
          );
          isOpration = checkForYAxis?.operations?.type;
        }
        return {
          ...elem,
          label: isOpration ? `${elem.originalLabel} ( ${isOpration} )` : elem.originalLabel,
          show: true,
        };
      });
      return { ...currentData, style: newStyle };
    });
  };

  const chartSchemaMemo = useMemo(() => {
    const updatedChartSchema = {
      chartType: chartType.chartKey,
      name: chatName,
      desc: chartDescription,
      xAxis: xAxisData,
      yAxis: yAxisData,
      xAxisStyle: chartXAxisStyle,
      yAxisStyle: chartYAxisStyle,
      chartWidth: chartWidth.key,
      chartID: chartID,
      appID: appID,
      sheetID: sheetID,
      tableID: tableID,
      tooltip: chartToolTip,
      filters: chartFilter,
      isInsight: isInsight,
      isForecast: isForecast,
      isZoomBrush: isZoomBrush,
      isDateHierarchy: isDateHierarchy,
      plotAreaBackground: plotAreaBackground,
      gridLines: gridLines,
      isCrossReport: isCrossReport,
      iskeepAllFilters: iskeepAllFilters,
      timeFrame: timeFrame,
      chartBuilderHelper: {
        columnDropNumber: columnDropNumber,
      },
      timeframeColumn: defaultDateTimeCol,
      // Special Case
      //CARD
      headerImage: chartHeaderImage, //CARD
      cardType: chartCardType, //CARD
      //KPIMETER
      targetPoints: kpiTargetPoints, //KPIMETER
      //GEO
      geoGroup: geoGroup, //GEO
      //GEOMAP
      geoMapColor: geoMapColor, //GEOMAP
      //BARSTACKLINE
      barStackLineTargetPoints: barStackLineTargetPoints, //BARSTACKLINE
      //PLOT
      plotGroup: plotGroup,
      plotGroupStyle: plotGroupStyle,
      //PIVOTTABLE
      pivotTableTitleStyle: pivotTableTitleStyle,
      //HEATMAP
      magnitude: heatMapGroup,
      heatMapColor: heatMapColor,
      //TREEMAP
      treeMapColor: treeMapColor,
      //COLOR
      colorAxis: colorAxis,
      colorAxisStyle: colorAxisStyle,
      //DOUBLEAXIS
      doubleAxis: doubleAxis,
      //RADIALLINE
      radialLineBackGroundColor: radialLineBackGroundColor,
    };
    return updatedChartSchema;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartID,
    appID,
    sheetID,
    projectID,

    chartType,
    chatName,
    chartDescription,
    xAxisData,
    yAxisData,
    chartXAxisStyle,
    chartYAxisStyle,
    isForecast,
    isInsight,
    isZoomBrush,
    isDateHierarchy,
    plotAreaBackground,
    gridLines,
    chartWidth,
    chartToolTip,
    chartFilter,
    isCrossReport,
    iskeepAllFilters,
    columnDropNumber,
    // Special Case
    //CARD
    chartHeaderImage,
    chartCardType,
    //GEO
    geoGroup,
    //GEO
    geoMapColor,
    //KPIMETER
    kpiTargetPoints,
    //BARSTACKLINE
    barStackLineTargetPoints,
    chartType,
    //PLOT
    plotGroup,
    plotGroupStyle,
    timeFrame,
    //PIVOTTABLE
    pivotTableTitleStyle,
    //HEATMAP
    heatMapGroup,
    heatMapColor,
    //TREEMAP
    treeMapColor,
    //COLOR
    colorAxis,
    colorAxisStyle,
    //DOUBLEAXIS
    doubleAxis,
  ]);
  const stateProps = {
    // Handle Table
    tablesData,
    allTableData,
    setAllTableData,
    setFilterdTableSchema,
    mainTableData: filterdTableSchema,
    tableFilter,
    handleSearch,
    handleTypeFilter,
    appID,
    sheetID,
    isJoinedTable,
    // Extra
    toast,
    populateCount,
    setPopulateCount,
    tableDetails,
    tableID,

    // Normal Chart
    chartType,
    setchartType,
    chartDescription,
    setChartDescription,
    chatName,
    setChatName,
    xAxisData,
    setXAxisData,
    yAxisData,
    setYAxisData,
    chartXAxisStyle,
    setChartXAxisStyle,
    chartYAxisStyle,
    setChartYAxisStyle,
    chartToolTip,
    setChartToolTip,
    chartFilter,
    setChartFilter,
    isZoomBrush,
    setIsZoomBrush,
    isDateHierarchy,
    setIsDateHierarchy,
    plotAreaBackground,
    setPlotAreaBackground,
    gridLines,
    setGridLines,
    isCrossReport,
    setIsCrossReport,
    iskeepAllFilters,
    setIskeepAllFilters,
    chartWidth,
    setChartWidth,
    columnDropNumber,
    setColumnDropNumber,
    timeFrame,
    setTimeFrame,
    defaultDateTimeCol,
    // Special Case Chart

    //CARD
    chartHeaderImage,
    setChartHeaderImage,
    chartCardType,
    setChartCardType,
    isInsight,
    setIsInsight,
    isForecast,
    setIsForecast,

    //KPIMETER
    kpiTargetPoints,
    setKpiTargetPoints,
    defaultKpiTargets,
    setDefaultKpiTargets,

    //GEO
    geoGroup,
    setGeoGroup,

    //GEOMAP
    geoMapColor,
    setGeoMapColor,

    //BARSTACKLINE
    barStackLineTargetPoints,
    setBarStackLineTargetPoints,

    //PLOT
    plotGroup,
    setPlotGroup,
    plotGroupStyle,
    setPlotGroupStyle,

    //PIVOTTABLE
    pivotTableTitleStyle,
    setPivotTableTitleStyle,

    //HEATMAP
    heatMapGroup,
    setHeatMapGroup,
    heatMapColor,
    setHeatMapColor,

    //TREEMAP
    treeMapColor,
    setTreeMapColor,

    //COLOR
    colorAxis,
    setColorAxis,
    colorAxisStyle,
    setColorAxisStyle,

    //DOUBLEAXIS
    doubleAxis,
    setDoubleAxis,
    doubleAxisMemo,

    //RADIALLINE
    radialLineBackGroundColor,
    setRadialLineBackGroundColor,

    //ExtraFunction
    handleResetToolTip,

    //chartSchemaMemo
    chartSchemaMemo,
    chartID,
    timeFrameData,
  };
  // Chart Data Population
  useEffect(() => {
    chartPopulate({ stateProps, chartSchema, allTableData, populateState, setPopulateState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populateState]);

  useEffect(async () => {
    // const = getTableData()
    const tableDataRes = await getConvertedTableData({
      appID: appID,
      selectedTable: tableID,
      sheetID: sheetID,
    });
    if (tableDataRes.skip) {
      return;
    }
    settableDetails(tableDataRes.groupedTableData);
  }, []);

  // remove scrollBar
  useEffect(() => {
    document.body.classList.add('ChartSettingsLibScrollLock');
    deleteDateTimeToggle({
      toggle: timeFrameData?.toggle,
      chartID,
      dispatch,
    });
    return () => {
      deleteDateTimeToggle({
        toggle: timeFrameData?.toggle,
        chartID,
        dispatch,
      });

      document.body.classList.remove('ChartSettingsLibScrollLock');
    };
  }, []);
  return (
    <>
      <ToastContainer />
      <ChartTitleDescriptionInitiator />
      <InfoToolTipDivInitiator />
      <DragableWrapper
        stateProps={stateProps}
        mainTableData={tablesData}
        allTableData={allTableData}
      >
        <div className='chartPopUp'>
          <NonSupportedScreen closeButton={() => handleChartPopUp(false, 'cancel')} />
          <Header
            handleChartPopUp={handleChartPopUp}
            chartSchemaMemo={chartSchemaMemo}
            stateProps={stateProps}
            updateProjectSchema={updateProjectSchema}
            isNew={isNewChart}
            isChatBot={isChatBot}
            onSave={onSave}
          />
          <div className='mainContainer'>
            <ToggleColumn toggleProps={toggleProps} />
            <DataSection
              allDropableID={allDropableID}
              stateProps={stateProps}
              mainTableData={tablesData}
              toggleProps={toggleProps}
            />
            <SecondColumn
              stateProps={stateProps}
              allDropableID={allDropableID}
              toggleProps={toggleProps}
            />
            <ChartArea
              chartSchemaMemo={chartSchemaMemo}
              stateProps={stateProps}
              setPopulateState={setPopulateState}
              setMinimizedDiv={setMinimizedDiv}
              toggleProps={toggleProps}
            />
          </div>
        </div>
      </DragableWrapper>
    </>
  );
};

export default ChartSettingWrapper;
