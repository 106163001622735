import { supistaApiPost } from "./connections";

const API = 'https://test.supista.com/api';

const endpoints = {
  demoUserRegister: 'app/supistacrm1/CRUD/598cec72-6c4a-4dd3-9b78-221673fd654f',
}

export const supistaTrackingDataSubmitApi = async ({
  type, data
}) => {
  supistaApiPost(endpoints[type], { data: data});
}