import React from 'react';

import { allDropableID, defaultChartSetting, defaultKpiTimeFrame } from '../../data/defaultSchema';
// import FilterIndex from '../../../components/BuildComponents/FilterDiv/FilterIndex';
// import KpiTargetPoints from '../../../components/SpacialCasesComponents/KpiMeaters/KpiTargetPoints';

import { ObjectDropDown } from 'lib/chart-settings-lib/features/NewDropDown/DropDown';
import NumberFormatterComp from 'lib/chart-settings-lib/features/Formatter/NumberFormatterComp';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';
// import ChartInsight from '../../../components/BuildComponents/ChartInsight';
import { mls } from 'lib/multilanguagesupport';
import DragableAxis from './DragableAxis';
import KpiTargetPoints from './KpiTargetPoints';
import FilterIndex from 'lib/filters-lib/FilterIndex';
import ChartInsight from './ChartInsight';
import { getDateTimeCols } from '../../utils/helperFunction';
import ArrayDropDown from 'lib/reusable-components/Components/DropDowns/ArrayDropDown';
import Select from 'react-select';
const KpiMeterBuildCase = ({ stateProps }) => {
  const {
    yAxisData,
    setYAxisData,
    setChartYAxisStyle,
    kpiTargetPoints,
    setKpiTargetPoints,
    isInsight,
    setIsInsight,
    chartType,
    DTCols,
  } = stateProps;
  const { yAxisId } = allDropableID;
  const handleKpiTimeFrame = (e) => {
    setKpiTargetPoints({ ...kpiTargetPoints, kpiTimeframe: e });
  };
  const handleTimeframeColumn = (e) => {
    setKpiTargetPoints({ ...kpiTargetPoints, timeframeColumn: e });
  };
  const handleFormatter = (e) => {
    const formatedTargets = (kpiTargetPoints?.targets ?? []).map((elem) => {
      return {
        ...elem,
        label: numberFormatter({ type: e, value: elem?.targetPoint, short: true }),
      };
    });
    setKpiTargetPoints({ ...kpiTargetPoints, formatter: e, targets: formatedTargets });
  };
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  return (
    <>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('KPI Tracking Column')} *</div>
        <DragableAxis
          dragableDivId={yAxisId}
          axisData={yAxisData}
          setAxisData={setYAxisData}
          stateProps={stateProps}
          setStyle={setChartYAxisStyle}
          defaultLable={defaultChartSetting.yAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>

      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('KPI Section Colors')} *</div>
        <KpiTargetPoints stateProps={stateProps} />
      </div>
      {DTCols?.[0] ? (
        <div className='outerDiv open'>
          <div className='MainTitle'>{mls('KPI Time Frame')}</div>
          <div className='grayContainers noOverflowGrayContainer'>
            <div className={`containerItems open`}>
              <div className='titleDiv'>{mls('DateTime Column')}</div>
              <ArrayDropDown
                array={DTCols}
                dropDownValue={kpiTargetPoints.timeframeColumn ?? ''}
                setdropDownValue={handleTimeframeColumn}
                mainKey={'uniqueColumnName'}
                ObjectInArray
              />
              <div className='titleDiv'>{mls('Time Frame')}</div>
              <ObjectDropDown
                object={defaultKpiTimeFrame}
                dropDownValue={kpiTargetPoints.kpiTimeframe}
                setdropDownValue={handleKpiTimeFrame}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('KPI Formatter')}</div>

        <div className='grayContainers noOverflowGrayContainer'>
          <div className='containerItems'>
            <NumberFormatterComp
              formatter={kpiTargetPoints?.formatter}
              setFormatter={handleFormatter}
              divBorder={false}
            />
            {/* <Select options={options} /> */}
          </div>
        </div>
      </div>
      {/* 
      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls('Default Filters')}</div>
        <FilterIndex stateProps={stateProps} />
      </div> */}
      <ChartInsight
        isInsight={isInsight}
        setIsInsight={setIsInsight}
        chartKey={chartType.chartKey}
      />
    </>
  );
};

export default KpiMeterBuildCase;
