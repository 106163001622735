export const getfileExtensionData = ({ fileName = '', extensionObject = {} }) => {
  let sFileExtension = ((fileName ?? '').split('.')[1] ?? '').toLowerCase();
  return Object.values(extensionObject).find((extendtionElem: any) => {
    return extendtionElem.extension.toLowerCase() === sFileExtension;
  });
};

export const dateTimeTypes = [
  '__d3__CreatedAtDate',
  '__d3__CreatedAtTime',
  '__d3__UpdatedAtDate',
  '__d3__UpdatedAtTime',
  'date',
  'datePicker',
  'dateRangePicker',
  'dateTimePicker',
  'timePicker',
];
interface getDTColsProps {
  columns: { [key: string]: any }[];
  columnDataStartFrom?: number;
  tableID: string;
  keyArray?: boolean;
}
export const getDTCols = ({
  columns,
  columnDataStartFrom = 0,
  tableID,
  keyArray = false,
}: getDTColsProps) => {
  let DTCols = {};
  columns.forEach((col: { [key: string]: any }, colIndex: number) => {
    if (colIndex >= columnDataStartFrom) {
      if (dateTimeTypes.includes(col.dataType)) {
        const uniqueColumnNames = `${col.tableName ?? tableID}.${col.columnName}`;
        if (!col.disabledColumn) {
          DTCols = { ...DTCols, [uniqueColumnNames]: { ...col, uniqueColumnNames } };
        }
      }
    }
  });
  if (keyArray) {
    let DTolInArray: string[] = [];
    Object.keys(DTCols).forEach((DTColsKey: string) => {
      DTolInArray = [...DTolInArray, DTColsKey];
    });
    return DTolInArray;
  }
  return DTCols;
};
interface getDateTimeColumnsObjectProps {
  columns: { [key: string]: any }[];
  columnDataStartFrom?: number;
  handleDateTime: Function;
  dateTimeColumn: string;
  tableID: string;
}
export const getDateTimeColumnsObject = ({
  columns,
  columnDataStartFrom = 0,
  handleDateTime,
  dateTimeColumn,
  tableID,
}: getDateTimeColumnsObjectProps) => {
  let DTCols = getDTCols({ columns, columnDataStartFrom, tableID });
  const defaultDateTime = Object.keys(DTCols)[0];
  if (defaultDateTime) {
    if (!dateTimeColumn) {
      handleDateTime(defaultDateTime);
    }
  } else {
    if (dateTimeColumn) {
      handleDateTime('');
    }
  }
  return DTCols;
};
const IDTypes = ['id_text', 'id_num'];

export const getIDColumnsObject = ({
  columns,
  columnDataStartFrom = 0,
  handleDateTime,
  idColumn,
  tableID,
}: {
  columns: { [key: string]: any }[];
  columnDataStartFrom?: number;
  handleDateTime: Function;
  idColumn: string;
  tableID: string;
}) => {
  let IDfiled = {};
  let restfileds = {};
  columns.forEach((col: { [key: string]: any }, colIndex: number) => {
    if (colIndex >= columnDataStartFrom) {
      if (IDTypes.includes(col.dataType)) {
        const uniqueColumnNames = `${col.tableName ?? tableID}.${col.columnName}`;
        IDfiled = { ...IDfiled, [uniqueColumnNames]: { ...col, uniqueColumnNames } };
      } else {
        const uniqueColumnNames = `${col.tableName}.${col.columnName}`;
        IDfiled = { ...IDfiled, [uniqueColumnNames]: { ...col, uniqueColumnNames } };
      }
    }
  });
  const finalFileds = { ...IDfiled, ...restfileds };
  const defaultDateTime = Object.keys(finalFileds)[0];
  if (defaultDateTime) {
    if (!idColumn) {
      handleDateTime(defaultDateTime);
    }
  } else {
    if (idColumn) {
      handleDateTime('');
    }
  }
  return finalFileds;
};
