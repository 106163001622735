import React, { useEffect, useState } from 'react';
import arrowDropDownIcon from '../assets/arrowDropDownIcon.svg';
import arrowIcon from '../assets/arrowIcon.svg';
import {
  toggleColumnDetails,
  allDropableID,
  defaultDataTypeStyles,
  defaultChartSetting,
} from '../data/defaultSchema';
import { columnToggleFunc } from '../utils/coloumnToggleHelper';
import { mls } from 'lib/multilanguagesupport';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import {
  getConvertedTableData,
  getSheetID,
} from 'lib/reusable-components/DisplayTable/tableFunction';
import { BiFilterAlt } from 'react-icons/bi';
import DragableTable from '../features/DragableThings/DragableTable';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import ArrayDropDown from 'lib/reusable-components/Components/DropDowns/ArrayDropDown';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
interface mainPorps {
  toggleProps: {
    minimizedDiv: string[];
    toggleMinimizeDiv: Function;
  };
  mainPorps: any;
}
const FirstColumn = (props: mainPorps) => {
  const { toggleProps, mainPorps } = props;
  const { minimizedDiv, toggleMinimizeDiv } = toggleProps;
  const { toggleKeyName, toggleKeyId } = toggleColumnDetails.firstColumn;

  const [isToggle, setIsToggle] = useState(true);
  const [isMainDivOn, setIsMainDivOn] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);

  useEffect(() => {
    columnToggleFunc({
      minimizedDiv: minimizedDiv[toggleKeyId],
      toggleKeyName: toggleKeyName,
      setIsMainDivOn: setIsMainDivOn,
      setIsToggle: setIsToggle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimizedDiv]);

  if (!isToggle) {
    return <></>;
  }

  const { stateProps } = mainPorps;
  const {
    appID,
    appSheets,
    mainTableData,
    tableFilter,
    handleSearch,
    setTableSchema,
    setIsJoinedTable,
    setDefaultTable,
    setFilterdTableSchema,
    departmentsData,
    selectedDepartment,
    setSelectedDepartment,
    tablesObject,
    selectedTable,
    setSelectedTable,
    handleTypeFilter,
    setYAxisData,
    setKpiTargetPoints,
    setDTCols,
    setDatetimeCol,
    setSheetID,
    isTableSelected,
    setIsTableSelected,
  } = stateProps;
  const handleDone = async () => {
    if (!selectedDepartment) {
      toast.warn('Select Any Department');
      return;
    }
    if (!selectedTable) {
      toast.warn('Select Any Table');
      return;
    }
    setIsTableLoading(true);
    const sheetData: {
      sheetID: string;
      skip: boolean;
      newCreatedSheetID?: boolean;
    } = await getSheetID({
      appSheets,
      selectedTable,
      appID,
    });
    if (sheetData?.skip) {
      setIsTableLoading(false);
      return { skip: true, skipFrom: 'sheetID' };
    }

    const tableData = await getConvertedTableData({
      appID: appID,
      selectedTable: selectedTable,
      sheetID: sheetData?.sheetID,
      newSheetID: sheetData?.newCreatedSheetID ?? false,
    });
    if (tableData?.skip) {
      toast.error('Some error occurred while fetching the table data.');
      setIsTableLoading(false);
      return { skip: true, skipFrom: 'tableData' };
    }

    const newTableSchema = tableData?.data?.tableSchema?.data?.map((tableElem: any) => {
      return { ...tableElem, sheetID: sheetData?.sheetID };
    });
    const tableSchema = {
      ...tableData?.data?.tableSchema,
      data: newTableSchema,
      defaultTable: {
        ...tableData?.data?.tableSchema?.defaultTable,
        sheetID: sheetData?.sheetID,
      },
    };

    setSheetID(sheetData?.sheetID ?? '');
    setTableSchema(tableSchema?.data ?? []);
    setFilterdTableSchema(tableSchema?.data ?? []);
    setIsJoinedTable(tableSchema?.isJoinedTable ?? false);
    setDefaultTable(tableSchema?.defaultTable ?? {});
    setIsTableSelected(true);
    setYAxisData(defaultChartSetting.yAxis);
    setIsTableLoading(false);

    let DTCols: any[] = [];
    (tableSchema?.data ?? []).forEach((col: { [key: string]: any }) => {
      if (
        (tableSchema?.defaultTable ?? {})?.datetimeCols?.includes(`${col.tableName}.${col.name}`)
      ) {
        DTCols = [...DTCols, { ...col, uniqueColumnName: `${col.tableName}.${col.name}` }];
      }
    });
    setKpiTargetPoints({
      ...defaultChartSetting.targetPoints,
      timeframeColumn: (tableSchema?.defaultTable ?? {})?.defaultDatetimeCol ?? '',
    });
    setDTCols(DTCols);
  };
  const { tableId } = allDropableID;

  return (
    <div className={isMainDivOn ? 'firstColumn ' : 'firstColumn close'}>
      <div className='staticDiv'>
        <div className='mainTitleDiv'>
          <div className='mainTitle'>{mls(toggleKeyName)}</div>
          <img
            src={arrowIcon}
            alt=''
            className='arrowIcon'
            onClick={() => toggleMinimizeDiv(toggleKeyName, toggleKeyId)}
          />
        </div>
      </div>

      <div className='scrollableDiv'>
        <div className='outerDiv open'>
          <div className='grayContainers noOverflowGrayContainer'>
            <div className='containerItems open '>
              <div className='titleDiv'>{mls('Department')}</div>
              <ArrayDropDown
                array={departmentsData}
                dropDownValue={selectedDepartment}
                setdropDownValue={setSelectedDepartment}
                mainKey={'departmentID'}
                inputPlaceHolder={mls('Select Department')}
                nameKey={'name'}
                ObjectInArray
              />
              <div className='titleDiv'>{mls('Table Name')}</div>
              <div className='tableDropDownDiv'>
                <ObjectDropDown
                  object={tablesObject}
                  dropDownValue={selectedTable}
                  setdropDownValue={setSelectedTable}
                  className='tableDropDown introjs-supistaAISelectTable'
                  inputPlaceHolder={mls('Search Table')}
                  alternateName={'tableID'}
                  mainKey={'tableID'}
                  ObjectInObject
                  search
                  groupDropDown
                />
              </div>
            </div>
          </div>
        </div>
        <button className='primaryButton secondaryButtonColor' onClick={() => handleDone()}>
          {mls(isTableSelected ? 'Update' : 'Done')}

          {isTableLoading ? (
            <>
              &nbsp;
              <CircularProgress size={9} color='inherit' />
            </>
          ) : null}
        </button>
        <div className='serachBoxOuter'>
          <div className='searchBox'>
            <SearchInputDiv value={tableFilter.search} onChange={handleSearch} />
          </div>
          <ObjectDropDown
            object={defaultDataTypeStyles}
            dropDownValue={tableFilter.type}
            setdropDownValue={handleTypeFilter}
            ObjectInObject
            isChildernInsert
            className='serachBoxFilterIconDropDown'
          >
            <div className='filterIcon'>
              <BiFilterAlt />
            </div>
          </ObjectDropDown>
        </div>
        <div className='tablesContainerDiv'>
          {React.Children.toArray(
            mainTableData.map((elem: any, i: number) => {
              return (
                <TableDiv
                  elem={elem}
                  tableId={tableId}
                  i={i}
                  stateProps={stateProps}
                  allDropableID={allDropableID}
                  mainTableData={elem}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

function TableDiv({ elem, tableId, i, stateProps, allDropableID, mainTableData }: any) {
  const [isDivOpen, setisDivOpen] = useState(true);
  return (
    <div className={isDivOpen ? 'tablesItems open' : 'tablesItems'}>
      <div className='tablesItemTitle' onClick={() => setisDivOpen(!isDivOpen)}>
        <div className='text'>{elem.nameOfTable ?? elem.name}</div>
        <img src={arrowDropDownIcon} alt='' />
      </div>
      <div className='tablesItemList'>
        <DragableTable
          dragableDivId={tableId}
          stateProps={stateProps}
          tableNumber={i}
          // mainTableData={mainTableData}
        />
      </div>
    </div>
  );
}

export default FirstColumn;
