import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './components/NewReportComponents/Header';

import {
  defaultDashboardSchema,
  defaultReportTypeObject,
  reportPageStageObject,
} from './data/defaultSchema';
import { useEffect, useMemo, useRef, useState } from 'react';
import { populateFunction, tableHeadPopulateFunction } from './utils/populateFunction';
import './reportGlobal.scss';
import './scroll.scss';
import NonSupportedScreen from './components/NonSupportedScreen/NonSupportedScreen';
import { getConvertedTableData } from 'lib/reusable-components/DisplayTable/tableFunction';
import { chartFilterFromAnalyticsFunction } from 'lib/reusable-components/reusableFunction/reusableFunction';
// import ReportToggleContainer from './components/ReportToggleContainer/ReportToggleContainer';
import SelectReportType from './components/NewReportComponents/SelectReportType/SelectReportType';
import ReportTypeContainer from './components/NewReportComponents/ReportTypeContainer/ReportTypeContainer';
import SaveReportContainer from './components/NewReportComponents/SaveReportContainer/SaveReportContainer';

interface mainProps {
  appID: string;
  reportID: string;
  isNewChart: boolean;
  reportSchema: any;
  analyticsSchema: any;
  handleReportSettingsPopUp: Function;
  handleSave: Function;
  handleDelete: Function;
  sendToOptions: {
    email: string[];
    user: string[];
    teams: string[];
  };
}
const ReportSettingsWrapper = ({
  appID,
  reportID,
  isNewChart,
  reportSchema = {},
  analyticsSchema,
  handleReportSettingsPopUp,
  handleSave,
  handleDelete,
  sendToOptions,
}: mainProps) => {
  const [populateState, setPopulateState] = useState(!isNewChart);
  const [isPopulateComplete, setIsPopulateComplete] = useState(false);
  const [populateCount, setPopulateCount] = useState(0);
  const [reportAreaLoading, setReportAreaLoading] = useState(!isNewChart);

  const [projectSheets, setProjectSheets] = useState(analyticsSchema.appSheets ?? {});
  const [chartsData, setChartsData] = useState(analyticsSchema.appCharts ?? {});

  const [reportTools, setReportTools] = useState(defaultDashboardSchema.reportTools);
  const [reports, setReports] = useState(defaultDashboardSchema.reports);

  const [reportName, setReportName] = useState(defaultDashboardSchema.reportName);
  const [reportDescription, setReportDescription] = useState(defaultDashboardSchema.reportDesc);
  const [sendTo, setSendTo] = useState(defaultDashboardSchema.recipients);
  const [reportFilter, setReportFilter] = useState(defaultDashboardSchema.reportFilter);
  const [reportTimeFrame, setReportTimeFrame] = useState(defaultDashboardSchema.reportTimeFrame);
  const [scheduledTime, setScheduledTime] = useState(defaultDashboardSchema.scheduledTime);
  const [tableDataInSheetID, setTableDataInSheetID] = useState<{ [key: string]: any }>(
    defaultDashboardSchema.tableDataInSheetID
  );
  const [pagesList, setPagesList] = useState(defaultDashboardSchema.pages);

  const [pageCount, setPageCount] = useState(1);
  const [selectedPageNumber, setSelectedPageNumber] = useState(0);

  const [isReportConfiguration, setIsReportConfiguration] = useState(true);
  const handleReportConfigToggle = (action?: boolean) => {
    setIsReportConfiguration((currentToggle) => !currentToggle);
  };
  const [chartSearch, setChartSearch] = useState({ search: '' });

  // New
  const [selectedReportType, setSelectedReportType] = useState(
    defaultReportTypeObject.DATA_REPORT.key
  );

  const [reportPageStage, setReportPageStage] = useState(reportPageStageObject.selectType.key);

  const [allTableHeadData, setAllTableHeadData] = useState<{ [key: string]: any }>({});
  const [tableHeadLoading, setTableHeadLoading] = useState(false);
  const handleSearch = (value: string) => {
    setChartSearch((currentValue) => {
      chartFilterFromAnalyticsFunction({
        allAppSheet: analyticsSchema.appSheets,
        chartsData: chartsData,
        setProjectSheets,
        ...currentValue,
        search: value,
      });
      return { ...currentValue, search: value };
    });
  };
  const tempTableDataInSheetID = useRef<{ [key: string]: any }>({});
  // let tempTableDataInSheetID: { [key: string]: any } = {};

  const handleTableData = async ({
    tableDataInSheetID,
    setTableDataInSheetID,
    selectedTable,
    appID,
    sheetID,
  }: any) => {
    if (tableDataInSheetID[sheetID as keyof typeof tableDataInSheetID]) {
      return {
        tableDataInSheetID: {
          [sheetID]: tableDataInSheetID[sheetID as keyof typeof tableDataInSheetID],
        },
        skip: false,
        alreadyPresent: true,
      };
    }
    if (tempTableDataInSheetID.current[sheetID as keyof typeof tempTableDataInSheetID]) {
      return {
        tableDataInSheetID: {
          [sheetID]: tempTableDataInSheetID.current[sheetID as keyof typeof tempTableDataInSheetID],
        },
        skip: false,
        alreadyPresent: true,
      };
    }
    const tableDataRes = await getConvertedTableData({
      appID: appID,
      selectedTable: selectedTable,
      sheetID: sheetID,
      newSheetID: false,
    });
    if (tableDataRes.skip) {
      return { skip: true, alreadyPresent: false };
    }
    const tableSchema = tableDataRes?.data?.tableSchema || {};
    const finalTableData = {
      [sheetID]: {
        data: tableSchema.data,
        defaultTable: tableSchema.defaultTable,
        name: tableSchema?.defaultTable?.name ?? tableSchema?.defaultTable?.tableID,
        isJoinedTable: tableSchema.isJoinedTable ?? false,
      },
    };
    tempTableDataInSheetID.current = {
      ...tempTableDataInSheetID.current,
      ...finalTableData,
    };
    setTableDataInSheetID({
      ...tableDataInSheetID,
      ...finalTableData,
    });

    return {
      tableDataInSheetID: finalTableData,
      skip: false,
      alreadyPresent: false,
    };
    // dispatch(
    //   create({
    //     setPath: reduxConstants.config.Report_Table_Data,
    //     value: {},
    //   })
    // );
  };
  const settingsSchema = useMemo(() => {
    const newSechma = {
      ...reportSchema,
      reportID: reportID,
      appID: appID,
      name: reportName,
      reportType: selectedReportType,
      desc: reportDescription,
      scheduledTime: scheduledTime,
      pages: pagesList,
      recipients: sendTo,
      // reportTimeFrame: reportTimeFrame,
      // scheduledTime: scheduledTime,
      // reports: reports,
      // reportFilter: reportFilter,
    };
    return newSechma;
  }, [
    reportSchema,
    reportID,
    appID,
    reportName,
    reportDescription,
    scheduledTime,
    pagesList,
    sendTo,
    selectedReportType,
  ]);

  const stateProps = {
    appID,
    reportID,
    settingsSchema,

    chartSearch,
    handleSearch,

    tableDataInSheetID,
    setTableDataInSheetID,
    chartsData,
    setChartsData,
    projectSheets,
    setProjectSheets,
    reportName,
    setReportName,
    reportDescription,
    setReportDescription,
    populateCount,
    setPopulateCount,
    //Reports
    reportTools,
    setReportTools,
    reportTimeFrame,
    setReportTimeFrame,
    reports,
    setReports,
    sendTo,
    setSendTo,
    reportFilter,
    setReportFilter,
    scheduledTime,
    setScheduledTime,

    //NEW
    selectedReportType,
    setSelectedReportType,
    reportPageStage,
    setReportPageStage,
    pagesList,
    setPagesList,
    pageCount,
    setPageCount,
    selectedPageNumber,
    setSelectedPageNumber,
    allTableHeadData,
    setAllTableHeadData,
    tableHeadLoading,
    setTableHeadLoading,

    // Extra
    isReportConfiguration,
    handleReportConfigToggle,
    reportAreaLoading,
    setReportAreaLoading,
    tempTableDataInSheetID,

    sendToOptions,
    analyticsSchema,
    handleTableData,
  };
  useEffect(() => {
    const populateStatus = populateFunction({
      stateProps,
      reportSchema,
      populateState,
      setPopulateState,
    });
    if (!populateStatus?.skip) {
      tableHeadPopulateFunction({
        stateProps,
        reportSchema,
        populateState,
        setPopulateState,
        setIsPopulateComplete,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populateState]);
  useEffect(() => {
    if (isPopulateComplete) {
      setTableDataInSheetID(tempTableDataInSheetID.current);
      tempTableDataInSheetID.current = {};
      setIsPopulateComplete((preElem: boolean) => !preElem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopulateComplete, tempTableDataInSheetID.current]);

  return (
    <div className='ReportSettings'>
      <NonSupportedScreen closeButton={() => handleReportSettingsPopUp(false)} />
      <ToastContainer position='bottom-left' theme='colored' />
      <Header
        settingsSchema={settingsSchema}
        appID={appID}
        handleReportSettingsPopUp={handleReportSettingsPopUp}
        handleSave={handleSave}
        isNew={isNewChart}
        handleDelete={handleDelete}
        selectedReportType={selectedReportType}
        reportName={reportName}
        reportPageStage={reportPageStage}
        setReportPageStage={setReportPageStage}
      />
      <div className='reportSettingsWrapper'>
        {reportPageStage === reportPageStageObject.selectType.key ? (
          <SelectReportType stateProps={stateProps} />
        ) : null}
        {reportPageStage === reportPageStageObject.selectReport.key ? (
          <ReportTypeContainer stateProps={stateProps} />
        ) : null}
        {reportPageStage === reportPageStageObject.selectTime.key ? (
          <SaveReportContainer stateProps={stateProps} />
        ) : null}
      </div>
    </div>
  );
};

export default ReportSettingsWrapper;
