import React, { useState, useEffect } from 'react';
import { KTSVG } from '_metronic/helpers';
import sortArrayOfObjects from './sortArrayOfObjects';
import { mls } from 'lib/multilanguagesupport';
import DataLakeSearch from './DataLakeSearch/DataLakeSearch';

export const allSortingObject = {
  alphabetically: { name: 'Name', key: 'alphabetically' },
  createdAt: { name: 'Created At', key: 'createdAt' },
  updatedAt: { name: 'Updated At', key: 'updatedAt' },
  connectionName: { name: 'Connection Name', key: 'connectionName' },
};
const defaultSortingObject = {
  [allSortingObject.updatedAt.key]: allSortingObject.updatedAt,
  [allSortingObject.alphabetically.key]: allSortingObject.alphabetically,
  [allSortingObject.createdAt.key]: allSortingObject.createdAt,
};
const Sorting = ({
  setObjectOfObjects,
  dataInRedux,
  searchBy = 'name',
  secondarySearchBy = 'name',
  keyProperty = 'tableID',
  sortingObject = defaultSortingObject,
  updateSearchKey = () => {},
}) => {
  const [isSortAscending, setIsSortAscending] = useState(true); // true = ascending order sort; false = descending order sort
  const [appDataList, setAppDataList] = useState(dataInRedux);

  const [sortBy, setSortBy] = useState(
    Object.keys(sortingObject)[0]?.key ?? Object.keys(sortingObject)[0]
  );

  // Sort the data tables
  useEffect(() => {
    if (appDataList) {
      if (setObjectOfObjects) {
        const sorted = sortArrayOfObjects({
          arrayOfObjects: Object.values(appDataList),
          sortBy,
          isSortAscending,
          sortingObject,
          allSortingObject,
          nameKey: searchBy,
          secondaryNameKey: secondarySearchBy,
        });

        const objectFromArrayOfObjects = sorted.reduce((acc, obj) => {
          acc[obj[keyProperty]] = obj;
          return acc;
        }, {});
        setObjectOfObjects(objectFromArrayOfObjects);
      }
    }
  }, [appDataList, sortBy, isSortAscending, setObjectOfObjects]);

  return (
    <>
      <DataLakeSearch
        objectForSearch={dataInRedux}
        setSearch={setAppDataList}
        searchBy={searchBy}
        secondarySearchBy={secondarySearchBy}
        updateSearchKey={updateSearchKey}
      />

      <div
        style={{
          display: 'flex',
          gap: '.5rem',
          alignItems: 'center',
        }}
      >
        <label
          style={{
            textWrap: 'nowrap',
          }}
        >
          {mls('Sort by')}:
        </label>
        <select
          className='form-select form-select-sm mx-1'
          // style={{ width: '100%' }}
          value={sortBy}
          onChange={(e) => {
            setSortBy(e.target.value);
          }}
        >
          {React.Children.toArray(
            Object.keys(sortingObject).map((sortingKey) => {
              return (
                <option value={sortingKey}>{mls(`${sortingObject?.[sortingKey]?.name}`)}</option>
              );
            })
          )}
        </select>

        <button
          type='button'
          className='btn btn-sm btn-light mx-1 px-4'
          onMouseDown={(e) => e.preventDefault()} // remove hover from button
          onClick={(e) => {
            e.preventDefault();
            setIsSortAscending((old) => !old);
          }} // invert sorting order
        >
          <KTSVG
            className='m-0'
            path={`/media/icons/duotune/arrows/${isSortAscending ? 'arr066.svg' : 'arr065.svg'}`}
          />
        </button>
      </div>
    </>
  );
};

export default Sorting;
