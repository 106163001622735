import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';

export const GoogleSheetsInfo = () => {
  const SUPISTA_GOOGLE_SERVICE_ACCOUNT = 'supista@supista.iam.gserviceaccount.com';
  const copyServiceAccountEmailToClipboard = () => {
    navigator.clipboard.writeText(SUPISTA_GOOGLE_SERVICE_ACCOUNT);
    store.dispatch(
      create({
        setPath: reduxConstants.config.APP_STATUS.TOAST,
        value: {
          message: 'Copied to clipboard',
          time: 5,
          isActive: true,
        },
      })
    );
  };
  return (
    <>
      <p className='mb-5'>
        To allow Supista to read data from your spreadsheet, add the following account as a 'Viewer'
        in your spreadsheet.
      </p>
      <span className='my-5 fw-bold p-2 border border-warning rounded'>
        {SUPISTA_GOOGLE_SERVICE_ACCOUNT}
      </span>
      <span
        onClick={copyServiceAccountEmailToClipboard}
        style={{ cursor: 'pointer', userSelect: 'none' }}
        className='ms-4 badge bg-warning text-dark'
      >
        Copy
      </span>
    </>
  );
};

export default GoogleSheetsInfo;
