import clsx from 'clsx';
import { mls } from 'lib/multilanguagesupport';

export const EmailForm = ({ formik }) => {
  return (
    <div className='fv-row mb-10'>
      <label className='form-label fw-bolder text-gray-900 fs-6'>
        {mls('Email'.replaceAll(' ', ''))}
      </label>
      <input
        type='email'
        placeholder=''
        autoComplete='off'
        {...formik.getFieldProps('email')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
          { 'is-invalid': formik.touched.email && formik.errors.email },
          {
            // 'is-valid': formik.touched.email && !formik.errors.email,
          }
        )}
      />
      {formik.touched.email && formik.errors.email && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert' className='text-danger'>{mls(formik.errors.email)}</span>
          </div>
        </div>
      )}
    </div>
  );
};
