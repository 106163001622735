import useUpdateEffect from '../../hooks/useUpdateEffect';
import React, { useMemo, useState } from 'react';
import arrowDropDownIcon from '../../assets/arrowDropDownIcon.svg';
import { defaultDataType } from 'lib/reusable-components/reusableData/defaultChartSchema';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import NumberFormatterComp from 'lib/chart-settings-lib/features/Formatter/NumberFormatterComp';
import { getGroupDataByChart } from 'lib/chart-settings-lib/utils/chartHelperFunctions';
import DateTimeFormatterComp from 'lib/chart-settings-lib/features/Formatter/DateTimeFormatterComp';
import { useSelector } from 'react-redux';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { mls } from 'lib/multilanguagesupport';

const ToolTipDiv = (props) => {
  const { elm, provided, show = true, stateProps, index } = props;
  const { setChartToolTip, chartToolTip, chartType, xAxisData, yAxisData, chartID } = stateProps;
  const [isDivOpen, setisDivOpen] = useState(show);
  const [isContent, setisContent] = useState(show);

  const udpateToolTip = (data) => {
    setChartToolTip((currentValue) => {
      const sourceitems = Array.from(currentValue.style);
      const [selectedItem] = sourceitems.splice(index, 1);
      const newSelectedItem = { ...selectedItem, ...data };
      sourceitems.splice(index, 0, newSelectedItem);
      const newSourceItem = {
        ...currentValue,
        style: sourceitems,
      };
      return newSourceItem;
    });
  };
  const handleLabel = (e) => {
    udpateToolTip({ label: e.target.value });
  };
  const handleShow = (action) => {
    udpateToolTip({ show: action });
  };
  const handleDiv = () => {
    setisDivOpen((currentValue) => !currentValue);
  };
  useUpdateEffect(() => {
    if (!isDivOpen) {
      const timer = setTimeout(() => setisContent(isDivOpen), 300);
      return () => {
        clearTimeout(timer);
      };
    }
    setisContent(isDivOpen);
  }, [isDivOpen]);
  const handleFormatter = (updatedFormatter) => {
    udpateToolTip({ formatter: updatedFormatter });
  };
  const groupData = getGroupDataByChart({ stateProps, chartType }).data;
  const columnData = useMemo(
    () => returnOpration({ chartType, xAxisData, yAxisData, groupData: groupData, elm }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [xAxisData, yAxisData, groupData, chartToolTip]
  );

  const timeFrameData = useSelector(
    (state) => state[reduxConstants.STORE_NAME][reduxConstants.config.DATE_TIME_TOGGLE]
  );
  return (
    <div
      className={isDivOpen ? 'containerItems open horizontalDiv' : 'containerItems horizontalDiv'}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div
        className='titleDiv cursorPointer toolTipTitleContainer'
        onClick={() => handleDiv()}
        title={`${columnData?.opration} ${elm.originalLabel}`}
      >
        <div className='toolTipTitle'>
          <span>{columnData?.opration}</span>&nbsp;
          {elm.originalLabel}
        </div>
        <img src={arrowDropDownIcon} alt='' className='arrowIcon' />
      </div>
      <div className='contaierClosableDiv'>
        {isContent && (
          <>
            <div className='inputDivContainer'>
              <div className='toolTipInputDiv'>
                <input type='text' value={elm.label} onChange={(e) => handleLabel(e)} />

                <div className='visibilityToggleDiv'>
                  &nbsp;{mls('Visibility')}:
                  <SlideSwitch isChecked={elm.show} setIsChecked={handleShow} type={'small'} />
                </div>
                {columnData?.column?.dataType === defaultDataType?.NUM ? (
                  <NumberFormatterComp formatter={elm?.formatter} setFormatter={handleFormatter} />
                ) : null}

                {chartType?.chartKey !== allChartKeys.HEATMAP &&
                columnData?.column?.dataType === defaultDataType?.DATETIME ? (
                  <DateTimeFormatterComp
                    formatter={elm?.formatter}
                    setFormatter={handleFormatter}
                    format={timeFrameData?.toggle?.[chartID] ?? columnData.oprationType}
                  />
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ToolTipDiv;

const returnOpration = ({ xAxisData, yAxisData, groupData, elm }) => {
  const getOpration = () => {
    const findInxAxisData = xAxisData.find((e) => {
      return e.uniqueColumnName === elm.uniqueColumnName;
    });
    if (findInxAxisData) return findInxAxisData;

    const findInyAxisData = yAxisData.find((e) => {
      return e.uniqueColumnName === elm.uniqueColumnName;
    });

    if (findInyAxisData) return findInyAxisData;
    const findInGroupAxisData = groupData.find((e) => {
      return e.uniqueColumnName === elm.uniqueColumnName;
    });

    if (findInGroupAxisData) return findInGroupAxisData;
    return false;
  };
  const data = getOpration();
  if (data?.operations?.type) {
    return {
      column: data,
      opration: `(${data.operations.type})`,
      oprationType: data.operations.type,
    };
  } else {
    return { column: data, opration: '', oprationType: undefined };
  }
};
