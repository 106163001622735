import React from 'react';
import { FaCheck } from 'react-icons/fa';
import './chekBox.scss';
interface CheckBoxProps {
  checked: boolean;
  onClick?: Function;
}
const CheckBox = ({ checked, onClick = () => {} }: CheckBoxProps) => {
  return (
    <div
      className={checked ? 'CustomCheckBoxInputContainer checked' : 'CustomCheckBoxInputContainer'}
      onClick={() => onClick(!checked)}
    >
      <FaCheck className='checkIcon' />
    </div>
  );
};

export default CheckBox;
