import { toAbsoluteUrl } from '_metronic/helpers';
import { allDropableID, defaultChartWidthTypesObj, defaultDataType } from './defaultChartSchema';
import { mls } from 'lib/multilanguagesupport';

const { xAxisId, yAxisId, groupId } = allDropableID;
const { ALL, NUM, CAT, DATETIME } = defaultDataType;
export const allChartKeys = {
  BAR: 'BAR',
  BARSTACK: 'BARSTACK',

  LINE: 'LINE',
  MULTILINE: 'MULTILINE',

  AREA: 'AREA',
  AREASTACK: 'AREASTACK',

  BARGROUP: 'BARGROUP',

  BARHORIZONTAL: 'BARHORIZONTAL',
  BARSTACKHORIZONTAL: 'BARSTACKHORIZONTAL',

  LINEHORIZONTAL: 'LINEHORIZONTAL', // NIC
  MULTILINEHORIZONTAL: 'MULTILINEHORIZONTAL', // NIC

  AREAHORIZONTAL: 'AREAHORIZONTAL', // NIC
  AREASTACKHORIZONTAL: 'AREASTACKHORIZONTAL', // NIC
  MULTIAXISMULTIAREAHORIZONTAL: 'MULTIAXISMULTIAREAHORIZONTAL', // NIC

  CARD: 'CARD',
  FUNNEL: 'FUNNEL',

  PIE: 'PIE',
  DONUT: 'DONUT',

  GEOINDIA: 'GEOINDIA',
  GEOUSA: 'GEOUSA',
  GEOCANADA: 'GEOCANADA ',
  GEOWORLD: 'GEOWORLD',
  GEOMAP: 'GEOMAP',
  GEOMAPCOLOR: 'GEOMAPCOLOR',

  WORDCLOUD: 'WORDCLOUD',

  BUBBLEPLOT: 'BUBBLEPLOT',
  GROUPBUBBLEPLOT: 'GROUPBUBBLEPLOT',
  SCATTERPLOT: 'SCATTERPLOT',
  GROUPSCATTERPLOT: 'GROUPSCATTERPLOT',

  TREEMAP: 'TREEMAP',
  RADAR: 'RADAR',
  RADIALLINE: 'RADIALLINE',
  BARSTACKLINE: 'BARSTACKLINE',
  KPIMETER: 'KPIMETER',
  KPITRACKING: 'KPITRACKING',
  PIVOTTABLE: 'PIVOTTABLE',
  HEATMAP: 'HEATMAP',

  BARCOLOR: 'BARCOLOR',
  LINECOLOR: 'LINECOLOR',
  AREACOLOR: 'AREACOLOR',
  BARHORIZONTALCOLOR: 'BARHORIZONTALCOLOR',
};
const { FULL, HALF, TWOTHIRD, ONEFOURTH } = defaultChartWidthTypesObj;
export const allChartTypesObj = {
  [allChartKeys.BAR]: {
    name: 'Bar',
    relatedTo: {
      name: 'Bar',
      key: allChartKeys.BAR,
    },
    chartKey: allChartKeys.BAR,
    tooltipDiscription: 'Compare categorical data with vertical bars for easy value analysis.',
    largeDisription: '',
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${mls(allChartKeys.PIVOTTABLE)}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.BARSTACK,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${mls(allChartKeys.BARSTACK)}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.BARCOLOR,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${mls(allChartKeys.BARCOLOR)}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/barStackChart.svg'),
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
    relatedChartTypes: [allChartKeys.LINE, allChartKeys.AREA, allChartKeys.BAR],
    xAxis: [CAT],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        // xAxis:[ALL],
        // yAxis:[ALL],
        // group:[ALL]
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
  },
  [allChartKeys.BARCOLOR]: {
    name: 'Bar Color',
    relatedTo: {
      name: 'Bar',
      key: allChartKeys.BAR,
    },
    chartKey: allChartKeys.BARCOLOR,
    tooltipDiscription: 'Compare categorical data with vertical bars for easy value analysis.',
    largeDisription: '',

    isMultipleValue: true,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          // msg: `${mls("Changed to")} <b>${allChartKeys.PIVOTTABLE}</b>`,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          // msg: `${mls("Changed to")} <b>${allChartKeys.PIVOTTABLE}</b>`,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.BAR,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.BAR}</b>`,
        },
      },
    },
    toolTip: {
      target: groupId,
      isMultipleValue: true,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/barStackChart.svg'),
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
    relatedChartTypes: [allChartKeys.LINE, allChartKeys.AREA, allChartKeys.BAR],
    xAxis: [CAT],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
  },

  [allChartKeys.BARSTACK]: {
    name: 'Bar Stack',
    relatedTo: {
      name: 'Bar',
      key: allChartKeys.BAR,
    },
    chartKey: allChartKeys.BARSTACK,
    relatedChartTypes: [allChartKeys.LINE, allChartKeys.AREA, allChartKeys.BAR],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.BAR,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.BAR}</b>`,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 1,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/barStackChart.svg'),
    isMultipleValue: false,
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    xAxis: [CAT],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },

  [allChartKeys.LINE]: {
    name: 'Line',
    relatedTo: {
      name: 'Line',
      key: allChartKeys.LINE,
    },
    tooltipDiscription:
      'Identify trends or patterns over time with connected data points for visual analysis.',
    largeDisription: '',

    chartKey: allChartKeys.LINE,
    relatedChartTypes: [allChartKeys.BAR, allChartKeys.AREA, allChartKeys.LINE],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.MULTILINE,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.MULTILINE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.LINECOLOR,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.LINECOLOR}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/lineChart.svg'),
    isMultipleValue: false,
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    xAxis: [CAT],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.LINECOLOR]: {
    name: 'Line Color',
    relatedTo: {
      name: 'Line',
      key: allChartKeys.LINE,
    },
    tooltipDiscription:
      'Identify trends or patterns over time with connected data points for visual analysis.',
    largeDisription: '',
    chartKey: allChartKeys.LINECOLOR,
    relatedChartTypes: [allChartKeys.BAR, allChartKeys.AREA, allChartKeys.LINE],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          // msg: `${mls("Changed to")} <b>${allChartKeys.PIVOTTABLE}</b>`,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          // msg: `${mls("Changed to")} <b>${allChartKeys.PIVOTTABLE}</b>`,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.LINE,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.LINE}</b>`,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/lineChart.svg'),
    isMultipleValue: true,
    toolTip: {
      target: groupId,
      isMultipleValue: true,
    },
    xAxis: [CAT],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },

  [allChartKeys.MULTILINE]: {
    name: 'Multi-line',
    relatedTo: {
      name: 'Line',
      key: allChartKeys.LINE,
    },
    chartKey: allChartKeys.MULTILINE,
    relatedChartTypes: [allChartKeys.BAR, allChartKeys.AREA, allChartKeys.LINE],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.LINE,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.LINE}</b>`,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 1,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/lineChart.svg'),
    isMultipleValue: false,
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    xAxis: [CAT],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },

  [allChartKeys.AREA]: {
    name: 'Area',
    relatedTo: {
      name: 'Area',
      key: allChartKeys.AREA,
    },
    tooltipDiscription: 'Visualize data patterns and trends from the area.',
    largeDisription: '',

    chartKey: allChartKeys.AREA,
    relatedChartTypes: [allChartKeys.LINE, allChartKeys.BAR, allChartKeys.AREA],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.AREASTACK,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.AREASTACK}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.AREACOLOR,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.AREACOLOR}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/areaStackChart.svg'),
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    xAxis: [CAT],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.AREACOLOR]: {
    name: 'Area Color',
    relatedTo: {
      name: 'Area',
      key: allChartKeys.AREA,
    },
    tooltipDiscription: 'Visualize data patterns and trends from the area.',
    largeDisription: '',
    chartKey: allChartKeys.AREACOLOR,
    relatedChartTypes: [allChartKeys.LINE, allChartKeys.BAR, allChartKeys.AREA],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          // msg: `${mls("Changed to")} <b>${allChartKeys.PIVOTTABLE}</b>`,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          // msg: `${mls("Changed to")} <b>${allChartKeys.PIVOTTABLE}</b>`,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.AREA,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.AREA}</b>`,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/areaStackChart.svg'),
    toolTip: {
      target: groupId,
      isMultipleValue: true,
    },
    xAxis: [CAT],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.AREASTACK]: {
    name: 'Area Stack',
    chartKey: allChartKeys.AREASTACK,
    relatedTo: {
      name: 'Area',
      key: allChartKeys.AREA,
    },
    relatedChartTypes: [allChartKeys.LINE, allChartKeys.BAR, allChartKeys.AREA],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: false,
          showMsg: true,
          msg: `${mls('Changed to')} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.AREA,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.AREA}</b>`,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 1,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/areaStackChart.svg'),
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    xAxis: [CAT],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.BARHORIZONTAL]: {
    name: 'Bar Horizontal',
    relatedTo: {
      name: 'Bar Horizontal',
      key: allChartKeys.BARHORIZONTAL,
    },
    tooltipDiscription:
      'Compare categorical data from a horizontal perspective with horizontal bars.',
    largeDisription: '',

    chartKey: allChartKeys.BARHORIZONTAL,
    relatedChartTypes: [allChartKeys.BARHORIZONTAL],
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/horizontalBarStackChart.svg'),
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.BARSTACKHORIZONTAL,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.BARSTACKHORIZONTAL}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          msg: ``,
          // msg: `${mls("Changed to")} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.BARHORIZONTALCOLOR,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.BARHORIZONTALCOLOR}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    toolTip: {
      target: xAxisId,
      isMultipleValue: false,
    },
    xAxis: [NUM],
    yAxis: [CAT],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: NUM,
        yAxis: CAT,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },

    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.BARHORIZONTALCOLOR]: {
    name: 'Bar Horizontal Color',
    relatedTo: {
      name: 'Bar Horizontal',
      key: allChartKeys.BARHORIZONTAL,
    },
    tooltipDiscription:
      'Compare categorical data from a horizontal perspective with horizontal bars.',
    largeDisription: '',

    chartKey: allChartKeys.BARHORIZONTALCOLOR,
    relatedChartTypes: [allChartKeys.BARHORIZONTAL],
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/horizontalBarStackChart.svg'),
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          // msg: `${mls("Changed to")} <b>${allChartKeys.PIVOTTABLE}</b>`,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          // msg: `${mls("Changed to")} <b>${allChartKeys.PIVOTTABLE}</b>`,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.BARHORIZONTAL,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.BARHORIZONTAL}</b>`,
        },
      },
    },
    toolTip: {
      target: groupId,
      isMultipleValue: true,
    },
    xAxis: [NUM],
    yAxis: [CAT],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: NUM,
        yAxis: CAT,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.BARSTACKHORIZONTAL]: {
    name: 'Bar Stack Horizontal',
    relatedTo: {
      name: 'Bar Horizontal',
      key: allChartKeys.BARHORIZONTAL,
    },
    chartKey: allChartKeys.BARSTACKHORIZONTAL,
    relatedChartTypes: [allChartKeys.BARHORIZONTAL],
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/horizontalBarStackChart.svg'),
    isMultipleValue: false,

    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.BARHORIZONTAL,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.BARHORIZONTAL}</b>`,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          msg: ``,
          // msg: `${mls("Changed to")} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 1,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
      },
    },
    toolTip: {
      target: xAxisId,
      isMultipleValue: false,
    },
    xAxis: [NUM],
    yAxis: [CAT],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: NUM,
        yAxis: CAT,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.PIE]: {
    name: 'Pie',
    relatedTo: {
      name: 'Pie',
      key: allChartKeys.PIE,
    },
    tooltipDiscription:
      'Understand relative distribution of categories with slices of a circular chart.',
    largeDisription: '',

    chartKey: allChartKeys.PIE,
    specialCase: true,
    relatedChartTypes: [
      allChartKeys.DONUT,
      allChartKeys.PIE,
      allChartKeys.TREEMAP,
      allChartKeys.LINE,
      allChartKeys.AREA,
      allChartKeys.BAR,
    ],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: false,
          showMsg: true,
          msg: `${mls('Changed to')} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.BARSTACK,
          isError: false,
          showMsg: true,
          msg: `${mls('Changed to')} <b>${allChartKeys.BARSTACK}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    isMultipleValue: true,
    toolTip: {
      target: xAxisId,
      isMultipleValue: true,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/pieChart.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [CAT],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.DONUT]: {
    name: 'Donut',
    relatedTo: {
      name: 'Donut',
      key: allChartKeys.DONUT,
    },
    tooltipDiscription:
      'Represent proportion of categories with a circular chart with a central hole.',
    largeDisription: '',

    chartKey: allChartKeys.DONUT,
    specialCase: true,
    isMultipleValue: true,
    toolTip: {
      target: xAxisId,
      isMultipleValue: true,
    },
    relatedChartTypes: [
      allChartKeys.DONUT,
      allChartKeys.PIE,
      allChartKeys.TREEMAP,
      allChartKeys.LINE,
      allChartKeys.AREA,
      allChartKeys.BAR,
    ],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: false,
          showMsg: true,
          msg: `${mls('Changed to')} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.BARSTACK,
          isError: false,
          showMsg: true,
          msg: `${mls('Changed to')} <b>${allChartKeys.BARSTACK}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/donutChart.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [CAT],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.BARGROUP]: {
    name: 'Bar Group',
    relatedTo: {
      name: 'Bar Group',
      key: allChartKeys.BARGROUP,
    },
    tooltipDiscription:
      'Compare categories within each group using grouped bars for effective comparison.',
    largeDisription: '',

    chartKey: allChartKeys.BARGROUP,
    relatedChartTypes: [],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: false,
          showMsg: true,
          msg: `${mls('Changed to')} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    isMultipleValue: false,
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/barGroupChart.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.CARD]: {
    name: 'Card',
    relatedTo: {
      name: 'Card',
      key: allChartKeys.CARD,
    },
    chartKey: allChartKeys.CARD,
    tooltipDiscription: 'Display key metrics or data points concisely and in a compact format.',
    largeDisription: '',

    isMultipleValue: false,
    relatedChartTypes: [],
    specialCase: true,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: `This field doesn't accept multiple columns`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/card3.svg'),
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: ONEFOURTH,
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [ALL],
      },
    },
  },
  [allChartKeys.FUNNEL]: {
    name: 'Funnel',
    relatedTo: {
      name: 'Funnel',
      key: allChartKeys.FUNNEL,
    },
    chartKey: allChartKeys.FUNNEL,
    tooltipDiscription:
      'Visualize progression and conversion rates of a process or customer journey.',
    largeDisription: '',

    relatedChartTypes: [],
    specialCase: true,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: false,
          showMsg: true,
          msg: `${mls('Changed to')} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.BARSTACK,
          isError: false,
          showMsg: true,
          msg: `${mls('Changed to')} <b>${allChartKeys.BARSTACK}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    isMultipleValue: true,
    toolTip: {
      target: xAxisId,
      isMultipleValue: true,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/funnel.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [CAT],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.GEOINDIA]: {
    name: 'Geo india',
    relatedTo: {
      name: 'Geo india',
      key: allChartKeys.GEOINDIA,
    },
    chartKey: allChartKeys.GEOINDIA,
    tooltipDiscription: 'Map data to Indian Map for state-wise geographical analysis.',
    largeDisription: '',

    relatedChartTypes: [
      allChartKeys.GEOCANADA,
      allChartKeys.GEOUSA,
      allChartKeys.GEOINDIA,
      allChartKeys.GEOWORLD,
    ],

    specialCase: true,
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/geoIndia.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [CAT],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.GEOINDIA]: {
    name: 'Geo india',
    relatedTo: {
      name: 'Geo india',
      key: allChartKeys.GEOINDIA,
    },
    chartKey: allChartKeys.GEOINDIA,
    tooltipDiscription: 'Map data to Indian Map for state-wise geographical analysis.',
    largeDisription: '',

    relatedChartTypes: [
      allChartKeys.GEOCANADA,
      allChartKeys.GEOUSA,
      allChartKeys.GEOINDIA,
      allChartKeys.GEOWORLD,
    ],

    specialCase: true,
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/geoIndia.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [CAT],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.GEOUSA]: {
    name: 'Geo USA',
    relatedTo: {
      name: 'Geo USA',
      key: allChartKeys.GEOUSA,
    },
    chartKey: allChartKeys.GEOUSA,
    tooltipDiscription: 'Map data to the USA map for state-wise geographical analysis.',
    largeDisription: '',

    relatedChartTypes: [
      allChartKeys.GEOCANADA,
      allChartKeys.GEOUSA,
      allChartKeys.GEOINDIA,
      allChartKeys.GEOWORLD,
    ],

    specialCase: true,
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/geoUsa.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [CAT],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.GEOCANADA]: {
    name: 'Geo Canada',
    relatedTo: {
      name: 'Geo Canada',
      key: allChartKeys.GEOCANADA,
    },
    chartKey: allChartKeys.GEOCANADA,
    tooltipDiscription: 'Map data to the Canada map for state-wise geographical analysis.',
    largeDisription: '',

    relatedChartTypes: [
      allChartKeys.GEOCANADA,
      allChartKeys.GEOUSA,
      allChartKeys.GEOINDIA,
      allChartKeys.GEOWORLD,
    ],

    specialCase: true,
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/geoCanada.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [CAT],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.GEOWORLD]: {
    name: 'Geo World',
    relatedTo: {
      name: 'Geo World',
      key: allChartKeys.GEOWORLD,
    },
    chartKey: allChartKeys.GEOWORLD,
    tooltipDiscription: 'Map data to the Global map for Global geographical analysis.',
    largeDisription: '',

    relatedChartTypes: [
      allChartKeys.GEOCANADA,
      allChartKeys.GEOUSA,
      allChartKeys.GEOINDIA,
      allChartKeys.GEOWORLD,
    ],

    specialCase: true,
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/geoWorld.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [CAT],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: FULL,
  },
  [allChartKeys.GEOMAP]: {
    name: 'Geo india',
    relatedTo: {
      name: 'Geo Map',
      key: allChartKeys.GEOMAP,
    },
    chartKey: allChartKeys.GEOMAP,
    tooltipDiscription: 'Map data for state-wise geographical analysis.',
    largeDisription: '',
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/geoMap.svg'),

    relatedChartTypes: [],

    specialCase: true,
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 3,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.GEOMAPCOLOR,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${mls(allChartKeys.GEOMAPCOLOR)}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
      },
    },
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [NUM],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: FULL,
  },
  [allChartKeys.GEOMAPCOLOR]: {
    name: 'Geo india',
    relatedTo: {
      name: 'Geo Map',
      key: allChartKeys.GEOMAPCOLOR,
    },
    chartKey: allChartKeys.GEOMAPCOLOR,
    tooltipDiscription: 'Map data for state-wise geographical analysis.',
    largeDisription: '',

    relatedChartTypes: [],

    specialCase: true,
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 3,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.GEOMAP,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.GEOMAP}</b>`,
        },
      },
    },
    toolTip: {
      target: groupId,
      isMultipleValue: true,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/geoMap.svg'),
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [NUM],
        yAxis: [ALL],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: FULL,
  },
  [allChartKeys.WORDCLOUD]: {
    name: 'Word cloud',
    relatedTo: {
      name: 'Word cloud',
      key: allChartKeys.WORDCLOUD,
    },
    chartKey: allChartKeys.WORDCLOUD,
    tooltipDiscription:
      'Highlight significant terms by displaying words in varying sizes based on frequency and importance.',
    largeDisription: '',

    relatedChartTypes: [],
    specialCase: true,
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/wordCloud.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [CAT],
        yAxis: [],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.SCATTERPLOT]: {
    name: 'Scatter Plot',
    chartKey: allChartKeys.SCATTERPLOT,
    relatedTo: {
      name: 'Scatter Plot',
      key: allChartKeys.SCATTERPLOT,
    },
    tooltipDiscription:
      'Plot data points to explore the relationship and correlation between two numerical columns.',
    largeDisription: '',

    relatedChartTypes: [allChartKeys.SCATTERPLOT],
    specialCase: true,
    // isMultipleValue: true,
    // toolTip: {
    //   target: groupId,
    //   isMultipleValue: true,
    // },
    isMultipleValue: false,
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.BUBBLEPLOT,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.BUBBLEPLOT}</b>`,
        },
        // add: {
        //   triggerColoumnValue: 2,
        //   changeTo: null,
        //   isError: true,
        //   showMsg: true,
        //   msg: ``,
        // },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.GROUPSCATTERPLOT,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.GROUPSCATTERPLOT}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/bubblePlot.svg'),
    xAxis: [NUM],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: NUM,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [NUM],
        yAxis: [NUM],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.GROUPSCATTERPLOT]: {
    name: 'Group Scatter Plot',
    relatedTo: {
      name: 'Scatter Plot',
      key: allChartKeys.SCATTERPLOT,
    },
    tooltipDiscription: '',
    largeDisription: '',
    chartKey: allChartKeys.GROUPSCATTERPLOT,
    relatedChartTypes: [allChartKeys.SCATTERPLOT],
    specialCase: true,
    isMultipleValue: true,
    toolTip: {
      target: groupId,
      isMultipleValue: true,
    },
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        // add: {
        //   triggerColoumnValue: 2,
        //   changeTo: null,
        //   isError: true,
        //   showMsg: true,
        //   msg: ``,
        // },
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.GROUPBUBBLEPLOT,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.GROUPBUBBLEPLOT}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.SCATTERPLOT,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.SCATTERPLOT}</b>`,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/bubblePlot.svg'),
    xAxis: [NUM],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: NUM,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [NUM],
        yAxis: [NUM],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.BUBBLEPLOT]: {
    name: 'Bubble Plot',
    relatedTo: {
      name: 'Scatter Plot',
      key: allChartKeys.SCATTERPLOT,
    },
    chartKey: allChartKeys.BUBBLEPLOT,
    tooltipDiscription: '',
    largeDisription: '',
    relatedChartTypes: [allChartKeys.SCATTERPLOT],
    specialCase: true,
    isMultipleValue: false,
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 3,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.SCATTERPLOT,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.SCATTERPLOT}</b>`,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.GROUPBUBBLEPLOT,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.GROUPBUBBLEPLOT}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/bubblePlot.svg'),
    // icon: bubblePlot1,
    // icon: bubblePlot2,
    xAxis: [NUM],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: NUM,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [NUM],
        yAxis: [NUM],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.GROUPBUBBLEPLOT]: {
    name: 'Group Bubble Plot',
    relatedTo: {
      name: 'Scatter Plot',
      key: allChartKeys.SCATTERPLOT,
    },
    tooltipDiscription: '',
    largeDisription: '',
    chartKey: allChartKeys.GROUPBUBBLEPLOT,
    relatedChartTypes: [allChartKeys.SCATTERPLOT],
    specialCase: true,
    isMultipleValue: true,
    toolTip: {
      target: groupId,
      isMultipleValue: true,
    },
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 3,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 1,
          changeTo: allChartKeys.GROUPSCATTERPLOT,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.GROUPSCATTERPLOT}</b>`,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: 0,
          changeTo: allChartKeys.BUBBLEPLOT,
          isError: false,
          showMsg: false,
          msg: `${mls('Changed to')} <b>${allChartKeys.BUBBLEPLOT}</b>`,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/bubblePlot.svg'),
    xAxis: [NUM],
    yAxis: [NUM],
    group: [CAT],
    axis: {
      axisType: {
        xAxis: NUM,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [NUM],
        yAxis: [NUM],
        group: [CAT],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.KPIMETER]: {
    name: 'KPI Meters',
    relatedTo: {
      name: 'KPI Meters',
      key: allChartKeys.KPIMETER,
    },
    chartKey: allChartKeys.KPIMETER,
    tooltipDiscription:
      'Assess performance against targets with a dial of key performance indicators.',
    largeDisription: '',

    relatedChartTypes: [],
    specialCase: true,
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/kpiMeter.svg'),
    xAxis: [NUM],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: NUM,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.KPITRACKING]: {
    name: 'KPI Tracking',
    relatedTo: {
      name: 'KPI Tracking',
      key: allChartKeys.KPITRACKING,
    },
    chartKey: allChartKeys.KPITRACKING,
    tooltipDiscription:
      'Assess performance against targets with a dial of key performance indicators.',
    largeDisription: '',

    relatedChartTypes: [],
    specialCase: true,
    isMultipleValue: false,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/kpiMeter.svg'),
    xAxis: [NUM],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: NUM,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.BARSTACKLINE]: {
    name: 'BAR Stack line',
    relatedTo: {
      name: 'BAR Stack line',
      key: allChartKeys.BARSTACKLINE,
    },
    chartKey: allChartKeys.BARSTACKLINE,
    relatedChartTypes: [],
    specialCase: true,
    isMultipleValue: false,
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/barStackLine.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [ALL],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.RADIALLINE]: {
    name: 'Radial Line',
    relatedTo: {
      name: 'Radial Line',
      key: allChartKeys.RADIALLINE,
    },
    tooltipDiscription:
      'A Radial line chart represents time-series data by displaying lines originating from the center, with each line corresponding to a specific date and radiating outward to convey variations in values over time.',
    chartKey: allChartKeys.RADIALLINE,
    specialCase: true,
    relatedChartTypes: [],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    isMultipleValue: false,
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/radialChart.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [DATETIME],
        yAxis: [ALL],
        group: [],
      },
    },

    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.RADAR]: {
    name: 'Radar',
    relatedTo: {
      name: 'Radar',
      key: allChartKeys.RADAR,
    },
    chartKey: allChartKeys.RADAR,
    tooltipDiscription:
      'Compare different factors or dimensions by plotting multiple variables on a radial chart.',
    largeDisription: '',

    specialCase: true,
    relatedChartTypes: [],
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    isMultipleValue: true,
    toolTip: {
      target: xAxisId,
      isMultipleValue: true,
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/radarLineChart.svg'),
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [CAT],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
  },
  [allChartKeys.TREEMAP]: {
    name: 'Tree Map',
    relatedTo: {
      name: 'Tree Map',
      key: allChartKeys.TREEMAP,
    },
    specialCase: true,
    tooltipDiscription:
      'Highlight the significance of categories using rectangles of varying sizes.',
    largeDisription: '',

    chartKey: allChartKeys.TREEMAP,
    toolTip: {
      target: yAxisId,
      isMultipleValue: true,
    },
    isMultipleValue: true,
    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.PIVOTTABLE,
          isError: false,
          showMsg: true,
          msg: `${mls('Changed to')} <b>${allChartKeys.PIVOTTABLE}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: allChartKeys.BARSTACK,
          isError: false,
          showMsg: true,
          msg: `${mls('Changed to')} <b>${allChartKeys.BARSTACK}</b>`,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/treeMap.svg'),
    // relatedChartTypes: [allChartKeys.LINE, allChartKeys.AREA, allChartKeys.BAR],
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: HALF,
    relatedChartTypes: [
      allChartKeys.LINE,
      allChartKeys.AREA,
      allChartKeys.BAR,
      allChartKeys.PIE,
      allChartKeys.DONUT,
    ],
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [CAT],
        yAxis: [ALL],
        group: [],
      },
    },
  },
  [allChartKeys.PIVOTTABLE]: {
    name: 'Pivot Table',
    relatedTo: {
      name: 'Pivot Table',
      key: allChartKeys.PIVOTTABLE,
    },
    chartKey: allChartKeys.PIVOTTABLE,
    relatedChartTypes: [],
    specialCase: true,
    tooltipDiscription:
      'Summarizes and analyzes data based on different columns providing insights into trends and patterns.',
    largeDisription: '',

    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/pivotTable.svg'),
    isMultipleValue: false,
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    xAxis: [CAT],
    yAxis: [NUM],
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: NUM,
        group: CAT,
      },
      axisDataSupport: {
        xAxis: [ALL],
        yAxis: [ALL],
        group: [],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: FULL,
  },

  [allChartKeys.HEATMAP]: {
    name: 'Heat Map',
    relatedTo: {
      name: 'Heat Map',
      key: allChartKeys.HEATMAP,
    },
    chartKey: allChartKeys.HEATMAP,
    relatedChartTypes: [],
    specialCase: true,
    tooltipDiscription:
      'A heat map chart visually displays data using color gradients to represent variations in intensity or values across a two-dimensional space.',
    largeDisription: '',

    multipleAxis: {
      xAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      yAxis: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
      group: {
        add: {
          triggerColoumnValue: 2,
          changeTo: null,
          isError: true,
          showMsg: true,
          msg: ``,
        },
        remove: {
          triggerColoumnValue: null,
          changeTo: null,
          isError: false,
          showMsg: false,
          msg: ``,
        },
      },
    },
    icon: toAbsoluteUrl('/media/resusableAssets/chartIcons/heatMap.svg'),
    isMultipleValue: false,
    toolTip: {
      target: yAxisId,
      isMultipleValue: false,
    },
    axis: {
      axisType: {
        xAxis: CAT,
        yAxis: CAT,
        group: NUM,
      },
      axisDataSupport: {
        xAxis: [CAT, DATETIME],
        yAxis: [CAT, DATETIME],
        group: [NUM],
      },
    },
    width: { FULL, HALF, TWOTHIRD },
    defaultChartWidth: FULL,
  },
};

export const allChartTypes = [
  allChartTypesObj[allChartKeys.BAR],
  allChartTypesObj[allChartKeys.BARCOLOR],
  allChartTypesObj[allChartKeys.BARSTACK],
  allChartTypesObj[allChartKeys.LINE],
  allChartTypesObj[allChartKeys.LINECOLOR],
  allChartTypesObj[allChartKeys.MULTILINE],
  allChartTypesObj[allChartKeys.AREA],
  allChartTypesObj[allChartKeys.AREACOLOR],
  allChartTypesObj[allChartKeys.AREASTACK],
  allChartTypesObj[allChartKeys.BARHORIZONTAL],
  allChartTypesObj[allChartKeys.BARHORIZONTALCOLOR],
  allChartTypesObj[allChartKeys.BARSTACKHORIZONTAL],
  allChartTypesObj[allChartKeys.PIE],
  allChartTypesObj[allChartKeys.DONUT],
  allChartTypesObj[allChartKeys.BARGROUP],
  // allChartTypesObj[allChartKeys.BARSTACKLINE],
  allChartTypesObj[allChartKeys.CARD],
  allChartTypesObj[allChartKeys.FUNNEL],
  allChartTypesObj[allChartKeys.GEOINDIA],
  allChartTypesObj[allChartKeys.GEOUSA],
  allChartTypesObj[allChartKeys.GEOCANADA],
  allChartTypesObj[allChartKeys.GEOWORLD],
  allChartTypesObj[allChartKeys.GEOMAP],
  allChartTypesObj[allChartKeys.GEOMAPCOLOR],
  allChartTypesObj[allChartKeys.WORDCLOUD],
  allChartTypesObj[allChartKeys.BUBBLEPLOT],
  allChartTypesObj[allChartKeys.GROUPBUBBLEPLOT],
  allChartTypesObj[allChartKeys.SCATTERPLOT],
  allChartTypesObj[allChartKeys.GROUPSCATTERPLOT],
  allChartTypesObj[allChartKeys.KPIMETER],
  allChartTypesObj[allChartKeys.RADIALLINE],
  allChartTypesObj[allChartKeys.RADAR],
  allChartTypesObj[allChartKeys.TREEMAP],
  allChartTypesObj[allChartKeys.PIVOTTABLE],
  allChartTypesObj[allChartKeys.HEATMAP],
];
export const chartForSelection = [
  allChartTypesObj[allChartKeys.BAR],
  allChartTypesObj[allChartKeys.LINE],
  allChartTypesObj[allChartKeys.AREA],
  allChartTypesObj[allChartKeys.BARHORIZONTAL],
  allChartTypesObj[allChartKeys.PIE],
  allChartTypesObj[allChartKeys.DONUT],
  allChartTypesObj[allChartKeys.BARGROUP],
  allChartTypesObj[allChartKeys.CARD],
  allChartTypesObj[allChartKeys.FUNNEL],
  allChartTypesObj[allChartKeys.GEOINDIA],
  allChartTypesObj[allChartKeys.GEOUSA],
  // allChartTypesObj[allChartKeys.GEOCANADA],
  allChartTypesObj[allChartKeys.GEOWORLD],
  allChartTypesObj[allChartKeys.GEOMAP],
  allChartTypesObj[allChartKeys.WORDCLOUD],
  allChartTypesObj[allChartKeys.SCATTERPLOT],
  allChartTypesObj[allChartKeys.KPIMETER],
  // allChartTypesObj[allChartKeys.BARSTACKLINE],
  allChartTypesObj[allChartKeys.RADIALLINE],
  allChartTypesObj[allChartKeys.RADAR],
  allChartTypesObj[allChartKeys.TREEMAP],
  allChartTypesObj[allChartKeys.PIVOTTABLE],
  allChartTypesObj[allChartKeys.HEATMAP],
];
export const ColorfulColorChart = [
  allChartTypesObj[allChartKeys.PIE],
  allChartTypesObj[allChartKeys.DONUT],
  allChartTypesObj[allChartKeys.FUNNEL],
  allChartTypesObj[allChartKeys.KPIMETER],
  allChartTypesObj[allChartKeys.RADAR],
];
