import React from 'react';
// import KpiEditColors from '../../../components/SpacialCasesComponents/KpiMeaters/KpiEditColors';
import { mls } from 'lib/multilanguagesupport';
import SolidColorSelector from './StyleComponent/SolidColorSelector';
import FontSelector from './StyleComponent/FontSelector';
import KpiEditColors from './StyleComponent/KpiEditColors';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import InfoToolTip from 'lib/reusable-components/Components/ToolTip/InfoToolTip';
import { automaticCustomArray } from '../../data/defaultSchema';
import { KpiColorFunction } from '../../utils/colorFunction';

const KpiMeterStyleCase = ({ stateProps }) => {
  const { kpiTargetPoints, setKpiTargetPoints } = stateProps;

  const handlePostfix = (e) => {
    setKpiTargetPoints({
      ...kpiTargetPoints,
      title: { ...kpiTargetPoints.title, valuePostfix: e.target.value },
    });
  };
  const handlePrefix = (e) => {
    setKpiTargetPoints({
      ...kpiTargetPoints,
      title: { ...kpiTargetPoints.title, valuePrefix: e.target.value },
    });
  };
  const handleTitleStyle = (e) => {
    setKpiTargetPoints({ ...kpiTargetPoints, title: e });
  };
  const handleTargetFontStyle = (e) => {
    setKpiTargetPoints({ ...kpiTargetPoints, targetFontStyle: e });
  };
  const handleReverseTargetAchieve = (action) => {
    if (kpiTargetPoints.type === automaticCustomArray[0]) {
      const kpiColors = KpiColorFunction({
        colorLength: kpiTargetPoints.sectionColors.length,
        isReverseTarget: action,
      });
      const updatedAutomaticKpiColors = kpiTargetPoints.sectionColors.map(
        (sourceElem, sourceIndex) => {
          return { ...sourceElem, color: kpiColors[sourceIndex] };
        }
      );
      const newKpiTargetPoint = { ...kpiTargetPoints, sectionColors: updatedAutomaticKpiColors };
      setKpiTargetPoints({
        ...newKpiTargetPoint,
        reverseTargetAchieve: action,
      });
    } else {
      setKpiTargetPoints({
        ...kpiTargetPoints,
        reverseTargetAchieve: action,
      });
    }
  };
  return (
    <>
      {/* <KpiEditColors stateProps={stateProps} /> */}
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('Arc Point')}</div>

        <div className='grayContainers noOverflowGrayContainer'>
          <div className={`containerItems open`}>
            <FontSelector
              fontStyle={kpiTargetPoints.targetFontStyle}
              setFontStyle={handleTargetFontStyle}
            />
            <SolidColorSelector
              color={kpiTargetPoints.targetFontStyle}
              setColor={handleTargetFontStyle}
            />
          </div>
        </div>
      </div>
      <div className='outerDiv'>
        <div className='MainTitle inFlex'>
          <InfoToolTip
            data={{ title: '', description: mls('It is changing the Polarity of the colors.') }}
          >
            {mls('Reverse Target')}
          </InfoToolTip>
          <SlideSwitch
            isChecked={kpiTargetPoints.reverseTargetAchieve ?? false}
            setIsChecked={handleReverseTargetAchieve}
          />
        </div>
      </div>
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('Advance Option')}</div>

        <div className='grayContainers noOverflowGrayContainer'>
          <div className={`containerItems open`}>
            <div className='titleDiv'>{mls('Title Prefix')}</div>
            <input
              type='text'
              className='primaryInput'
              placeholder={mls('Title Prefix')}
              value={kpiTargetPoints.title.valuePrefix}
              onChange={handlePrefix}
            />
            <div className='titleDiv'>{mls('Title Postfix')}</div>
            <input
              type='text'
              className='primaryInput'
              placeholder={mls('Title Postfix')}
              value={kpiTargetPoints.title.valuePostfix}
              onChange={handlePostfix}
            />

            {/* <div className='titleDiv'>{mls('Kpi Start Point')}</div>
            <input
              type='number'
              className='primaryInput'
              placeholder={mls('Kpi Start Point')}
              value={kpiTargetPoints.kpiStartingPoint}
              onChange={handleKpiStartingPoint}
            /> */}
            <FontSelector fontStyle={kpiTargetPoints.title} setFontStyle={handleTitleStyle} />
            <SolidColorSelector color={kpiTargetPoints.title} setColor={handleTitleStyle} />
          </div>
        </div>
      </div>
    </>
  );
};

export default KpiMeterStyleCase;
