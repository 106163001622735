import {
  getConvertedTableData,
  getSheetID,
} from 'lib/reusable-components/DisplayTable/tableFunction';
import { defaultDashboardSchema } from '../data/defaultSchema';
import { updateReportPlotArea } from './dragableFunctions';

interface populateFunctionProps {
  stateProps: any;
  reportSchema: any;
  populateState: boolean;
  setPopulateState: any;
}

export const getTableDataInSheetID = async ({
  reports,
  appID,
  analyticsSchema,
  handleTableData,
}: {
  reports: any[];
  appID: string;
  analyticsSchema: { [key: string]: any };
  handleTableData: Function;
}) => {
  let tableDataInSheetID: { [key: string]: any } = {};
  const setTableDataInSheetID = (newTableData: { [key: string]: any }) => {
    tableDataInSheetID = { ...tableDataInSheetID, ...newTableData };
    return tableDataInSheetID;
  };
  for (let index = 0; index < reports.length; index++) {
    const reportElem = reports[index];
    if (reportElem?.sheetID) {
      const handleTableProps = {
        selectedTable:
          analyticsSchema?.appSheets?.[reportElem.sheetID]?.defaultTable?.tableID || '',
        appID: appID,
        sheetID: reportElem.sheetID,
        tableDataInSheetID: tableDataInSheetID,
        setTableDataInSheetID: setTableDataInSheetID,
      };
      const tableDataRes = await handleTableData(handleTableProps);
      if (tableDataRes.skip) {
        console.log('table not found');
      }
    }
  }
  return tableDataInSheetID;
};
export const populateFunction = (props: populateFunctionProps) => {
  const { stateProps, reportSchema, populateState } = props;
  if (!populateState) return;

  const CheckUpdateAndUpdateFunction = (setFunction: any, data: any, defaultData: any) => {
    if (data) {
      setFunction(data);
    } else {
      setFunction(defaultData);
    }
  };
  const {
    setReportName,
    setReportDescription,
    setSendTo,
    setScheduledTime,
    setPagesList,
    setReportFilter,
    setSelectedReportType,
  } = stateProps;

  const updateArray = [
    {
      setFunction: setReportName,
      data: reportSchema.name,
      defaultData: defaultDashboardSchema.reportName,
    },
    {
      setFunction: setReportDescription,
      data: reportSchema.desc,
      defaultData: defaultDashboardSchema.reportDesc,
    },
    {
      setFunction: setScheduledTime,
      data: reportSchema.scheduledTime,
      defaultData: defaultDashboardSchema.scheduledTime,
    },
    {
      setFunction: setPagesList,
      data: reportSchema.pages,
      defaultData: defaultDashboardSchema.pages,
    },
    {
      setFunction: setReportFilter,
      data: reportSchema.reportFilter,
      defaultData: defaultDashboardSchema.reportFilter,
    },
    {
      setFunction: setSendTo,
      data: reportSchema.recipients,
      defaultData: defaultDashboardSchema.recipients,
    },
    {
      setFunction: setSelectedReportType,
      data: reportSchema.reportType,
      defaultData: defaultDashboardSchema.reportType,
    },
  ];

  // populateState
  updateArray.forEach(({ setFunction, data, defaultData }) => {
    CheckUpdateAndUpdateFunction(setFunction, data, defaultData);
  });
  // setPopulateCount((currentCount: number) => currentCount + 1);
  // setPopulateState((preElem: boolean) => !preElem);
  return { skip: false };
};
interface tableHeadPopulateFunctionProps {
  stateProps: any;
  reportSchema: any;
  populateState: boolean;
  setPopulateState: Function;
  setIsPopulateComplete: Function;
}
export const tableHeadPopulateFunction = async (props: tableHeadPopulateFunctionProps) => {
  const { stateProps, reportSchema, populateState, setPopulateState, setIsPopulateComplete } =
    props;
  if (!populateState) return;

  const CheckUpdateAndUpdateFunction = (setFunction: any, data: any, defaultData: any) => {
    if (data) {
      setFunction(data);
    } else {
      setFunction(defaultData);
    }
  };

  const {
    setPopulateCount,
    appID,
    setReportAreaLoading,
    allTableHeadData,
    projectSheets,
    setTableHeadLoading,
    setAllTableHeadData,
    pagesList,
  } = stateProps;

  const getTableData = async ({ tableID }: { tableID: string }) => {
    if (allTableHeadData[tableID as keyof typeof allTableHeadData]) {
      return { skip: true };
    }
    const sheetData: {
      sheetID: string;
      skip: boolean;
      newCreatedSheetID?: boolean;
    } = await getSheetID({
      appSheets: projectSheets,
      selectedTable: tableID,
      appID,
    });
    if (sheetData?.skip) {
      return { skip: true };
    }
    const tableDataProps = {
      appID,
      tableID: tableID,
      sheetID: sheetData.sheetID,
      disableCustomColumns: false,
    };

    const tableDataRes = await getConvertedTableData({
      appID: tableDataProps.appID,
      selectedTable: tableDataProps.tableID,
      sheetID: tableDataProps.sheetID,
      disableCustomColumns: tableDataProps.disableCustomColumns,
    });
    if (tableDataRes.skip) {
      return { skip: true };
    }

    return {
      tableData: tableDataRes.groupedTableData,
      skip: false,
      tableSchema: tableDataRes.data.tableSchema,
    };
  };
  const getAllTableData = async ({ tables = [] }: { tables: any[] }) => {
    setTableHeadLoading(true);

    let newAllTableData: { [key: string]: any } = {};
    for (let index = 0; index < tables.length; index++) {
      const tableID = tables[index];
      const tableDataRes = await getTableData({ tableID: tableID });

      if (!tableDataRes.skip) {
        newAllTableData = {
          ...newAllTableData,
          [tableID]: { tableData: tableDataRes.tableData, tableSchema: tableDataRes.tableSchema },
        };
      }
    }
    setTableHeadLoading(false);
    return newAllTableData;
  };
  const getAllTableFromReport = async ({ pages }: { pages: any }) => {
    let tableIDList: string[] = [];
    pages.forEach((pagesElem: { tableID: string }) => {
      tableIDList = [...tableIDList, pagesElem.tableID];
    });
    const tableHeads = await getAllTableData({ tables: tableIDList });
    return { skip: false, tableHeads: tableHeads };
  };
  const allTableData = await getAllTableFromReport({ pages: reportSchema.pages });

  const updateArray = [
    {
      setFunction: setAllTableHeadData,
      data: allTableData.skip ? defaultDashboardSchema.allTableHeadData : allTableData.tableHeads,
      defaultData: defaultDashboardSchema.allTableHeadData,
    },
  ];

  // populateState
  updateArray.forEach(({ setFunction, data, defaultData }) => {
    CheckUpdateAndUpdateFunction(setFunction, data, defaultData);
  });
  setPopulateCount((currentCount: number) => currentCount + 1);
  setPopulateState((preElem: boolean) => !preElem);
  setReportAreaLoading((preElem: boolean) => !preElem);
  setIsPopulateComplete((preElem: boolean) => !preElem);
};
