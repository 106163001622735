import { dataTableColumnTypes } from 'lib/data-connectors/dependencies/constant';
import { mls } from 'lib/multilanguagesupport';
import React, { useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap-v5';
import { DeleteTable } from '../../modal/add-database/DeleteTable';
import { _supistaAnalyticsApi } from 'lib/server-connection/connections';
import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { numberToChar } from 'lib/data-connectors/dependencies/utilFunctions';
import { v4 as uuid } from 'uuid';
import CopyColumnToClipBoard, {
  CopyToClipBoard,
} from 'lib/data-connectors/dependencies/CopyColumnToClipBoard/CopyColumnToClipBoard';
import { toast } from 'react-toastify';
import ConfirmEditModal from '../ConfirmEditModal';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import clsx from 'clsx';
import { IconButton } from '@mui/material';
import { RiTableFill } from 'react-icons/ri';
import { tableUpdateWarning } from 'lib/data-connectors/data/dataContainersData';
import { FaTimes } from 'react-icons/fa';
import {
  getReportDashIDsTBD,
  getSheetChartsIDs,
  getSheetChartsIDsTBD,
  getTableIDSTBD,
} from 'lib/reusable-components/reusableFunction/deleteDataDependencies';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import useAddNewSheets from 'lib/data-connectors/screens/data-source/components/useAddNewSheets';
import DataLakeSearch from 'lib/data-connectors/dependencies/DataLakeSearch/DataLakeSearch';
const dateTimeTypes = [
  '__d3__CreatedAtDate',
  '__d3__CreatedAtTime',
  '__d3__UpdatedAtDate',
  '__d3__UpdatedAtTime',
  'date',
  'datePicker',
  'dateRangePicker',
  'dateTimePicker',
  'timePicker',
];

const removeSysVariable = (str: string) => {
  const spl = str.split('.');
  const last = spl.pop();
  if (last === '__d3__updatedAt') return spl.join('.') + '.Last Update Date';
  else if (last === '__d3__createdAt') return spl.join('.') + '.Create Date';
  else return spl.join('.') + '.' + last;
};

const getDateTimeCols = (tableSchema: any, dbType: any, tableName: any, isJoined: any) => {
  let columns = tableSchema?.columnSchema || [];
  if (isJoined) {
    columns = columns.map((col: any) => ({ ...col, tableName: tableSchema.primaryTable }));
    (tableSchema.joinTableSchema || []).map((sch: any) => {
      const joinCols = (sch.columnSchema || []).map((schCol: any) => ({
        ...schCol,
        tableName: sch.joinTableName,
      }));
      columns = [...columns, ...joinCols];
      return null;
    });
  } else {
    columns = columns.map((col: any) => ({ ...col, tableName: tableName }));
  }

  if (columns.length === 0) return [];
  const DTCols = columns.filter((col: any) => dateTimeTypes.includes(col.dataType));
  if (dbType === '__d3__supista' && isJoined !== true) {
    const createData = {
      name: 'Create Date',
      dataType: 'DATETIME',
      tableName: tableName,
      columnName: '__d3__createdAt',
    };
    const updateData = {
      name: 'Last Update Date',
      dataType: 'DATETIME',
      columnName: '__d3__updatedAt',
      tableName: tableName,
    };
    DTCols.push(createData, updateData);
  }

  const colNames = DTCols.map((obj: any) => obj.tableName + '.' + obj.columnName);

  return { DTCols, colNames };
};

interface editTableListProps {
  appID: string;
  closeModal: Function;
  appCustomDatatable: { [key: string]: any };
  appDatatable: { [key: string]: any };
  setTableSchema: Function;
  useUpdateSchema: Function;
  tableSchema: any;
  setTableHeadDisplayTableData: Function;
  setAllTableHeadData: Function;
  isAlreadyTable: boolean;
  setIsAlreadyTable: Function;
  setTableHeadLoading: Function;
  getTableData: Function;
  setUpdateSqlCustomCommands: Function;
  setIsAnyChanges: Function;
  isNew: boolean;
  setIsNew: Function;
}
const EditTableList = ({
  appID,
  closeModal,
  appCustomDatatable,
  appDatatable,
  setTableSchema,
  tableSchema,
  isAlreadyTable,
  setIsAlreadyTable,
  useUpdateSchema,
  setTableHeadDisplayTableData,
  setAllTableHeadData,
  setTableHeadLoading,
  getTableData,
  setUpdateSqlCustomCommands,
  setIsAnyChanges,
  isNew,
  setIsNew,
}: editTableListProps) => {
  const updateTableSchema = useUpdateSchema();
  const defaultCustomTableData = {
    name: '',
    description: '',
    tables: [],
    columnSchema: [],
    tableType: 'CUSTOM_TABLE',
    _id: appID,
    appID,
  };

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isAddTableOpen, setIsAddTableOpen] = useState(!isAlreadyTable);
  const [submitting, setSubmitting] = useState(false);
  const addNewSheets: Function = useAddNewSheets();

  const dbType = tableSchema?.dbType || '__d3__supista';
  const tableID = tableSchema?.tableID || uuid();
  const handleTableSchema = (key: string, value: any) => {
    setTableSchema({ ...tableSchema, [key]: value });
    setIsAnyChanges(true);
  };
  const outerContainerRef = useRef<HTMLDivElement>(null);

  const datetime: any = getDateTimeCols(tableSchema, dbType, tableID, false);

  const datetimeCols = datetime?.colNames || [];

  // map of tableID and its name to display in the frontend.
  const allTableIDAndNamesMap: { [key: string]: any } = {};
  Object.keys(appCustomDatatable || {}).forEach((tableID) => {
    allTableIDAndNamesMap[tableID] = appCustomDatatable[tableID].name;
  });

  const applyTableName = (columnName: string) => {
    const columnNameArray = columnName.split('.');
    const last = columnNameArray.pop();
    const tableName = columnNameArray.join('.');
    return (allTableIDAndNamesMap[tableName] || tableName) + '.' + last;
  };
  const handleDataTypeChange = (index: number, newType: any) => {
    toast.warn(mls(tableUpdateWarning));
    setTableSchema((old: { [key: string]: any }) => {
      const newColumnSchema: { [key: string]: any }[] = Array.from(old.columnSchema);
      const [selectedItem] = newColumnSchema.splice(index, 1);
      const newSelectedItem = { ...selectedItem, dataType: newType };
      newColumnSchema.splice(index, 0, newSelectedItem);
      return {
        ...old,
        columnSchema: newColumnSchema,
      };
    });
    setIsAnyChanges(true);
  };

  const handleSave = async () => {
    if (submitting) return;
    setSubmitting(true);
    if (!tableSchema.name) {
      toast.error(mls('Please Enter Table name'), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setSubmitting(false);
      return;
    }
    if (tableSchema.tables?.length <= 0) {
      toast.error(mls('Please Select Table to create custom Table'), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setSubmitting(false);
      return;
    }

    const saveSqlColumn = 'saveSqlTable';
    const finalEndPoint = `${appID}/customType/${saveSqlColumn}/customSql`;
    const currentTimestamp = Date.now();
    const tableID = tableSchema?.tableID || uuid();

    const getSheetIDList = (tableID: string) => {
      const { sheetIDsTBD } = getSheetChartsIDsTBD([tableID], [], sheetsSchema);
      return sheetIDsTBD ?? [];
    };

    const saveData = {
      appID: defaultCustomTableData.appID,
      tableID: tableID,
      tableList: tableSchema.tables,
      sqlQuery: tableSchema.sqlQuery,
      isNew: isNew,
      sheetIDLists: isNew ? [] : getSheetIDList(tableID),
      tableSchema: {
        ...tableSchema,
        tableID: tableID,
        datetimeCols,
        createdAt: tableSchema.createdAt ?? currentTimestamp,
        updatedAt: currentTimestamp,
      },
    };
    const responseData = await _supistaAnalyticsApi(finalEndPoint, saveData);
    if (responseData.__d3__error) {
      toast.error(mls(responseData?.response?.errorMsg ?? 'Unable to save'), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      if (isNew) {
        const defaultDatetimeColumnName = tableSchema?.defaultDatetimeCol ?? '';
        const tableName = tableSchema?.name;
        // adding sheet for the joined Table
        const defaultTable = {
          appID,
          tableID,
          name: tableName,
          tableType: tableSchema?.tableType,
          tables: tableSchema?.tables,
          datetimeCols,
          defaultDatetimeCol: defaultDatetimeColumnName,
          _id: appID,
        };
        await addNewSheets({
          appID,
          tableName: tableName,
          defaultDatetimeColumnName,
          successMesage: mls(
            'Charts Auto generated by Supista AI and added to the Sheet successfully!'
          ),
          defaultTable,
        });
        setIsNew(false);
      }
      publishToProduction(appID);
      toast.success(mls('Saved successfully'), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      updateTableSchema(false, null, saveData.tableSchema, saveData.tableSchema.tableID);
    }
    setIsAnyChanges(false);
    setSubmitting(false);
  };
  const handleEditDone = async () => {
    if (!tableSchema.name) {
      toast.error(mls('Please Enter Table name'), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }
    if (tableSchema.tables?.length <= 0) {
      toast.error(mls('Please Select Table to create custom Table'), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }
    setTableSchema((currentValue: any) => ({ ...currentValue, tableID }));
    setIsAlreadyTable((currentValue: boolean) => !currentValue);
    setIsAnyChanges(true);
  };

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const sheetsSchema = analyticsSchema?.appSheets || {};
  const reportSchema = analyticsSchema.appReports || {};
  const dashSchema = analyticsSchema.appDash || {};
  const customTableSchema = analyticsSchema.appCustomtable || {};
  const joinTableSchema = analyticsSchema.appJointable || {};

  const tablesObject = useMemo(() => {
    interface getGroupTableDataProps {
      analyticsSchema: { [key: string]: any };
      tableType: { [key: string]: any };
    }
    const getGroupTableData = ({ analyticsSchema, tableType }: getGroupTableDataProps) => {
      let tableInGroup = {};
      Object.keys(tableType).forEach((tableTypeID) => {
        if (analyticsSchema?.[tableTypeID]) {
          tableInGroup = {
            ...tableInGroup,
            [tableTypeID]: {
              groupKey: tableTypeID,
              title: tableType[tableTypeID],
              options: analyticsSchema?.[tableTypeID],
            },
          };
        }
      });
      return tableInGroup;
    };
    const tableType = {
      appJointable: 'Join Table',
      appCustomtable: 'Custom Table',
      appDatatable: 'Data Table',
    };
    const finalGroupTable = getGroupTableData({ analyticsSchema, tableType });
    return finalGroupTable;
  }, [analyticsSchema]);
  const deleteTableData = async ({ finalTable }: { finalTable: any }) => {
    const deleteRes = await _supistaAnalyticsApi(
      `${appID}/customType/deleteCustomTable/customSql`,
      finalTable
    )
      .then(async (response) => {
        return { skip: false, msg: response };
      })
      .catch((error) => {
        toast.error(
          mls(
            "Some issues encountered. Couldn't able to Delete Custom Table. Please try again later."
          )
        );
        return { skip: true, msg: error };
      });

    return deleteRes;
  };
  const handleDeleteTable = async () => {
    if (submitting) return;
    setSubmitting(true);
    const { sheetIDsTBD, chartIDSTBD } = getSheetChartsIDs(tableSchema.tableID, sheetsSchema);
    const { reportIDsTBD, dashIDsTBD } = getReportDashIDsTBD({
      chartIDs: chartIDSTBD ?? [],
      dashSchema: dashSchema,
      reportSchema: reportSchema,
    });

    const { customTableIDsTBD } = getTableIDSTBD({
      tableIDs: [tableSchema.tableID],
      joinTableSchema: joinTableSchema,
      customTableSchema: customTableSchema,
    });
    const sendToBackend = {
      appID: tableSchema.appID,
      name: tableSchema.name,
      tableID: tableSchema.tableID,
      joinTableIDList: undefined,
      customTableIDList: customTableIDsTBD,
      sheetIDList: sheetIDsTBD,
      dashIDObj: dashIDsTBD,
      reportIDObj: reportIDsTBD,
    };
    const deleteDataRes = await deleteTableData({ finalTable: sendToBackend });
    if (deleteDataRes?.skip || deleteDataRes.msg.__d3__error) {
      toast.error(
        mls(
          deleteDataRes?.msg?.response?.errorMsg ??
            "Some issues encountered. Couldn't able to Delete Custom Table. Please try again later."
        )
      );
      setSubmitting(false);
      return;
    }
    toast.success(mls(deleteDataRes?.msg?.response?.errorMsg ?? 'Table Deleted Successfully'));
    updateTableSchema(true, tableSchema.tableID);
    publishToProduction(appID);
    setDeleteOpen(false);
    setSubmitting(false);
    closeModal();
  };

  const handleColEdit = async ({
    tableID,
    index,
    action,
  }: {
    tableID: string;
    index: number;
    action: string;
  }) => {
    if (action === ColAction.add) {
      setTableHeadLoading(true);
      const newArray = [...tableSchema.tables, tableID];
      handleTableSchema('tables', newArray);
      setIsAddTableOpen(false);
      const tableDataRes = await getTableData({ tableID: tableID });
      if (!tableDataRes.skip) {
        setAllTableHeadData((currentTableData: any) => ({
          ...currentTableData,
          [tableID]: tableDataRes.tableData,
        }));
      }
      setTableHeadLoading(false);
    }
    if (action === ColAction.delete) {
      const newArray = tableSchema.tables.filter((tablesElem: string) => tablesElem !== tableID);
      handleTableSchema('tables', newArray);
    }
    if (action === ColAction.edit) {
      setTableHeadLoading(true);
      const newArray = Array.from(tableSchema.tables ?? []);
      newArray.splice(index, 1); // removedFromindex
      newArray.splice(index, 0, tableID); // added new tableID in Array
      handleTableSchema('tables', newArray);
      const tableDataRes = await getTableData({ tableID: tableID });
      if (!tableDataRes.skip) {
        setAllTableHeadData((currentTableData: any) => ({
          ...currentTableData,
          [tableID]: tableDataRes.tableData,
        }));
      }
      setTableHeadLoading(false);
    }
    setIsAnyChanges(true);
  };
  const clearCustomColumn = () => {
    const currentTimestamp = Date.now();
    const finalTable = {
      ...tableSchema,
      sqlQuery: '',
      columnSchema: [],
      updatedAt: currentTimestamp,
    };
    setUpdateSqlCustomCommands((currentValue: number) => currentValue + 1);
    setTableSchema(finalTable);
    setIsAnyChanges(true);
  };
  return (
    <>
      <div className='editTableList'>
        <div className='modal-body pt-0 pb-0 px-xl-20 editTableTitle'>
          <div className='text-center'>
            <h1 className='mb-3'>{mls('Table Settings')}</h1>
            <div className='text-muted fw-bold fs-5'>{mls('Edit Table settings here')}.</div>
          </div>
        </div>
        <div className='edittableName'>
          <label className='form-label mt-5'>{mls('Table name')}:</label>
          <input
            type='text'
            className='form-control form-control-solid'
            placeholder={mls('Enter table name')}
            value={tableSchema?.name || ''}
            onChange={(e) => {
              handleTableSchema('name', e.target.value);
            }}
          />
        </div>
        <div className='editTableOptions' ref={outerContainerRef}>
          <label className='form-label '>{mls('Description')}:</label>
          <textarea
            className='form-control form-control-solid'
            style={{ resize: 'none', height: 80 }}
            placeholder={mls('Table description')}
            value={tableSchema?.description || ''}
            maxLength={100}
            onChange={(e) => {
              handleTableSchema('description', e.target.value);
            }}
          />
          <div className={`p-5 mt-5 border rounded`}>
            <label className='form-label '>{mls('Tables')}:</label>
            {React.Children.toArray(
              (tableSchema?.tables ?? []).map((selectedTables: string, index: number) => {
                return (
                  <AddTables
                    appDatatable={appDatatable}
                    index={index}
                    tableID={selectedTables}
                    outerContainerRef={outerContainerRef}
                    tablesArray={tableSchema.tables ?? []}
                    handleColEdit={handleColEdit}
                    tablesObject={tablesObject}
                    setIsAddTableOpen={setIsAddTableOpen}
                  />
                );
              })
            )}
            {isAddTableOpen ? (
              <AddTables
                appDatatable={appDatatable}
                index={(tableSchema?.tables ?? []).length}
                tablesArray={tableSchema.tables ?? []}
                outerContainerRef={outerContainerRef}
                tableID={''}
                handleColEdit={handleColEdit}
                isNew={true}
                setIsAddTableOpen={setIsAddTableOpen}
                tablesObject={tablesObject}
              />
            ) : (
              <div className='text-center w-100 mt-5'>
                <i
                  className='fas fa-plus fa-lg'
                  style={{
                    cursor: 'pointer',
                    border: '1px solid grey',
                    padding: 8,
                    borderRadius: '50%',
                  }}
                  onClick={() => {
                    setIsAddTableOpen(true);
                  }}
                />
              </div>
            )}
          </div>
          {(datetimeCols || []).length > 0 && (
            <>
              <br />
              <label className='form-label'>{mls('Default Datetime Column')}:</label>
              <Form.Select
                aria-label='Default Datetime Column'
                className='form-control form-control-solid'
                value={tableSchema?.defaultDatetimeCol || ''}
                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
                  handleTableSchema('defaultDatetimeCol', evt.target.value)
                }
              >
                <option value=''>None</option>
                {(datetimeCols || []).map((ele: any) => (
                  <option key={ele} value={ele}>
                    {applyTableName(removeSysVariable(ele))}
                  </option>
                ))}
              </Form.Select>
            </>
          )}
          {tableSchema?.columnSchema && tableSchema?.columnSchema.length >= 1 ? (
            <div className='p-5 mt-5 border rounded CustomColumnDiv'>
              <br />
              <div style={{ display: 'flex', gap: '1rem' }}>
                <div style={{ width: 'calc( 100% - 4rem)' }}>
                  <label className='form-label  fw-bolder fs-3 '>{mls('Columns')}</label>
                </div>
                <label className='btn btn-sm btn-hover-danger' onClick={() => clearCustomColumn()}>
                  Clear
                </label>
              </div>
              <div className='w-90 mx-auto'>
                <div className='row mt-5'>
                  <label className='col-5 fw-bold'>{mls('Column Name')}</label>
                  <label className='col-7 fw-bold'>{mls('Column Type')}</label>
                </div>
                <hr />
                {tableSchema?.columnSchema?.map((col: any, index: number) => {
                  return (
                    <div className='row my-5' key={index}>
                      <div className='col-5 d-flex align-items-center justify-content-left'>
                        <CopyToClipBoard text={`"${col.columnName}"`}>
                          {col.columnName}
                        </CopyToClipBoard>
                      </div>
                      <div className='col-7 d-flex align-items-center justify-content-left'>
                        <select
                          className='form-select form-control form-control-solid mb-1 mx-auto'
                          value={col.dataType || 'text'}
                          onChange={(e) => {
                            handleDataTypeChange(index, e.target.value);
                          }}
                        >
                          {Object.entries(dataTableColumnTypes).map((type) => {
                            return <option value={type[0]}>{type[1]}</option>;
                          })}
                        </select>
                      </div>
                      <div className='col-2 d-flex align-items-center justify-content-left'></div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          {tableSchema?.tables && tableSchema?.tables.length >= 1
            ? React.Children.toArray(
                (tableSchema?.tables ?? []).map((selectedTables: string, index: number) => {
                  const tableSchema = appDatatable[selectedTables] ??
                    customTableSchema[selectedTables] ??
                    joinTableSchema[selectedTables] ?? {
                      columnSchema: [],
                      name: '',
                      tableID: '',
                    };
                  if (tableSchema.tableType === 'JOINED_TABLE') {
                    return (
                      <>
                        <ShowJoinTable
                          columnSchema={tableSchema?.columnSchema ?? []}
                          joinTableSchema={tableSchema?.joinTableSchema ?? []}
                          tableIndex={index}
                          setTableHeadDisplayTableData={setTableHeadDisplayTableData}
                          tableID={tableSchema.tableID}
                          mainTitle={tableSchema?.name ?? tableSchema.tableID}
                        />
                      </>
                    );
                  }
                  return (
                    <>
                      <ShowTable
                        columnSchema={tableSchema.columnSchema}
                        tableIndex={index}
                        setTableHeadDisplayTableData={setTableHeadDisplayTableData}
                        tableID={tableSchema.tableID}
                        mainTitle={tableSchema?.name ?? tableSchema.tableID}
                      />
                    </>
                  );
                })
              )
            : null}
        </div>
        <div className='w-100 d-flex justify-content-end bg-white editTableButtons'>
          {isAlreadyTable ? (
            <button
              name='Delete Table'
              className={'btn btn-sm btn-hover-danger'}
              onClick={() => setDeleteOpen(true)}
            >
              {mls('Delete Table')}
            </button>
          ) : null}
          {isAlreadyTable ? (
            <button
              name='Save Table'
              className={'btn btn-sm ms-5 btn-primary'}
              onClick={handleSave}
            >
              <span className='loader-container'>
                {mls('Save Table')}
                {submitting && <span className='loader' />}
              </span>
            </button>
          ) : (
            <button
              name='Done'
              className={'btn btn-sm btn-primary'}
              onClick={() => handleEditDone()}
            >
              {mls('Done')}
            </button>
          )}
        </div>
      </div>
      {deleteOpen && (
        <DeleteTable
          appID={appID}
          setDeleteOpen={setDeleteOpen}
          deleteOpen={deleteOpen}
          handleDeleteTable={handleDeleteTable}
          tableID={tableID}
        />
      )}
    </>
  );
};

export default EditTableList;

interface AddTablesProps {
  appDatatable: { [key: string]: any };
  tablesObject: { [key: string]: any };
  tableID: string;
  handleColEdit: Function;
  setIsAddTableOpen: Function;
  index: number;
  tablesArray: string[];
  isNew?: boolean;
  outerContainerRef: React.RefObject<HTMLDivElement>;
}
const ColAction = {
  delete: 'delete',
  add: 'add',
  edit: 'edit',
};
const AddTables = ({
  appDatatable,
  tablesArray,
  tableID,
  handleColEdit,
  index,
  isNew = false,
  outerContainerRef,
  setIsAddTableOpen,
  tablesObject,
}: AddTablesProps) => {
  const handleSelecTableID = (tableID: string) => {
    if (isNew) {
      const tableAlreadyExist = tablesArray.some((tablesElem: string) => tablesElem === tableID);
      if (tableAlreadyExist) {
        toast.error(mls('Table Already Exist'));
        return { isError: true, defaultValue: tableID };
      }
      handleColEdit({ tableID: tableID, index, action: ColAction.add });
    } else {
      const newArray = Array.from(tablesArray ?? []);
      newArray.splice(index, 1); // removedFromindex
      const tableAlreadyExist = newArray.some((tablesElem: string) => tablesElem === tableID);
      if (tableAlreadyExist) {
        toast.error(mls('Table Already Exist'));
        return { isError: true, defaultValue: tableID };
      }
      handleColEdit({ tableID: tableID, index, action: ColAction.edit });
    }
  };
  const handleRemove = () => {
    if (!tableID) {
      setIsAddTableOpen(false);
      return;
    }
    handleColEdit({ tableID: tableID, index, action: ColAction.delete });
  };

  return (
    <div className={`p-2 mt-5 border rounded `} style={{ display: 'flex', gap: '.5rem' }}>
      <ObjectDropDown
        object={tablesObject}
        outerContainerRef={outerContainerRef}
        dropDownValue={tableID}
        inputPlaceHolder={mls('Select table to add')}
        alternateName={'tableID'}
        className={'dataConnectorsDropDown'}
        mainKey={'tableID'}
        setdropDownValue={handleSelecTableID}
        ObjectInObject
        search
        groupDropDown
      />

      <button
        name='Remove'
        className={'primaryButtonSmall btn-hover-danger'}
        onClick={() => handleRemove()}
      >
        <FaTimes />
      </button>
    </div>
  );
};

interface showJoinTableProps {
  columnSchema: any[];
  joinTableSchema: any[];
  tableIndex?: number;
  mainTitle?: string;
  subTitle?: string;
  className?: string;
  tableID: string;
  setTableHeadDisplayTableData: Function;
}
interface showTableProps {
  columnSchema: any[];
  tableIndex?: number;
  mainTitle?: string;
  subTitle?: string;
  className?: string;
  tableID: string;
  setTableHeadDisplayTableData: Function;
}

const ShowJoinTable = ({
  columnSchema,
  joinTableSchema,
  tableIndex = 0,
  mainTitle = '',
  subTitle = '',
  className = '',
  tableID,
  setTableHeadDisplayTableData,
}: showJoinTableProps) => {
  const finalColumnSchema = useMemo(() => {
    const joinTableColumns = joinTableSchema.map((elem) => elem?.columnSchema ?? []);
    const flatArray = joinTableColumns.flat();

    return [...columnSchema, ...flatArray];
  }, [columnSchema, joinTableSchema]);

  const [filteredcolumnSchema, setFilteredColumnSchema] = useState(finalColumnSchema);
  const setSearch = (filteredObject: any) => {
    const finalObject = [...Object.values(filteredObject)];
    setFilteredColumnSchema(finalObject);
  };
  return (
    <div className={`p-5 mt-5 border rounded ${className}`}>
      {mainTitle ? (
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ width: 'calc( 100% - 4rem)' }}>
            <CopyToClipBoard text={`__D3__TABLE__${numberToChar({ number: tableIndex })}`}>
              <label className='form-label  fw-bolder fs-3 '>{mls(mainTitle)}</label>
              &nbsp;
              <label className='form-label'>
                __D3__TABLE__{numberToChar({ number: tableIndex })}
              </label>
            </CopyToClipBoard>
          </div>
          <div style={{}}>
            <IconButton
              className='openTableButton'
              onClick={() => setTableHeadDisplayTableData({ status: true, tableID })}
            >
              <RiTableFill />
            </IconButton>
          </div>
        </div>
      ) : null}
      {subTitle ? (
        <input
          placeholder={mls('Enter Join table name')}
          value={subTitle}
          className={clsx('form-control form-control-solid')}
          disabled
        />
      ) : null}
      <br />
      <DataLakeSearch objectForSearch={finalColumnSchema ?? []} setSearch={setSearch} fullWidth />
      <div className='w-90 mx-auto'>
        <div className='row mt-5'>
          <label className='col-5 fw-bold'>{mls('Column Name')}</label>
          <label className='col-7 fw-bold'>{mls('Column Type')}</label>
        </div>
        <hr />
        <div style={{ ...customColumnBoxStyle, overflowX: 'hidden' }}>
          {(filteredcolumnSchema || []).map((tableCol: any, ix: number) => {
            return (
              <div className='row my-5' key={ix}>
                <div className='col-5 d-flex align-items-center justify-content-left'>
                  <CopyToClipBoard text={`"${tableCol.columnName}"`}>
                    {tableCol?.name ?? tableCol?.columnName}
                  </CopyToClipBoard>
                </div>
                <div className='col-7 d-flex align-items-center justify-content-left'>
                  <input
                    placeholder={mls('Enter Join table name')}
                    value={tableCol.dataType}
                    className={clsx('form-control form-control-solid')}
                    disabled
                  />
                </div>
                <div className='col-2 d-flex align-items-center justify-content-left'></div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Display warning if no column is selected from this table */}
      {finalColumnSchema?.length === 0 && (
        <div className='alert alert-warning mt-2 text-center' role='alert'>
          {mls('No column is selected from this table!')}
        </div>
      )}
    </div>
  );
};
const ShowTable = ({
  columnSchema,
  tableIndex = 0,
  mainTitle = '',
  subTitle = '',
  className = '',
  tableID,
  setTableHeadDisplayTableData,
}: showTableProps) => {
  const [filteredcolumnSchema, setFilteredColumnSchema] = useState(columnSchema);
  const setSearch = (filteredObject: any) => {
    const finalObject = [...Object.values(filteredObject)];
    setFilteredColumnSchema(finalObject);
  };
  return (
    <div className={`p-5 mt-5 border rounded ${className}`}>
      {mainTitle ? (
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ width: 'calc( 100% - 4rem)' }}>
            <CopyToClipBoard text={`__D3__TABLE__${numberToChar({ number: tableIndex })}`}>
              <label className='form-label  fw-bolder fs-3 '>{mls(mainTitle)}</label>
              &nbsp;
              <label className='form-label'>
                __D3__TABLE__{numberToChar({ number: tableIndex })}
              </label>
            </CopyToClipBoard>
          </div>
          <div style={{}}>
            <IconButton
              className='openTableButton'
              onClick={() => setTableHeadDisplayTableData({ status: true, tableID })}
            >
              <RiTableFill />
            </IconButton>
          </div>
        </div>
      ) : null}
      {subTitle ? (
        <input
          placeholder={mls('Enter Join table name')}
          value={subTitle}
          className={clsx('form-control form-control-solid')}
          disabled
        />
      ) : null}
      <br />
      <DataLakeSearch objectForSearch={columnSchema ?? []} setSearch={setSearch} fullWidth />
      <div className='w-90 mx-auto'>
        <div className='row mt-5'>
          <label className='col-5 fw-bold'>{mls('Column Name')}</label>
          <label className='col-7 fw-bold'>{mls('Column Type')}</label>
        </div>
        <hr />
        <div style={{ ...customColumnBoxStyle, overflowX: 'hidden' }}>
          {(filteredcolumnSchema || []).map((tableCol: any, ix: number) => {
            return (
              <div className='row my-5' key={ix}>
                <div className='col-5 d-flex align-items-center justify-content-left'>
                  <CopyToClipBoard text={`"${tableCol.columnName}"`}>
                    {tableCol?.name ?? tableCol?.columnName}
                  </CopyToClipBoard>
                </div>
                <div className='col-7 d-flex align-items-center justify-content-left'>
                  <input
                    placeholder={mls('Enter Join table name')}
                    value={tableCol.dataType}
                    className={clsx('form-control form-control-solid')}
                    disabled
                  />
                </div>
                <div className='col-2 d-flex align-items-center justify-content-left'></div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Display warning if no column is selected from this table */}
      {columnSchema?.length === 0 && (
        <div className='alert alert-warning mt-2 text-center' role='alert'>
          {mls('No column is selected from this table!')}
        </div>
      )}
    </div>
  );
};
const customColumnBoxStyle = {
  maxHeight: '60vh',
  overflow: 'auto',
};
