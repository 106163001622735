import React, { useMemo, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { MdAddChart } from 'react-icons/md';
import PinningOptions from '../PinningOptions/PinningOptions';
import { KTSVG } from 'lib/visx-lib/_metronic/helpers';
import ObjectDropDown from '../../features/NewDropDown/ObjectDropDown';
import { BiTime } from 'react-icons/bi';
import { defaultTimeframe } from '../../data/defaultChartSchema';
import { RiLightbulbFill } from 'react-icons/ri';
import { convertToTimeFrameObject } from '../../utils/chartHelperFunction';
import { getPlotData } from '../../utils/chatBotHelperFunction';
import { checkInsightsCapableChart } from 'lib/reusable-components/reusableFunction/chartBasicFuntions';
import { mls } from 'lib/multilanguagesupport';

interface chartOptionsProps {
  chartSettingsButton: Function;
  filterCount: number;
  width: number;
  handleTopFilter: Function;
  handlePin: Function;
  isInsight: boolean;
  insightRef: any;

  appDash: any;
  selectedDashboard: any;
  isPiningOptionOpen: any;
  setisPiningOptionOpen: any;
  handleDashBoard: any;
  chartSchema: any;
  stateProps: any;
  tableSchema: any;
  defaultDateTimeCol: string | null;
  chartTimeFrame: any;
  message: any;
  setMessages: Function;
  appID: string;
  mobileMode: boolean;
}
const ChartOptions = ({
  chartSettingsButton,
  filterCount,
  handleTopFilter,
  handlePin,
  width,
  isInsight,
  insightRef,
  mobileMode,
  appDash,
  selectedDashboard,
  isPiningOptionOpen,
  setisPiningOptionOpen,
  handleDashBoard,
  defaultDateTimeCol,
  chartSchema,
  stateProps,
  tableSchema,
  chartTimeFrame,
  message,
  setMessages,
  appID,
}: chartOptionsProps) => {
  const { isPinningAllowed } = stateProps;

  const [timeFrame, setTimeFrame] = useState(() => {
    if (chartTimeFrame?.[defaultDateTimeCol as keyof typeof chartTimeFrame]) {
      return `${chartTimeFrame?.[defaultDateTimeCol as keyof typeof chartTimeFrame]?.value?.xDay}`;
    }
    return 'null';
  });

  const checkForInsight = useMemo(() => {
    const isInsightCapable = checkInsightsCapableChart({ chartKey: chartSchema.chartType });
    if (isInsightCapable) {
      return isInsight;
    }
    return false;
  }, [isInsight]);

  const handleInsight = () => {
    insightRef?.current?.openInsight();
  };
  const handleTimeFrame = async (action: any) => {
    if (defaultDateTimeCol === null) {
      return;
    }

    const timeFrameObjectForConvert = {
      defaultDateTimeCol: defaultDateTimeCol,
      action: action,
      tableData: tableSchema?.data,
    };
    const chartName = chartSchema?.name ?? '';
    const timeFrameName = defaultTimeframe[action as keyof typeof defaultTimeframe]?.name;
    const timeFrameMessage = `${mls('Apply')} ${timeFrameName} ${mls(
      'Timeframe on'
    )} ${chartName}.`;
    const timeFrameFinalMessage = { message: timeFrameMessage, sender: 'user' };

    setMessages((currentMessages: any[]) => [...currentMessages, timeFrameFinalMessage]);

    const convertedTimeFrame = convertToTimeFrameObject(timeFrameObjectForConvert);
    if (convertedTimeFrame.skip === null) {
      return;
    }

    const plotDataResponse = await getPlotData({
      chartSchema: chartSchema,
      appID,
      timeFrame: convertedTimeFrame.timeFrame,
      tableID: tableSchema?.defaultTable?.tableID,
    });
    if (plotDataResponse.skip) {
      return;
    }
    const newResponse = {
      schema: {
        [chartSchema.chartID]: chartSchema,
      },
      plotData: plotDataResponse.plotData,
    };

    const newMessage = {
      ...message,
      response: newResponse,
      timeFrame: convertedTimeFrame.timeFrame,
    };
    setMessages((currentMessages: any[]) => [...currentMessages, newMessage]);
  };
  return (
    <div
      className='chartOptions'
      style={mobileMode ? { width, position: 'absolute', right: 0 } : { width }}
    >
      <div className='card-toolbar'>
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-primary introjs-AIChartSettings'
          onClick={() => chartSettingsButton()}
        >
          <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
        </button>
      </div>
      {checkForInsight ? (
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary'
            onClick={() => handleInsight()}
          >
            <RiLightbulbFill style={{ fontSize: '1.5rem' }} />
          </button>
        </div>
      ) : null}

      <div className='card-toolbar'>
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-primary introjs-AIFilter'
          onClick={() => handleTopFilter()}
          style={{ position: 'relative' }}
        >
          <FiFilter style={{ fontSize: '1.5rem' }} />
          <div
            className='displayFilter'
            style={{
              position: 'absolute',
              top: '4px',
              right: '2px',
              height: '1rem',
              width: '1rem',
              background: '#009df6',
              fontSize: '.6rem',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              borderRadius: '6px',
              fontWeight: 500,
              color: 'white',
            }}
          >
            {filterCount}
          </div>
        </button>
      </div>
      {isPinningAllowed ? (
        <div className='card-toolbar'>
          <div className='pinningOptionContainer'>
            <PinningOptions
              object={appDash}
              dropDownValue={selectedDashboard}
              isDropDownOpen={isPiningOptionOpen}
              setisDropDownOpen={setisPiningOptionOpen}
              setdropDownValue={handleDashBoard}
            />
          </div>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary introjs-AIPin'
            onClick={() => handlePin()}
          >
            <MdAddChart style={{ fontSize: '1.5rem' }} />
          </button>
        </div>
      ) : null}
      {defaultDateTimeCol ? (
        <div className='card-toolbar'>
          <ObjectDropDown
            object={defaultTimeframe}
            dropDownValue={timeFrame}
            setdropDownValue={handleTimeFrame}
            ObjectInObject={true}
            isChildernInsert={true}
            className='timframeDropdown'
            children={
              <button type='button' className='btn btn-sm btn-icon btn-color-primary'>
                <BiTime style={{ fontSize: '1.5rem' }} />
              </button>
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default ChartOptions;

// const timeframObject = {
//   oneWeek: {
//     key: 'oneWeek',
//     name: '1 Week',
//     xDay: 7,
//   },
//   oneMonth: {
//     key: 'oneMonth',
//     name: '1 Months',
//     xDay: 30,
//   },
//   threeMonth: {
//     key: 'threeMonth',
//     name: '3 Months',
//     xDay: 90,
//   },
//   sixMonth: {
//     key: 'sixMonth',
//     name: '6 Months',
//     xDay: 180,
//   },
//   oneYear: {
//     key: 'oneYear',
//     name: '1 Year',
//     xDay: 365,
//   },
//   threeYear: {
//     key: 'threeYear',
//     name: '3 Year',
//     xDay: 1095,
//   },
//   customRange: {
//     key: 'customRange',
//     name: 'Custom Range',
//     xDay: null,
//   },
// };
