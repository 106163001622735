import { defaultNeededFormattingValue } from 'lib/reusable-components/reusableData/formatterData';

export const defaultDataType = {
  ALL: 'ALL',
  NUM: 'NUM',
  CAT: 'CAT',
  DATETIME: 'DATETIME',
  GEO: 'GEO',
  // ID: 'ID',
  ID_TEXT: 'ID_TEXT',
  ID_NUM: 'ID_NUM',
};

export const defaultDataTypeStyles = {
  [defaultDataType.ALL]: {
    key: defaultDataType.ALL,
    name: 'All',
    icon: '#123',
    color: 'green',
  },
  [defaultDataType.NUM]: {
    key: defaultDataType.NUM,
    name: 'Numerical',
    icon: '#123',
    color: 'green',
  },
  [defaultDataType.CAT]: {
    key: defaultDataType.CAT,
    name: 'Categorical',
    icon: 'ABC',
    color: '#00629a',
  },
  [defaultDataType.DATETIME]: {
    key: defaultDataType.DATETIME,
    name: 'Date Time',
    icon: 'DATE',
    color: 'orange',
  },
  [defaultDataType.GEO]: {
    key: defaultDataType.GEO,
    name: 'Geographical',
    icon: 'GEO',
    color: 'black',
  },
  // [defaultDataType.ID]: {
  //   key: defaultDataType.ID,
  //   name: 'Identifier',
  //   icon: 'ID',
  //   color: 'black',
  // },
  [defaultDataType.ID_TEXT]: {
    key: defaultDataType.ID_TEXT,
    name: 'Text Identifier',
    icon: 'ID',
    color: 'black',
  },
  [defaultDataType.ID_NUM]: {
    key: defaultDataType.ID_NUM,
    name: 'Num Identifier',
    icon: 'ID',
    color: 'black',
  },
};
export const defaultFilterByType = [
  defaultDataType.ALL,
  defaultDataType.CAT,
  defaultDataType.NUM,
  defaultDataType.DATETIME,
  defaultDataType.GEO,
  defaultDataType.ID,
];

export const allDropableID = {
  xAxisId: 'xAxisId',
  yAxisId: 'yAxisId',
  tableId: 'tableId',
  toolTip: 'toolTip',
  groupId: 'groupId',
};

export const toggleColumnDetails = {
  firstColumn: {
    toggleKeyName: 'Data Section',
    toggleKeyId: 0,
  },
  secondColumn: {
    toggleKeyName: 'Build Charts',
    toggleKeyId: 1,
  },
};

// export const defaultSolidColorPalettes = [
//   '#F64E60',
//   '#FFA800',
//   '#50CD89',
//   '#00A3FF',
//   '#8950FC',
//   '#1BC5BD',
//   '#FFE456',
//   '#137640',
//   '#394157',
//   '#FCB6BD',
//   '#5F5CF1',
//   '#D0B9FF',
// ];
// export const defaultSolidColorPalettes = [
//   '#276bfd',
//   '#fdb827',
//   '#e6194b',
//   '#3cb44b',
//   '#ffe119',
//   '#4363d8',
//   '#f58231',
//   '#911eb4',
//   '#46f0f0',
//   '#f032e6',
//   '#bcf60c',
//   '#fabebe',
//   '#008080',
//   '#e6beff',
//   '#9a6324',
//   '#fffac8',
//   '#800000',
//   '#aaffc3',
//   '#808000',
//   '#ffd8b1',
//   '#000075',
//   '#808080',
// ];
const mainColors = {
  primaryBlue: '#00A3FF',
};
export const defaultSolidColorPalettes = [
  '#181823',
  '#3F4254',
  '#404258',
  '#474E68',
  '#50577A',
  '#6B728E',
  '#FAF0E6',
  '#f8f9f5',
  // '#3C84AB',
  // '#473C33',
  // '#282A3A',
  // '#443C68',
  // '#635985',

  // '#00EBB8',
  // '#9CF68A',
  // '#F9F871',
  // '#fdb827',

  // '#404258',
  // '#474E68',
  // '#50577A',
  // '#6B728E',
  // mainColors.primaryBlue,
  // '#00C1FF',
  // '#00D9E6',
  // '#46f0f0',
  // '#f58231',
  // '#911eb4',
  // '#46f0f0',
  // '#f032e6',
  // '#bcf60c',
  // '#fabebe',
  // '#008080',
  // '#e6beff',
  // '#fffac8',
  // '#800000',
  // '#aaffc3',
  // '#808000',
  // '#ffd8b1',
  // '#000075',
  // '#808080',
];
export const defaultTextColor = defaultSolidColorPalettes[0];
export const defaultGradientColorPalettes = [
  {
    from: '#F64E60',
    to: '#FFA800',
    type: 'radial',
  },
  {
    from: '#FFA800',
    to: '#50CD89',
    type: 'linear',
  },
  {
    from: '#50CD89',
    to: '#00A3FF',
    type: 'conic',
  },
  {
    from: '#00A3FF',
    to: '#8950FC',
    type: 'linear',
  },
  {
    from: '#8950FC',
    to: '#1BC5BD',
    type: 'linear',
  },
  {
    from: '#1BC5BD',
    to: '#FFE456',
    type: 'linear',
  },
  {
    from: '#FFE456',
    to: '#47BE7D',
    type: 'linear',
  },
  {
    from: '#47BE7D',
    to: '#394157',
    type: 'linear',
  },
  {
    from: '#394157',
    to: '#181C32',
    type: 'linear',
  },
  {
    from: '#FCB6BD',
    to: '#5F5CF1',
    type: 'linear',
  },
  {
    from: '#5F5CF1',
    to: '#2FC4FF',
    type: 'linear',
  },
  {
    from: '#D0B9FF',
    to: '#5F5CF1',
    type: 'linear',
  },
];

export const defaultFontDecoration = ['bold', 'italic', 'underlined'];
export const defaultFontFamily = ['Poppins', 'Times new roman'];
export const defaultFontSize = [
  {
    name: 'S',
    key: '10',
  },
  {
    name: 'M',
    key: '12',
  },
  {
    name: 'L',
    key: '14',
  },
  // {
  //   name: 'XL',
  //   key: '16',
  // },
];
export const defaultSelectedFontSize = defaultFontSize[1].key;
export const defaultColorType = ['Solid', 'Gradient'];

export const defaultGradientTypes = ['linear', 'radial', 'conic'];

export const defaultGridLineTypes = ['dashed', 'solid', 'dotted'];
export const defaultGridLineWidth = [
  {
    key: 1,
    name: 'S',
  },
  {
    key: 2,
    name: 'M',
  },
  {
    key: 3,
    name: 'L',
  },
];

export const defaultChartWidthTypesObj = {
  FULL: {
    key: 'FULL',
    name: 'Full',
  },
  HALF: {
    key: 'HALF',
    name: 'Half',
  },
  TWOTHIRD: {
    key: 'TWOTHIRD',
    name: 'Two-Third',
  },
  ONEFOURTH: {
    key: 'ONEFOURTH',
    name: 'One-Fourth',
  },
};
export const defaultChartWidthTypes = [
  defaultChartWidthTypesObj.FULL,
  defaultChartWidthTypesObj.HALF,
  defaultChartWidthTypesObj.TWOTHIRD,
  defaultChartWidthTypesObj.ONEFOURTH,
];

export const defaultLegendColorPalettes = [
  mainColors.primaryBlue,
  '#475BF0',
  '#00D9E6',
  '#46f0f0',
  '#FFA800',
  '#50CD89',
  '#8950FC',
  '#1BC5BD',
  '#00C1FF',
  '#FFE456',
  '#0A572D',
  '#394157',
  '#FCB6BD',
  '#5F5CF1',
  '#D0B9FF',
];
export const defaultColorfulColorPalettes = [
  // '#FFFF00',
  '#c4f119', //new
  '#FF0000',
  '#0000FF',
  '#008000',
  '#00FFFF',
  '#008080',
  '#3F00FF',
  '#000080',
  '#800020',
  '#800080',
  '#FF00FF',
  // '#FFA500',
  '#0d76cf', //new
  '#E0B0FF',
  '#7F00FF',
  // '#FFD700',
  '#cf30c2', //new
];
// export const defaultColorfulColorPalettes = [
//   '#FF0000',
//   '#8B0000',
//   '#FF69B4',
//   '#FF6347',
//   '#FF4500',
//   '#FFA500',
//   '#FFFF00',
//   '#E6E6FA',
//   '#EE82EE',
//   '#FF00FF',
//   '#8A2BE2',
//   '#800080',
//   '#00FF00',
//   '#008000',
//   '#DDA0DD',
//   '#9370DB',
//   '#9932CC',
//   '#7B68EE',
//   '#7CFC00',
// ];
export const defaultColorfulColorPalettes2 = [
  mainColors.primaryBlue,
  '#00C1FF',
  '#00D9E6',
  '#46f0f0',
  '#FFA800',
  '#50CD89',
  '#00A3FF',
  '#8950FC',
  '#1BC5BD',
  '#FFE456',
  '#FCB6BD',
  '#5F5CF1',
  '#D0B9FF',
];

export const defaultDateKey = {
  CUSTOM: 'CUSTOM',
  LAST7DAYS: 'LAST7DAYS',
  LASTXDAYS: 'LASTXDAYS',
  defalutLastDay: 30,
  mainKey: 'period',
};

export const defaultDateArray = [
  {
    name: 'Today',
    [defaultDateKey.mainKey]: 'TODAY',
  },
  {
    name: 'Yesterday',
    [defaultDateKey.mainKey]: 'YESTERDAY',
  },
  {
    name: 'This week',
    [defaultDateKey.mainKey]: 'THISWEEK',
  },
  {
    name: 'Last 7 days',
    [defaultDateKey.mainKey]: 'LAST7DAYS',
  },
  {
    name: 'This month',
    [defaultDateKey.mainKey]: 'THISMONTH',
  },
  {
    name: 'Last 30 days',
    [defaultDateKey.mainKey]: 'LAST30Days',
  },
  {
    name: 'This quarter',
    [defaultDateKey.mainKey]: 'THISQUARTER',
  },
  {
    name: 'Last X day',
    [defaultDateKey.mainKey]: defaultDateKey.LASTXDAYS,
  },
  {
    name: 'This year',
    [defaultDateKey.mainKey]: 'THISYEAR',
  },
  {
    name: 'Custom Date',
    [defaultDateKey.mainKey]: defaultDateKey.CUSTOM,
  },
];
export const defaultOpacity = 70;
export const defaultTextOpacity = 100;
export const defaultPlotAreaBackgroundSolidOpacity = 30;

export const defaultDateObject = {
  [`TODAY`]: 'Today',
  [`YESTERDAY`]: 'Yesterday',
  [`THISWEEK`]: 'This week',
  [`LAST7DAYS`]: 'Last 7 days',
  [`THISMONTH`]: 'This month',
  [`LAST30DAYS`]: 'Last 30 days',
  [`THISQUARTER`]: 'This Quarter',
  [`THISYEAR`]: 'This Year',
  [`CUSTOM`]: 'Custom Date',
  [`LASTXDAYS`]: 'Last X Day',
};
export const defaultOperation = {
  num: {
    sum: {
      name: 'Sum',
      key: 'sum',
    },
    // average: {
    //   name: 'Average',
    //   key: 'mean',
    // },
    avg: {
      name: 'Average',
      key: 'avg',
    },
    minimum: {
      name: 'Minimum',
      key: 'min',
    },
    maximum: {
      name: 'Maximum',
      key: 'max',
    },
    standardDeviation: {
      name: 'Standard Deviation',
      key: 'std',
    },
    median: {
      name: 'Median',
      key: 'median',
    },
    mode: {
      name: 'Mode',
      key: 'mode',
    },
    var: {
      name: 'Variance',
      key: 'var',
    },
    // uncalculated: {
    //   name: 'Value(Uncalculated)',
    //   key: 'UNCALCULATED',
    // },
    percentOfGrandTotal: {
      name: 'Value(Percentage of grand total)',
      key: 'POGT',
    },
    PCT_BY_GROUP: {
      name: 'Percentage by group',
      key: 'PBG',
    },
  },
  cat: {
    count: {
      name: 'Count',
      key: 'count',
    },
    distinct: {
      name: 'Count(Distinct)',
      key: 'DISTINCT',
    },
  },
  dateTime: {
    day: {
      name: 'Day',
      key: 'DAY',
    },
    week: {
      name: 'Week',
      key: 'WEEK',
    },
    month: {
      name: 'Month',
      key: 'MONTH',
    },
    quarter: {
      name: 'Quarter',
      key: 'QUARTER',
    },
    year: {
      name: 'Year',
      key: 'YEAR',
    },
  },
};

// Special Case Charts

//CARD
export const defaultCardTypes = [
  {
    key: 'CARD1',
    name: 'CARD 1',
  },
  {
    key: 'CARD2',
    name: 'CARD 2',
  },
  // {
  //   key: 'TYPE2',
  //   name: 'Type 2',
  // },
  // {
  //   key: 'TYPE3',
  //   name: 'Type 3',
  // },
];

export const defaultTimeframe = {
  30: {
    name: '1 Months',
    xDay: 30,
  },
  90: {
    name: '3 Months',
    xDay: 90,
  },
  180: {
    name: '6 Months',
    xDay: 180,
  },
  365: {
    name: '1 Year',
    xDay: 365,
  },
  1095: {
    name: '3 Year',
    xDay: 1095,
  },
  null: {
    name: 'ALL',
    xDay: null,
  },
};
export const defaultCardDisplayType = ['data', 'percentage', 'change'];

export const CardAxesKey = {
  dataPrefix: 'dataPrefix',
  textSuffix: 'textSuffix',
  dataSuffix: 'dataSuffix',
  defaultTimeframe: 'defaultTimeframe',
  displayType: 'displayType',
  defaultCompare: 'defaultCompare',
  isCompare: 'isCompare',
  defaultDatetimeCol: 'defaultDatetimeCol',
  formatter: 'formatter',
};
export const defaultCardCompare = ['WEEK', 'MONTH', 'QUARTER', 'YEAR'];
export const defaultCardDataPrefix = '';
export const defaultCardTextSuffix = '';
export const defaultCardDataSuffix = '';

export const defaultKpiTargetData = {
  color: defaultTextColor,
  opacity: defaultOpacity,
};

export const defaultKpiTimeFrame = {
  THISWEEK: 'Weekly',
  THISMONTH: 'Monthly',
  THISQUARTER: 'Quarterly',
  THISYEAR: 'Yearly',
};
//GEO
export const GEOGroupColors = ['#dadaeb', '#bcbddc', '#9e9ac8', '#756bb1', '#54278f'];
export const defaultGeoData = {
  from: '',
  to: '',
  opacity: defaultOpacity,
};
//BARSTACKLINE
export const defaultTargetLines = {
  show: true,
  opacity: defaultOpacity,
  color: defaultLegendColorPalettes[0],
};
export const defaultGridLines = {
  vertical: {
    show: true,
    type: defaultGridLineTypes[1],
    width: 1,
    color: '#bcbec8',
    opacity: defaultOpacity,
    count: 5,
  },
  horizontal: {
    show: true,
    type: defaultGridLineTypes[1],
    width: 1,
    color: '#bcbec8',
    opacity: defaultOpacity,
    count: 5,
  },
};
export const defaultPCT_BY_GROUP = {
  NUM: [
    {
      name: '=',
      key: 'eq',
    },
    {
      name: '<',
      key: 'lt',
    },
    {
      name: '<=',
      key: 'le',
    },
    {
      name: '!=',
      key: 'ne',
    },
    {
      name: '>=',
      key: 'ge',
    },
    {
      name: '>',
      key: 'gt',
    },
  ],
  CAT: [
    {
      name: '=',
      key: 'eq',
    },
    {
      name: '!=',
      key: 'ne',
    },
  ],
};

export const defaultStyleFormater = {
  FN: {
    title: 'FullNumber',
    options: {
      AM: '123,123,123',
      EU: '123.123.123',
      IN: '12,12,12,123',
    },
  },
  SN: {
    title: 'ShortNumber',
    options: {
      AM: `123 M`,
      IN: '12 Cr',
    },
  },
  OTHER: {
    title: 'Other',
    options: {
      NU: 'XXXXXXXXX',
      Date: 'DD/MM/YYYY',
    },
  },
};
// PIVOTTABLE
export const defaultIndicatorTypes = { NONE: 'NONE', BAR: 'BAR', PIE: 'PIE', DONUT: 'DONUT' };

export const selectedStyleFormater = 'FN-AM';

//HEATMAP
export const minColorHeatMap = '#d8f3ff';
export const maxColorHeatMap = mainColors.primaryBlue;

export const defaultChartSetting = {
  chartType: {},
  name: '',
  desc: '',
  xAxis: [],
  yAxis: [],
  xAxisStyle: {
    label: 'X-Axis',
    isLabelEdited: false,
    show: true,
    style: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
    },
    valueStyle: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
      rotate: 0,
    },
  },
  yAxisStyle: {
    label: 'Y-Axis',
    isLabelEdited: false,
    show: true,
    style: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
    },
    valueStyle: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
      rotate: 0,
    },
  },
  doubleAxis: {
    isDoubleAxis: true,
    y0AxisStyle: {
      label: 'Y-Axis (Left)',
      isLabelEdited: false,
      show: true,
      style: {
        color: defaultTextColor,
        textStyle: [],
        font: defaultFontFamily[0],
        fontHeight: defaultSelectedFontSize,
        opacity: defaultTextOpacity,
      },
      valueStyle: {
        color: defaultTextColor,
        textStyle: [],
        font: defaultFontFamily[0],
        fontHeight: defaultSelectedFontSize,
        opacity: defaultTextOpacity,
        rotate: 0,
      },
    },
    y1AxisStyle: {
      label: 'Y-Axis (Right)',
      isLabelEdited: false,
      show: true,
      style: {
        color: defaultTextColor,
        textStyle: [],
        font: defaultFontFamily[0],
        fontHeight: defaultSelectedFontSize,
        opacity: defaultTextOpacity,
      },
      valueStyle: {
        color: defaultTextColor,
        textStyle: [],
        font: defaultFontFamily[0],
        fontHeight: defaultSelectedFontSize,
        opacity: defaultTextOpacity,
        rotate: 0,
      },
    },
  },
  tooltip: {
    textStyle: [],
    font: defaultFontFamily[0],
    fontHeight: defaultSelectedFontSize,
    type: 'automatic',
    automaticOpacity: defaultOpacity,
    style: [],
    valueStyle: [],
  },
  chartWidth: defaultChartWidthTypes[0],
  filters: {},
  plotAreaBackgroundSolid: {
    type: defaultColorType[0],
    opacity: defaultPlotAreaBackgroundSolidOpacity,
    color: defaultTextColor,
  },
  plotAreaBackgroundGradient: {
    type: defaultColorType[1],
    opacity: defaultPlotAreaBackgroundSolidOpacity,
    gradientColor: defaultGradientColorPalettes[0],
  },
  gridLines: {
    vertical: {
      show: true,
      type: defaultGridLineTypes[1],
      width: 1,
      color: '#bcbec8',
      opacity: defaultOpacity,
      count: 5,
    },
    horizontal: {
      show: true,
      type: defaultGridLineTypes[1],
      width: 1,
      color: '#bcbec8',
      opacity: defaultOpacity,
      count: 5,
    },
  },
  isZoomBrush: true,
  isDateHierarchy: false,
  isInsight: true,
  isForecast: false,
  isCrossReport: true,
  iskeepAllFilters: true,
  chartBuilderHelper: {
    columnDropNumber: 0,
  },
  timeFrame: 180,
  // Special Case

  //CARD
  chartHeaderImage: '', //CARD
  chartCardType: defaultCardTypes[0], //CARD
  //KPIMETER
  // targetPoints: [], //KPIMETER
  targetPoints: {
    maxGoalAchievePercentage: 9,
    startPoint: 0,
    kpiStartingPoint: 0,
    title: {
      valuePrefix: '',
      valuePostfix: '',
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
    },
    type: 'automatic',
    targetFontStyle: {
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      color: defaultTextColor,
      opacity: defaultTextOpacity,
    },
    targets: [],
    kpiTimeframe: 'THISMONTH',
    timeframeColumn: '',
    formatter: defaultNeededFormattingValue.num,
  },
  defaultKpiTargets: [],
  //GEO
  geoGroup: [], //GEO
  //GEOMAP
  geoMapColor: {
    color: '#e13b2f',
    opacity: defaultOpacity,
  },
  //BARSTACKLINE
  barStackLineTargetPoints: { targetLines: [] }, //BARSTACKLINE
  //PLOT
  plotGroup: [], //PLOT

  plotGroupStyle: {
    label: 'Plot Group',
    isLabelEdited: false,
    style: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
    },
    valueStyle: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
      rotate: 0,
    },
  },

  //PIVOTTABLE
  pivotTableTitleStyle: {
    fontHeight: defaultSelectedFontSize,
    textStyle: [defaultFontDecoration[0]],
  },
  //HEATMAP
  heatMapGroup: [], //HEATMAP
  heatMapColor: {
    max: {
      opacity: defaultTextOpacity,
      color: maxColorHeatMap,
    },
    min: {
      opacity: defaultOpacity,
      color: minColorHeatMap,
    },
  },

  //TREEMAP
  treeMapColor: {
    max: {
      opacity: defaultOpacity,
      color: maxColorHeatMap,
    },
    min: {
      opacity: defaultOpacity,
      color: minColorHeatMap,
    },
  },

  //COLOR
  colorAxis: [], //COLOR
  colorAxisStyle: {
    label: 'Color Axis',
    isLabelEdited: false,
    style: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
    },
    valueStyle: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
      rotate: 0,
    },
  },

  //RADIALLINE
  radialLineBackGroundColor: {
    opacity: defaultTextOpacity,
    color: maxColorHeatMap,
  },
};
export const chartErrors = {
  kpiMeters: {
    moreThenOne: 'More then one column not possible in <b>KPIMETER<b>',
  },
  pieCharts: {
    numColumnNotAcceptable: '<b>Numerical column</b> not acceptable',
  },
  geoCharts: {
    moreThenOne: 'More then one column not possible',
    numColumnAcceptable: 'Only <b>Numerical column</b> acceptable',
  },
  numColumnAcceptable: 'Only <b>Numerical column</b> acceptable',

  barStackLine: {
    moreThenOne: 'More then one column not possible',
    numColumnAcceptable: 'Only <b>Numerical column</b> acceptable',
  },
  catColumnAcceptable: 'Only <b>Categorical column</b> acceptable',
  catDateColumnAcceptable: 'Only <b>Categorical and DateTime column</b> acceptable',
  [defaultDataType.NUM]: {
    acceptable: 'Only <b>Numerical column</b> acceptable',
    notAcceptable: '<b>Numerical column</b> is not acceptable',
  },
  [defaultDataType.CAT]: {
    acceptable: 'Only <b>Categorical column</b> acceptable',
    notAcceptable: '<b>Categorical column</b> is not acceptable',
  },
  [defaultDataType.DATETIME]: {
    acceptable: 'Only <b>DateTime column</b> acceptable',
    notAcceptable: '<b>DateTime column</b> is not acceptable',
  },
  [defaultDataType.ID_TEXT]: {
    acceptable: 'Only <b>Text ID column</b> acceptable',
    notAcceptable: '<b>Text ID column</b> is not acceptable',
  },
  [defaultDataType.ID_NUM]: {
    acceptable: 'Only <b>Numerical ID column</b> acceptable',
    notAcceptable: '<b>Numerical ID column</b> is not acceptable',
  },
};
