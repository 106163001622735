import React, { useState } from 'react';

import { defaultSolidColorPalettes } from '../../../data/defaultChartSchema';
import xWhite from '../../../assets/x.svg';

import useDebounce from '../../../hooks/useDebounce';

import useUpdateEffect from '../../../hooks/useUpdateEffect';
import OpacitySlider from '../../StyleComponents/OpacitySlider';

const kpiStartingPoint = 0;
const CustomColor = (props) => {
  const { stateProps, targetKey, className = '' } = props;
  const { kpiTargetPoints, setKpiTargetPoints } = stateProps;

  const [isPopUpOn, setIsPopUpOn] = useState(true);
  const handlePopUp = () => {
    setIsPopUpOn(!isPopUpOn);
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      <div className='legendButton'>
        <button className='primaryButton secondaryButtonColor' onClick={() => handlePopUp()}>
          Customize Legends
        </button>
      </div>
      {isPopUpOn && (
        <div className='legendPopupDivOuterContainer' onClick={() => handlePopUp()}>
          <div
            className={
              targetKey?.isTrue
                ? `legendPopupDivInnerContainer ${className}`
                : `legendPopupDivInnerContainer legendPopupColumn ${className}`
            }
            onClick={(e) => stopPropagation(e)}
          >
            <div class='closeButton' onClick={() => handlePopUp()}>
              <img src={xWhite} alt='' class='arrowIcon' />
            </div>
            <div className='legendPopupItems upper'>
              <div className='legendPopupItem serialNumber'></div>
              <div className='legendPopupItem title'>Name</div>
              <div className='legendPopupItem selectColor'>Select color</div>
              <div className='legendPopupItem styleText'>Opacity</div>
            </div>
            <div className='legendPopupScrollableDiv'>
              {React.Children.toArray(
                kpiTargetPoints.targets.map((elem, i) => {
                  if (Number(kpiTargetPoints.targets[0].targetPoint) <= 0 && i === 0) {
                    return (
                      <>
                        <TargetItem
                          elem={elem}
                          target={kpiTargetPoints}
                          setTarget={setKpiTargetPoints}
                          index={i}
                          targetKey={targetKey}
                        />
                      </>
                    );
                  }

                  return (
                    <>
                      <TargetItem
                        elem={elem}
                        target={kpiTargetPoints}
                        setTarget={setKpiTargetPoints}
                        index={i}
                        targetKey={targetKey}
                      />
                    </>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomColor;

const TargetItem = (props) => {
  const { elem, target, setTarget, index } = props;

  const handleColor = (newElem) => {
    const sourceitems = Array.from(target.targets);
    const [selectedItem] = sourceitems.splice(index, 1);
    const newSelectedItem = { ...selectedItem, ...newElem };
    sourceitems.splice(index, 0, newSelectedItem);
    setTarget({ ...target, targets: sourceitems });
  };

  const tilteFucntion = () => {
    // when targetPoint is smaller then kpi starting Point
    if (kpiStartingPoint >= elem.targetPoint) {
      const forwardKpiTargetPoint = target.targets[index + 1];
      // when forwardKpi Target Point is present
      if (forwardKpiTargetPoint) {
        if (kpiStartingPoint <= forwardKpiTargetPoint.targetPoint) {
          // when forwardKpi Target Point is grater then kpiStarting Point
          // start current target point to kpiStartingPoint
          return `${elem?.targetPoint} to ${kpiStartingPoint}`;
        }
        // when forwardKpi Target Point is smaller then kpiStarting Point
        // start current target point to forwardKpiTargetPoint
        return `${elem?.targetPoint} to ${forwardKpiTargetPoint?.targetPoint}`;
      } else {
        // when forwardKpi Target Point is not present
        // start current target point to kpiStartingPoint
        return `${elem?.targetPoint} to ${kpiStartingPoint}`;
      }
    }

    const previousKpiTargetPoint = target.targets[index - 1];
    if (previousKpiTargetPoint) {
      if (kpiStartingPoint >= previousKpiTargetPoint.targetPoint) {
        // when previousKpiTargetPoint is smaller then kpiStartingPoint
        // start current target point to kpiStartingPoint
        return `${kpiStartingPoint} to ${elem?.targetPoint}`;
      }
      return `${previousKpiTargetPoint?.targetPoint} to ${elem?.targetPoint}`;
    }
    return target?.startPoint === elem?.targetPoint
      ? `${elem.targetPoint}`
      : `${target?.startPoint ?? 0} to ${elem.targetPoint}`;
  };

  return (
    <div className='legendPopupItems'>
      <div className='legendPopupItem serialNumber'></div>
      <div className='legendPopupItem title'>{tilteFucntion()}</div>
      <div className='legendPopupItem selectColor'>
        <SolidColorSelector color={elem} setColor={handleColor} />
      </div>
      <div className='legendPopupItem Opacity'>
        <OpacitySlider color={elem} setColor={handleColor} />
      </div>
    </div>
  );
};

const SolidColorSelector = ({ color, setColor }) => {
  const [selectedColor, setSelectedColor] = useState(color?.color || defaultSolidColorPalettes[0]);
  const debounceSelectedColor = useDebounce(selectedColor);
  const handleColor = (e) => {
    if (e.target.value.length >= 8) {
      return;
    }
    if (e.target.value.length === 0) {
      return;
    }
    setSelectedColor(e.target.value.toUpperCase());
  };

  useUpdateEffect(() => {
    if (!color) return;
    if (!debounceSelectedColor) return;

    setColor({ ...color, color: selectedColor });
  }, [debounceSelectedColor]);

  return (
    <div className='solidColorSelctor colorSelector'>
      <div className='colorInputDiv'>
        <input
          type='text'
          className='colorHashInput'
          value={selectedColor}
          onChange={(e) => handleColor(e)}
        />
        <div
          className='colorDropperInput'
          style={{ backgroundColor: selectedColor, opacity: color.opacity / 100 }}
        >
          <input type='color' value={selectedColor} onChange={(e) => handleColor(e)} />
        </div>
      </div>
    </div>
  );
};
