import { AreaSeries, BarGroup, BarSeries, LineSeries, AreaStack, BarStack } from '@visx/xychart';
import { getDefaultColorByIndex, hexWithOpacity } from 'lib/visx-lib/helpers/colorHelper';
import { memo } from 'react';

const XYChart = ({
  xData,
  yData,
  accessors,
  getRequiredFormat,
  type,
  handleClick,
  colorScale,
  automaticOpacity = 0.7,
}) => {
  const selectChart = () => {
    if (type === 'MULTILINE' || type === 'LINE' || type === 'LINECOLOR') {
      return (
        <>
          {Object.keys(yData).map((key, index) => {
            return (
              <LineSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData, yData[key].data)}
                {...accessors}
                colorAccessor={(d) => {
                  return (
                    colorScale?.(key) ??
                    hexWithOpacity(getDefaultColorByIndex(index), automaticOpacity)
                  );
                }}
              />
            );
          })}
        </>
      );
    }

    if (type === 'BARSTACK') {
      return (
        <>
          <BarStack
            // fillOpacity={0.5}
            // offset='none'
            offset={undefined}
          >
            {Object.keys(yData).map((key, yIx) => {
              return (
                <>
                  <BarSeries
                    key={key}
                    dataKey={key}
                    data={getRequiredFormat(xData, yData[key].data)}
                    {...accessors}
                    onPointerUp={(e) => {
                      handleClick?.(e.datum.x);
                    }}
                    fillOpacity={0.5}
                    colorAccessor={(d) => colorScale?.(key)}
                  />
                </>
              );
            })}
          </BarStack>
        </>
      );
    }

    if (type === 'BARCOLOR') {
      return (
        <>
          <BarStack
            // fillOpacity={0.5}
            offset={undefined}
            // offset='none'
          >
            {Object.keys(yData).map((key, index) => {
              return (
                <>
                  <BarSeries
                    key={key}
                    dataKey={key}
                    data={getRequiredFormat(xData, yData[key].data)}
                    {...accessors}
                    onPointerUp={(e) => {
                      handleClick?.(e.datum.x);
                    }}
                    fillOpacity={0.5}
                    colorAccessor={(d) => {
                      return (
                        colorScale?.(key) ??
                        hexWithOpacity(getDefaultColorByIndex(index), automaticOpacity)
                      );
                    }}
                  />
                </>
              );
            })}
          </BarStack>
        </>
      );
    }

    if (type === 'AREASTACK') {
      return (
        <AreaStack renderLine={true}>
          {Object.keys(yData).map((key) => {
            return (
              <AreaSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData, yData[key].data)}
                {...accessors}
                fillOpacity={0.4}
                fill={colorScale?.(key)}
              />
            );
          })}
        </AreaStack>
      );
    }

    if (type === 'AREACOLOR') {
      return (
        <AreaStack renderLine={true}>
          {Object.keys(yData).map((key, index) => {
            return (
              <AreaSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData, yData[key].data)}
                {...accessors}
                fillOpacity={0.4}
                fill={
                  colorScale?.(key) ??
                  hexWithOpacity(getDefaultColorByIndex(index), automaticOpacity)
                }
              />
            );
          })}
        </AreaStack>
      );
    }

    if (type === 'BAR') {
      return (
        <>
          {Object.keys(yData).map((key) => {
            return (
              <BarSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData, yData[key].data)}
                {...accessors}
                onPointerUp={(e) => {
                  handleClick?.(e.datum.x);
                }}
                opacity={1}
                colorAccessor={(d) => colorScale?.(key)}
              />
            );
          })}
        </>
      );
    }

    // if (type === 'BARGROUP') {
    //   return (
    //     <>
    //       <>
    //         {Object.keys(yData).map((mainKey, i) => {
    //           if (i === 0) {
    //             return (
    //               <BarGroup>
    //                 {Object.keys(yData).map((key) => {
    //                   const data = getRequiredFormat(xData, yData[key].data)
    //                   if (mainKey === key) {
    //                     return (
    //                       <BarSeries
    //                         key={key}
    //                         dataKey={key}
    //                         data={data}
    //                         {...accessors}
    //                         fillOpacity={0.6}
    //                         colorAccessor={(d) => colorScale?.(key)}
    //                         opacity={0.6}
    //                       />
    //                     );

    //                   }
    //                   return <BarSeries
    //                     key={"key"}
    //                     dataKey={"key"}
    //                     data={[]}
    //                     {...accessors}
    //                     fillOpacity={0.6}
    //                     colorAccessor={(d) => colorScale?.(key)}
    //                     opacity={0.6}
    //                   />
    //                 })}

    //               </BarGroup>
    //             )
    //           }
    //           if (i === 1) {
    //             return (
    //               <BarGroup>
    //                 {Object.keys(yData).map((key) => {
    //                   const data = getRequiredFormat(xData, yData[key].data)
    //                   if (mainKey === key) {
    //                     return (
    //                       <BarSeries
    //                         key={key}
    //                         dataKey={key}
    //                         data={data}
    //                         {...accessors}
    //                         fillOpacity={0.6}
    //                         colorAccessor={(d) => colorScale?.(key)}
    //                         opacity={0.6}
    //                       />
    //                     );
    //                   }
    //                   return <BarSeries
    //                     key={"key"}
    //                     dataKey={"key"}
    //                     data={[]}
    //                     {...accessors}
    //                     fillOpacity={0.6}
    //                     colorAccessor={(d) => colorScale?.(key)}
    //                     opacity={0.6}
    //                   />
    //                 }

    //                 )}
    //               </BarGroup>
    //             )
    //           }
    //         })}
    //       </>
    //     </>

    //   );
    // }
    if (type === 'BARGROUP') {
      return (
        <BarGroup>
          {Object.keys(yData).map((key) => {
            return (
              <BarSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData, yData[key].data)}
                {...accessors}
                fillOpacity={0.6}
                colorAccessor={(d) => colorScale?.(key)}
                opacity={0.6}
              />
            );
          })}
        </BarGroup>
      );
    }
    if (type === 'AREA') {
      return (
        <>
          {Object.keys(yData).map((key) => {
            return (
              <AreaSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData, yData[key].data)}
                {...accessors}
                fillOpacity={0.3}
                fill={colorScale?.(key)}
              />
            );
          })}
        </>
      );
    }

    throw Error('Invalid Chart Type'); // Invalid XYType Type
  };

  return <>{selectChart()}</>;
};

export default memo(XYChart);
