//  For charts server
// const api = 'https://analytics-api.supista.com/';

import { getAppIDFromURL } from './getAppID';
export const analyticsBaseAPI =
  process.env.REACT_APP_IS_LOCALHOST === 'true'
    ? 'http://localhost:8000'
    : 'https://analytics-api.supista.com';

//  For Supista server
const SupistaMISSettingsAPI = 'https://test.supista.com/api'; // 'http://localhost:8080/api'; // 'http://localhost:8080/api';

const checkFor403Error = (response) => {
  if (response?.status === 403) {
    const appID = getAppIDFromURL();
    _supistaApiPost(`auth/${appID}/logout`, { data: {} }).then(() => {
      localStorage.clear();
      window.location.reload();
    });
    return true;
  }
  return false;
};

export async function _fetchDataToPlot(params, url) {
  const serverURL = `${analyticsBaseAPI}/${url}`;
  return fetch(serverURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(params),
    credentials: 'include',
  })
    .then((response) => response.json())
    .then((response) => {
      if (checkFor403Error(response)) return {};
      return response;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
}

export const _supistaAnalyticsApi = async (endpoint, data) => {
  return fetch(`${analyticsBaseAPI}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      if (checkFor403Error(response)) return {};
      return response;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
};

export const _supistaApiGet = (endpoint) => {
  return fetch(`${SupistaMISSettingsAPI}/${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
};

export const _supistaApiPost = (endpoint, data) => {
  const cookiesStoredInLocalStorage = JSON.parse(localStorage.getItem('__d3__cookies'));
  if (cookiesStoredInLocalStorage) {
    data.__d3__cookies = cookiesStoredInLocalStorage;
  } else {
    data.__d3__cookies = {};
  }
  return fetch(`${SupistaMISSettingsAPI}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.hasOwnProperty('__d3__cookies')) {
        localStorage.setItem('__d3__cookies', JSON.stringify(response.__d3__cookies));
      }
      return response;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
};

export const _supistaApiDelete = async (endpoint, data) => {
  const cookiesStoredInLocalStorage = JSON.parse(localStorage.getItem('__d3__cookies'));
  if (cookiesStoredInLocalStorage) {
    data.__d3__cookies = cookiesStoredInLocalStorage;
  } else {
    data.__d3__cookies = {};
  }
  return fetch(`${SupistaMISSettingsAPI}/${endpoint}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.hasOwnProperty('__d3__cookies')) {
        localStorage.setItem('__d3__cookies', JSON.stringify(response.__d3__cookies));
      }
      return response;
    })
    .catch((error) => {
      console.error('Error:', error);
      return {};
      // Handle any errors that occurred during the file upload
    });
};

export function _uploadFileToServer(endpoint, formData) {
  return fetch(`${analyticsBaseAPI}/${endpoint}`, {
    method: 'POST',
    headers: {
      Accept: '*/*',
    },
    credentials: 'include',
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('File upload failed.');
      }
      return response.json();
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle any errors that occurred during the file upload
    });
}

export async function _fetchFile(params, url) {
  const serverURL = `${analyticsBaseAPI}/${url}`;
  return (
    fetch(serverURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json',
      },
      body: JSON.stringify(params),
      credentials: 'include',
    })
      // .then((response) => response.json())
      .then((response) => {
        if (!response.__d3__error) return response.blob();
        else return response.json();
      })
      .catch((error) => {
        console.error('Error:', error);
        return {};
        // Handle any errors that occurred during the file upload
      })
  );
}
