export const normalSchema = [
  'chartType',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  'xAxisStyle',
  'yAxisStyle',
  'chartWidth',
  'chartID',
  'appID',
  'sheetID',
  'projectID',
  'tooltip',
  'filters',
  'isInsight',
  'isForecast',
  'isZoomBrush',
  'tableID',
  'doubleAxis',
  // 'timeFrame',
  // 'isDateHierarchy',
  'gridLines',
  // 'isCrossReport',
  // 'iskeepAllFilters',
  'chartBuilderHelper',

  //   'plotAreaBackground',
];
export const colorSchema = [
  'chartType',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  'xAxisStyle',
  'yAxisStyle',
  'chartWidth',
  'chartID',
  'appID',
  'sheetID',
  'projectID',
  'tableID',
  'tooltip',
  'filters',
  'isInsight',
  'isForecast',
  'isZoomBrush',
  // 'timeFrame',
  // 'isDateHierarchy',
  'gridLines',
  'gridLines',
  // 'isCrossReport',
  // 'iskeepAllFilters',
  'chartBuilderHelper',
  'colorAxis',
  'colorAxisStyle',
  //   'plotAreaBackground',
];

export const pieSchema = [
  'chartType',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  'chartWidth',
  'chartID',
  'appID',
  'sheetID',
  'tableID',
  'projectID',
  'tooltip',
  'filters',
  'isInsight',
  'isForecast',
  // 'timeFrame',

  // 'isDateHierarchy',
  // 'isCrossReport',
  // 'iskeepAllFilters',
  'chartBuilderHelper',
  //   'plotAreaBackground',
];
export const treeMapSchema = [
  'chartType',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  'chartWidth',
  'chartID',
  'appID',
  'sheetID',
  'tableID',
  'projectID',
  'tooltip',
  'filters',
  'isInsight',
  'isForecast',
  // 'timeFrame',

  // 'isDateHierarchy',
  // 'isCrossReport',
  // 'iskeepAllFilters',
  'chartBuilderHelper',
  'treeMapColor',
];
export const cardSchema = [
  'appID',
  'filters',
  'cardType',
  'chartType',
  'chartWidth',
  'headerImage',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  'chartID',
  'tableID',
  'sheetID',
  'projectID',
  'isInsight',
  // 'timeFrame',

  // 'isCrossReport',
  // 'iskeepAllFilters',

  'isForecast', //?
  'chartBuilderHelper',
  //   'plotAreaBackground',
];

export const kpiMetersSchema = [
  'appID',
  'chartID',
  'chartType',
  'chartWidth',
  'name',
  'desc',
  'projectID',
  'tableID',
  'sheetID',
  'xAxis',
  'yAxis',
  'targetPoints',
  'tooltip',
  // 'timeFrame',

  'filters',
  'isInsight',
  // 'isCrossReport',
  // 'iskeepAllFilters',
  'isForecast', //?

  'timeframeColumn',
  'chartBuilderHelper',
  //   'plotAreaBackground',
];
export const kpiTrackingSchema = [
  'appID',
  'chartID',
  'chartType',
  'chartWidth',
  'name',
  'desc',
  'projectID',
  'tableID',
  'sheetID',
  'xAxis',
  'yAxis',
  'targetPoints',
  'tooltip',
  // 'timeFrame',

  'filters',
  'isInsight',
  // 'isCrossReport',
  // 'iskeepAllFilters',
  'isForecast', //?

  'timeframeColumn',
  'chartBuilderHelper',
  //   'plotAreaBackground',
];
export const wordCloudSchema = [
  'chartType',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  'chartWidth',
  'chartID',
  'appID',
  'sheetID',
  'tableID',
  'projectID',
  'filters',
  // 'timeFrame',
  // 'isCrossReport',
  // 'iskeepAllFilters',

  'isInsight',
  'isForecast', //?

  'chartBuilderHelper',
  //   'plotAreaBackground',
];
export const barStackLineSchema = [
  'appID',
  'chartID',
  'chartType',
  'chartWidth',
  'filters',
  'name',
  'desc',
  'gridLines',
  'isCrossReport',
  // 'isDateHierarchy',
  'isInsight',
  'isForecast',
  'iskeepAllFilters',
  'isZoomBrush',
  'tableID',
  'sheetID',
  'projectID',
  'targetPoints',
  'tooltip',
  'xAxis',
  'xAxisStyle',
  'yAxis',
  'yAxisStyle',
  // 'timeFrame',

  'chartBuilderHelper',
  //   'plotAreaBackground',
];
export const geoSchema = [
  'chartType',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  // 'geoGroup',
  'tooltip',
  'chartWidth',
  'chartID',
  'filters',
  'appID',
  'tableID',
  'sheetID',
  'projectID',
  'isCrossReport',
  'isForecast',
  'isInsight',
  'iskeepAllFilters',
  // 'timeFrame',

  'chartBuilderHelper',
  //   'plotAreaBackground',
];
export const geoMapSchema = [
  'chartType',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  // 'geoGroup',
  'tooltip',
  'chartWidth',
  'geoMapColor',
  'chartID',
  'filters',
  'appID',
  'tableID',
  'sheetID',
  'projectID',
  'isCrossReport',
  'isForecast',
  'isInsight',
  'iskeepAllFilters',
  // 'timeFrame',

  'chartBuilderHelper',
  //   'plotAreaBackground',
];
export const geoMapColorSchema = [
  'chartType',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  // 'geoGroup',
  'tooltip',
  'chartWidth',
  'chartID',
  'filters',
  'appID',
  'tableID',
  'sheetID',
  'projectID',
  'isCrossReport',
  'isForecast',
  'isInsight',
  'iskeepAllFilters',
  'colorAxis',
  // 'timeFrame',

  'chartBuilderHelper',
  //   'plotAreaBackground',
];
export const plotSchema = [
  'chartType',
  'name',
  'desc',
  'appID',
  'xAxis',
  'xAxisStyle',
  'yAxis',
  'yAxisStyle',
  'plotGroup',
  'plotGroupStyle',
  'tooltip',
  'chartWidth',
  'chartID',
  'filters',
  'tableID',
  'sheetID',
  'projectID',
  'isInsight',
  'isForecast',
  'gridLines',
  // 'timeFrame',
  // 'isCrossReport',
  // 'iskeepAllFilters',

  // 'isDateHierarchy', //?
  'isZoomBrush', //?

  'chartBuilderHelper',
  //   'plotAreaBackground',
];
export const radarOrRadialSchema = [
  'chartType',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  'xAxisStyle',
  'yAxisStyle',
  'chartWidth',
  'chartID',
  'appID',
  'tableID',
  'sheetID',
  'projectID',
  'tooltip',
  'filters',
  'isInsight',
  'isForecast',
  // 'timeFrame',
  // 'isDateHierarchy',
  // 'isCrossReport',
  // 'iskeepAllFilters',
  'chartBuilderHelper',

  //   'plotAreaBackground',
];
export const pivotTableSchema = [
  'chartType',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  'xAxisStyle',
  'yAxisStyle',
  'chartWidth',
  'chartID',
  'tableID',
  'appID',
  'sheetID',
  'projectID',
  'tooltip',
  'filters',
  'isInsight',
  'isForecast',
  'isZoomBrush',
  'chartBuilderHelper',
  'pivotTableTitleStyle',
];

export const heatMapSchema = [
  'chartType',
  'name',
  'desc',
  'xAxis',
  'yAxis',
  'xAxisStyle',
  'yAxisStyle',
  'chartWidth',
  'chartID',
  'appID',
  'tableID',
  'sheetID',
  'projectID',
  'tooltip',
  'filters',
  'isInsight',
  'isForecast',
  'isZoomBrush',
  'magnitude',
  'heatMapColor',
  // 'timeFrame',
  // 'isDateHierarchy',

  'chartBuilderHelper',
];
