import React, { useRef, useState } from 'react';
import Chart from '../components/Chart';
import html2canvas from 'html2canvas';
import ExportDivWrapper from './ExportDivWrapper';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
const ExportCharts = (props: any) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [exportDivWrapper, setExportDivWrapper] = useState(false);
  const [exportDivLoading, setExportDivLoading] = useState(false);
  const captureDivAsPng = () => {
    setExportDivWrapper(true);
    setExportDivLoading(true);
    setTimeout(() => {
      if (divRef.current) {
        html2canvas(divRef.current, {
          useCORS: true,
          logging: true,
          ignoreElements: (element) => {
            return element.classList.contains('leaflet-control-container');
          },
        }).then((canvas) => {
          let d = new Date(),
            dformat =
              [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('-') +
              '_' +
              [d.getHours(), d.getMinutes(), d.getSeconds()].join('-');
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          setTimeout(() => {
            link.download = `${props.chartData.name} ${dformat}.png`;
            link.click();
            setExportDivLoading(false);
          }, 2000);
        });
      } else {
        setExportDivLoading(false);
      }
      setExportDivWrapper(false);
    }, 1000);
  };

  return (
    <>
      {exportDivWrapper ? (
        <ExportDivWrapper
          {...props}
          chartDivRefForExport={divRef}
          captureDivAsPng={captureDivAsPng}
          cardWidth={'FULL'}
          className={props?.chartData?.chartType === allChartKeys.CARD ? 'CARDExportWrapper' : ''}
          chartHeight={800}
        />
      ) : null}
      <Chart {...props} captureDivAsPng={captureDivAsPng} exportDivLoading={exportDivLoading} />
    </>
  );
};

export default ExportCharts;
