import React, { useMemo } from 'react';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import { allDropableID, defaultChartSetting } from '../../data/defaultChartSchema';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';

import DragableToolTip from './DragableToolTip';
import DragableAxis from './DragableAxis';

import './chartBuild.scss';
import FilterIndex from './FilterDiv/FilterIndex';
import BarStackLineTargetPoints from '../SpacialCasesComponents/BarStackLine/BarStackLineTargetPoints';
import BarStackLineToolTipDiv from '../SpacialCasesComponents/BarStackLine/BarStackLineToolTipDiv';
import InfoToolTip from 'lib/reusable-components/Components/ToolTip/InfoToolTip';
import { setDoubleAxisLabel } from 'lib/chart-settings-lib/utils/chartHelperFunctions';
import ChartInsight from './ChartInsight';
import { mls } from 'lib/multilanguagesupport';

const ChartBuild = ({ stateProps }) => {
  const {
    isInsight,
    setIsInsight,
    xAxisData,
    setXAxisData,
    yAxisData,
    setYAxisData,
    setChartXAxisStyle,
    setChartYAxisStyle,
    chartType,
    handleResetToolTip,
    colorAxis,
    setColorAxis,
    setColorAxisStyle,
    setDoubleAxis,
    doubleAxisMemo,
  } = stateProps;
  const { xAxisId, yAxisId, toolTip, groupId } = allDropableID;
  const setDoubleAxisSwitch = (action) => {
    if (action) {
      setDoubleAxisLabel({ setDoubleAxis, yAxisData: yAxisData, isDoubleAxis: action });
      return;
    }
    setDoubleAxis((currentData) => {
      return { ...currentData, isDoubleAxis: action };
    });
  };
  const colorAxisArray = [
    allChartKeys.BAR,
    allChartKeys.BARCOLOR,
    allChartKeys.LINE,
    allChartKeys.LINECOLOR,
    allChartKeys.AREA,
    allChartKeys.AREACOLOR,
    allChartKeys.BARHORIZONTAL,
    allChartKeys.BARHORIZONTALCOLOR,
  ];
  const isColorAxisDisable = useMemo(() => {
    return colorAxisArray.some((elem) => {
      return elem === chartType?.chartKey;
    });
  }, [chartType]);
  return (
    <>
      <div className='outerDiv'>
        <div className='MainTitle'>
          <InfoToolTip
            data={{ title: '', description: 'Drop columns from table for xAxis of Chart' }}
          >
            {mls('X-Axis')} *
          </InfoToolTip>
        </div>
        <DragableAxis
          dragableDivId={xAxisId}
          axisData={xAxisData}
          setAxisData={setXAxisData}
          stateProps={stateProps}
          setStyle={setChartXAxisStyle}
          defaultLable={defaultChartSetting.xAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('Y-Axis')} *</div>
        <DragableAxis
          dragableDivId={yAxisId}
          axisData={yAxisData}
          setAxisData={setYAxisData}
          stateProps={stateProps}
          setStyle={setChartYAxisStyle}
          defaultLable={defaultChartSetting.yAxisStyle.label}
        />
      </div>
      {chartType.chartKey === allChartKeys.BARGROUP ? null : (
        <div className='outerDiv'>
          <div className={isColorAxisDisable ? 'MainTitle' : 'MainTitle disable'}>
            {mls('Color Axis')}
          </div>

          <DragableAxis
            dragableDivId={groupId}
            axisData={colorAxis}
            setAxisData={setColorAxis}
            stateProps={stateProps}
            setStyle={setColorAxisStyle}
            defaultLable={defaultChartSetting.colorAxisStyle.label}
            allDropableID={allDropableID}
            disable={!isColorAxisDisable}
          />
        </div>
      )}
      {doubleAxisMemo?.show ? (
        <div className='outerDiv'>
          <InfoToolTip
            data={{
              title: '',
              description:
                'It can be enable in BarGroup and Line Chart ( 2 Column Require in Y-Axis )',
            }}
          >
            <div className='MainTitle inFlex'>
              {mls('Double Y-Axis')}
              <div className='toggleDiv'>
                <SlideSwitch
                  isChecked={doubleAxisMemo?.doubleAxisData?.isDoubleAxis}
                  setIsChecked={setDoubleAxisSwitch}
                  disabled={doubleAxisMemo.disabled}
                />
              </div>
            </div>
          </InfoToolTip>
        </div>
      ) : null}

      {chartType.chartKey === allChartKeys.BARSTACKLINE && (
        <>
          <div className='outerDiv open'>
            <div className='MainTitle'>{mls('Target Points')}</div>
            <BarStackLineTargetPoints stateProps={stateProps} />
          </div>
        </>
      )}

      <div className='outerDiv open'>
        <div className='MainTitle inFlex'>{mls('Tooltips')}</div>

        {chartType.chartKey === allChartKeys.BARSTACKLINE ? (
          <BarStackLineToolTipDiv
            dragableDivId={toolTip}
            stateProps={stateProps}
            allDropableID={allDropableID}
            handleResetToolTip={handleResetToolTip}
          />
        ) : (
          <DragableToolTip
            dragableDivId={toolTip}
            stateProps={stateProps}
            allDropableID={allDropableID}
            handleResetToolTip={handleResetToolTip}
          />
        )}
      </div>

      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls('Default Filters')}</div>

        <FilterIndex stateProps={stateProps} />
        {/* <div className='textAndToggle'>
          <div className='text'>Cross-report</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={isCrossReport} setIsChecked={setIsCrossReport} />{' '}
          </div>
        </div>
        <div className='textAndToggle'>
          <div className='text'>Keep all filters</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={iskeepAllFilters} setIsChecked={setIskeepAllFilters} />
          </div>
        </div> */}
      </div>

      <ChartInsight
        isInsight={isInsight}
        setIsInsight={setIsInsight}
        chartKey={chartType.chartKey}
      />
    </>
  );
};

export default ChartBuild;
